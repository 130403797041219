@import 'src/variables';

.card-collection {
  width: 100%;
  font-size: 0.9rem;
  font-family: $gilroy-medium;

  &__text {
    &--accent {
      color: $accent;
    }

    &--primary {
      color: $primary;
    }

    &--bold {
      font-family: $gilroy-extra-bold;
    }
  }

  @media ($md) {
    font-size: 1rem;
  }

  @media ($lg) {
    font-size: 1.125rem;
  }

  @media ($xl) {
    font-size: 1.25rem;
  }

  @import 'components/title/Title',
  'components/ribbon/Ribbon',
  'components/collection/Collection',
  'components/collectionItem/CollectionItem';
}