@import "src/variables";

.profile-user-data {
  font-size: 16px;

  .selected {
    font-weight: bold;
  }

  .light {
    color: $light-gray;
  }

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: $gilroy-medium;

  &--large {
    width: 100%;
  }

  &__user-info {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;

    &__left {
      &__balance {
        color: $blue;
      }
      width: 100%;
      .RankBlock {
        margin: 0;
        display: flex;
        .rankingPorcentage {
          margin: 0 0 0 0.3em;
          font-size: 15px;
        }
      }
    }

    &__right {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      &__publications {
        max-width: 300px;
        width: 100%;
        .profile-stats-info {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          padding: 1em 0;
          & > * {
            max-width: 120px;
          }
        }
      }
    }
  }

  &__menu {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    width: 100%;

    font-family: $gilroy-medium;
    color: $accent;
    font-size: 13px;

    &__tab {
      padding: 5px;
      margin: 0 5px 0 5px;
      border-radius: 3px;
      white-space: nowrap;
      text-align: center;
      cursor: pointer;

      &:hover {
        background-color: $bg-light;
      }
    }
  }
}

@media ($sm) {
  .profile-user-data {
    width: 83.4%;
    height: 220px;
    min-width: 500px;

    &--large {
      width: 83.4%;
    }
    &__user-info {
      &__left {
        width: 45%;
      }
      &__right {
        height: 85px;
        margin: 0;
        display: flex;
        width: 55%;
        justify-content: flex-end;
      }
    }

    &__menu {
      display: grid;
      grid-template-rows: none;
      grid-template-columns: repeat(4, 1fr);
      width: 100%;
      &__tab {
        text-align: left;
      }
    }
  }
}

@media ($md) {
  .profile-user-data {
    flex-grow: 1;
    flex-basis: 0;
    height: 220px;
    min-width: 500px;

    &--large {
      width: 83.4%;
    }
    &__user-info {
      &__left {
        width: 55%;
      }
      &__right {
        margin: 0;
        display: flex;
        width: 45%;
        justify-content: flex-end;
        height: 130px;
        &--upper {
          margin: 0;
          align-items: flex-start;
          .profile-stats-info {
            padding-top: 0;
          }
        }
      }
    }

    &__menu {
      display: grid;
      grid-template-rows: none;
      grid-template-columns: repeat(4, 1fr);
      width: 100%;
      max-width: min-content;
      &__tab {
        text-align: left;
      }
    }
  }
}

@media ($lg) {
  .profile-user-data {
    width: 50%;

    &--large {
      width: 80%;
    }
  }
}
