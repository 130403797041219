@import 'src/variables';

.change-proposal-modal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .bp3-input{
    padding-left: 3em;
  }
  &__top {
    width: 90%;
    margin: 0.8em 0 0.1em 0;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    &--title {
      margin-bottom: 0.8em;
    }

    &--filter-bar{
      display: flex;
      width: 100%;
      flex-direction: row;
      &-input {
        width: 100%;
        position: relative;
        &-cross{
          position: absolute;
          top: 57px;
          right: 30px;
          margin-left: 0.3em;
          color: rgb(219, 219, 219);
          cursor: pointer;
          &:hover{
            color: rgb(183, 183, 183);
          }
        }
        &-filter{
          position: absolute;
          top: 57px;
          left: 30px;
          margin-left: 0.3em;
          color: $font-extra-light;
          z-index: 20;

        }
      }
    }

    &--counter {
      width: 100%;
      font-family: $gilroy-medium;
      font-size: 10px;
      margin-top: 0.5em;
      display: flex;
      flex-direction: column;
      align-items: center;

      &-title{
        width: 100%;
        color: $accent;
        font-family: $gilroy-bold;
      }
      &-choiced{
        width: 100%;
        text-align: right;
        color: $light-gray;
        margin-right: 0.8em;
      }

      &-main-container{
        width: 100%;
        text-align: right;
        font-size: 12px;
        color: $light-gray;
        margin: 0 2.1em 0.6em 0;
      }
    }
  }

  &__middle {
    width: 100%;

    &--publication-form {
      &__container {
        width: 100%;
        height: 400px;
        padding: 0 0.5em 0.5em 0.85em;
        &__without_publication{
          margin-top: 8em;
          font-size: 12px;
          color: $font-extra-light;
        }

        &::-webkit-scrollbar {
          width: 7px;
          background-color: white;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $font-extra-light;
          border-radius: 3px;
          height: 10px !important;
        }

        overflow-x: hidden;
        overflow-y: scroll;

      }
    }

  }

  &__bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    font-size: 15px;
    position: relative;
    &--total {
      position: absolute;
      right: 20px;
      top: 5px;
      color: $dark-purple;
      font-family: $gilroy-medium;
      &-bold{
        font-family: $gilroy-bold;
      }
    }
  }

  &__button-wrapper {
    width: 100%;
    padding-top: 1.25em;
    display: flex;
    justify-content: center;
    gap: 2em;
  }

  &__button {
    font-family: $gilroy-bold;
  }
}

.generic-modal.bp3-dialog.publications-card.modal {
  padding: 0 !important;
  max-width: 600px;
  padding-bottom: 1em !important;
}