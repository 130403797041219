@import 'src/variables';

.progress-bar-wrapper {
  display: grid;
  grid-template-columns: auto 1fr;
  row-gap: 0.75em;
  align-items: center;

  &__bar {
    display: contents;

    .bp3-progress-meter {
      background-color: $green;
    }
  }

  &__bar-title {
    color: $green;
  }

  &__bar-wrapper {
    margin-left: .5em;
  }

  &--complete & {
    &__bar {
      .bp3-progress-meter {
        background-color: $gray;
      }
    }

    &__bar-title {
      color: $light-gray;
    }

    &__bar-label {
      color: white;
    }
  }
}