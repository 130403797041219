@import "src/variables";

.successful_change_publication_info{
  width: 220px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  .publication-info {
    width: 200px;
    height: 225px;
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;

    .linked-title{
      align-self: flex-start;
      color: $accent;
    }

    .title{
      font-family: $gilroy-medium;
      font-size: 15px;
      margin-bottom: 10px;
      max-width: 200px;
      align-self: flex-start;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;

      p::first-letter {
        text-transform: capitalize;
      }

      &--inactive{
        color: $light-gray;
      }
      
      &--premium{
        color: $secondary;
      }

      &--standard{
        color: $accent;
      }
    }

    .img_box{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 200px;
      max-height: 200px;
      border-radius: 5px;
      overflow: hidden;
      box-shadow: 0px 0px 7px rgb(239, 239, 239);
      border: 1px solid rgb(239, 239, 239);
    }
    .img{
      width: 100%;
    }
    .written-proposal{
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: $bg-light;
      font-family: $gilroy-extra-bold;
      font-size: 25px;
      padding: 6em;
    }
  }
}
