@import "src/variables";

.profile-stats-info {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 1em 0 1em 1em;
  color: #707070;
  text-align: center;
  h2 {
    font-family: $gilroy-medium;
    font-size: 36px;
    color: #707070;
  }
}
