@import 'src/variables';

.button {
  font-family: $gilroy-medium;
  font-size: 14px;
  padding: .5em 2em;
  box-shadow: 0 0 10px #00000029 !important;
  border-radius: .375rem;
  background-image: none !important;
  line-height: 1.25;
  text-align: center;

  &--black:not(.bp3-disabled):not(.bp3-minimal) {
    background-color: #000 !important;
    color: $secondary;

    &:hover {
      background-color: darken(#000, 10%) !important;
    }

    &:active {
      background-color: darken(#000, 20%) !important;
    }
  }

  &--success:not(.bp3-disabled):not(.bp3-minimal) {
    background-color: #54D454 !important;

    &:hover {
      background-color: darken(#54D454, 10%) !important;
    }

    &:active {
      background-color: darken(#54D454, 20%) !important;
    }
  }

  &--danger:not(.bp3-disabled):not(.bp3-minimal) {
    background-color: #D64747 !important;

    &:hover {
      background-color: darken(#D64747, 10%) !important;
    }

    &:active {
      background-color: darken(#D64747, 20%) !important;
    }
  }

  &--accent:not(.bp3-disabled):not(.bp3-minimal) {
    background-color: $accent !important;
    color: white;

    &:hover {
      background-color: darken($accent, 10%) !important;
    }

    &:active {
      background-color: darken($accent, 20%) !important;
    }
  }

  &--primary:not(.bp3-disabled):not(.bp3-minimal) {
    background-color: $primary !important;
    color: white;

    &:hover {
      background-color: darken($primary, 10%) !important;
    }

    &:active {
      background-color: darken($primary, 20%) !important;
    }
  }

  &--blue:not(.bp3-disabled):not(.bp3-minimal) {
    background-color: $blue !important;
    color: white;

    &:hover {
      background-color: #167fc0 !important;
    }

    &:active {
      background-color: $secondary !important;
    }
  }

  &--secondary:not(.bp3-disabled):not(.bp3-minimal) {
    background-color: $secondary !important;
    color: white;

    &:hover {
      background-color: #ffa722 !important;
    }

    &:active {
      background-color: $secondary !important;
    }
  }

  &--bold {
    font-family: $gilroy-extra-bold;
  }

  &--min-with {
    min-width: 75px;
  }

  &--reduce-padding {
    padding: .5em 1em;
  }

  &.bp3-large {
    font-size: 18px;
    padding: .75em 3em;

    & .bp3-icon svg {
      width: 1.25em;
      height: 1.25em;
    }

    &.button--reduce-padding {
      padding: .75em 1.5em;
    }
  }

  &:focus {
    outline: none;
  }

  &:active,
  &:hover {
    color: white !important;
  }
}