@import 'src/variables';
.profile-publications-list {

  min-width: 100%;

  .list {
    padding-left: 0;
    margin-bottom: 1em;
    display: flex;

    &-button {
      list-style-type: none;
      margin-right: 1.5em;
      cursor: pointer;

      &--active {
        color: #8051da;

      }

      .tag-slate-blue {     
        background-color: #8051da ;
        color: #ffffff;
        font-family: $gilroy-bold;
        margin-bottom: 0.5em;
        margin-right: 0.5em;
      }
    }
  }

  .activity-new-publication {
    .picture {
      width: 208px;
      height: 208px;
      
      .img_box img {
        width: 208px;
        height: 208px;
      }
    }
  }

  .item-publication {
    padding: 1em;
    margin-bottom: 1.5em;
  }



  @media ($md) { 

    .item-publication {
      padding: 2em;
    }
    .date {
      position: absolute;
      right: 22px;
      top: 10px;
    }
  }

  @media ($md) { 
    .activity-new-publication .info .interests_content .buttons .actions {
      max-width: 75%;   
    }
  }
}
