.UserChangeDetail {
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 949px) {
  }
}

.owner_main_info{
  display: flex;
  flex-direction: column !important;
  &__name{
    text-align: left;
  }
}


