@import 'src/variables';

.services-lc {
  &__question {
    font-family: $gilroy-extra-bold;
  }

  &__description {
    margin-bottom: 15px;
  }
}