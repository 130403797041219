@import "src/variables";

.info-modal {
  width: 600px;
  border-radius: 10px !important;

  &__title {
    text-align: center;

    font-family: $gilroy-extra-bold;
    font-size: 25px;
    color: $accent;

    &--font {
      color: $font;
    }
  }

  &__content {
    text-align: left;
    font-family: $gilroy-medium;
    font-size: 16px;
    color: $font;
    max-height: 12rem;

    &--bold {
      font-family: $gilroy-extra-bold;
    }
  }

  @media ($sm) {
    &__title {
      text-align: left;
    }
  }
}