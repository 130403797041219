@import "src/variables";

.contacts {
  font-family: $gilroy;

  .accent-text {
    color: $accent;
  }

  .section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 75%;
    @media screen and (max-width: 769px) {
      width: 100%;
    }
  }

  .row {
    padding: 1rem 0 0 0;
    margin-bottom: 2.25rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .mini-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    grid-gap: 1rem;
    gap: 1rem;
  }
  .contact-image {
    width: 360px;
    height: 289px;
    @media screen and ($md) {
      width: 535px;
      height: 430px;
    }
  }
  .card {
    background: white;
    border-radius: 5px;
    flex-direction: column;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    display: flex;
    padding: 1rem;
    width: 90%;
    margin-left: auto;
    margin-right: auto;

    @media screen and ($md) {
      padding: 2rem;
      margin-left: 0;
      margin-right: 0;
      width: 100%;
    }
    h4 {
      font-size: 16px;
      margin-bottom: 0.5rem;
    }
    p {
      font-size: 14px;
    }
  }
  .social-media-row {
    background: white;
    border-radius: 5px;
    display: flex;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    padding: 1rem;
    width: 90%;
    flex-direction: row;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    font-size: 1rem;
    flex-wrap: wrap;
    @media screen and ($md) {
      padding: 2rem 1.75rem;
      padding-right: 0.5rem;
      margin-left: 0.3rem;
      margin-right: 0;
      width: 100%;
    }

    .card-section {
      width: 90%;
      @media screen and ($md) {
        width: 45%;
      }
    }
  }
  .orange_text {
    color: $primary;
  }
  .column {
    display: flex;
    flex-direction: column;
    width: max-content;
    max-width: 60rem;
    justify-content: center;
    align-items: center;
    @media screen and ($md) {
      justify-content: space-around;
    }
  }
  .info-pages {
    font-family: $gilroy-medium;
    margin: 1.5rem 0 0 3rem;
  }

  .justify-between {
    justify-content: space-between;
    flex-grow: 0.6;
  }
  .wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  @import "./components/contactForm/contactForm",
    "./../../components/footer/Footer";
}
