@import 'src/variables';

.new-Publication {
  width:90%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .inputField {
    border-radius: 5px;
    font-size: 15px;
    margin-bottom: 0.25rem;
    box-shadow: none;
    background-color: #ffffff;
    width: 100%;
    height: 2.15rem;
    .bp3-input {
      height: 2.15rem;
      font-family: $gilroy-light;
    }
    .bp3-input::placeholder {
      color: black;
      opacity: 1;
      font-size: 15px;
      font-family: $gilroy-light;
    }
  }
  .header-image {
    &-container{
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;

      &__label {
        width: 100%;
        .bp3-text-muted {
          color: $primary;
        }
      }

      .delete-all-images {
        font-family: $gilroy-medium;
        font-size: .85em;
        cursor: pointer;
        color: $alert;
        white-space: nowrap;
        order: 1;
        margin-bottom: 0.75em;
        margin-top: 0.5em;
        &--disabled {
          color: $font-extra-light;
          pointer-events: none;
          cursor: default;
        }
      }
      
      .counter {
        letter-spacing: 0px;
        color: $font-light;
        font-family: $gilroy-extra-bold;
        font-size: 13px;
      }

      .alert {
        color: $alert;
      }

      @media (min-width: 768px) {
        justify-content: flex-end;
        flex-wrap: wrap;
        .delete-all-images {
          order: 0;
          margin-bottom: 0;
          margin-top: 0;
          margin-left: auto;
        }
        &__label {
          max-width: 80%;
          margin-bottom: .5rem;
          &--full {
            max-width: 100%;
            width: 100%;
          }
        }
      }    
    }
  }
  .icon-width{
    width:3rem;
  }
  .radioTags {
    font-family: $gilroy-light;
    font-size: 15px;
    display: flex;
    width: 100%;
    .bp3-control.bp3-radio input:checked ~ .bp3-control-indicator::before {
      background-color: #8051da;
      border-radius: 50%;
    }
    .bp3-control.bp3-radio {
      margin-right: 2rem;
    }
  }
  .inputLabel {
    font-size: 17px;
    font-family: $gilroy-extra-bold;
    width: 45%;
    @media screen and (max-width: 768px) {
      width: 90%;
    }
    .bp3-text-muted {
      color: #ff6d00;
    }
    .bp3-form-content {
      display: flex;
      flex-wrap: nowrap;
      grid-gap: 1rem;
      gap: 1rem;
    }
    textarea, input, select{
      font-family: $gilroy;
    }
    select{
      height: 2rem;
      background-image: none;
      background-color: #FFFFFF;
      font-size: 15px;
    }

  }
  .bp3-html-select .bp3-icon {
    color: #8051da;
    padding-left: 1rem;
  }
  .bp3-html-select.bp3-minimal select {
    padding-right: 2rem;
    border: 1px solid #a3a3a3;
    border-radius: 5px;
    width: fit-content;
    height: 2.15rem;
    font-family: $gilroy-light;
  }

  .bp3-breadcrumbs > li::after{
    width: 1rem;
    height: 1rem;
    filter: brightness(0) saturate(100%) invert(45%) sepia(97%) saturate(4232%) hue-rotate(242deg) brightness(90%) contrast(88%);
  }
  
  .timeline{
    align-self: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: $gilroy;
    margin-top: 1rem;
    .bp3-breadcrumb{
      color: #8051da !important;
      line-height: 1.75rem;
      font-size: 1.25rem;
    }
    .bp3-breadcrumb-current{
      color:#464646 !important
    }
    .timeline-element{
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
    .bp3-breadcrumbs-collapsed::before{
      width: 1.3rem;
      height: 1.3rem;
      filter: brightness(0) saturate(100%) invert(45%) sepia(97%) saturate(4232%) hue-rotate(242deg) brightness(90%) contrast(88%);
    }
    
  }
  .card{
    background: #FFFFFF;
    box-shadow: 0px 0px 10px #00000029;
    border-radius: 5px;
    margin:0.5rem;
    padding: 1rem;
  }
  .row{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    @media (max-width:769px) {
      width: 100%;
      margin: 0;
      padding: 0;
    }
  }
  .column{
    display: flex;
    flex-direction: column;
    .bp3-form-group label.bp3-label {
      width: max-content;
    }
  }
  .sub-Card{
    padding: 1rem;
    width: 45%;
    background-color: #00000029;
    box-shadow: 0px 3px 6px #00000029;
    @media (max-width:769px) {
      width: 95%;
    }
  }
  .third-Title{
    margin-bottom: 1rem;
    font-size: 1.125rem;
  }
  .round-corners{
    border-radius: 5px;
  }
  .full-width{
    width: 100%;
  }
  .categories{
    width: 40%;
    max-width: 23.75rem;
    height: 15rem;
    box-shadow: 0px 3px 6px #00000029;
    @media (max-width:769px) {
      width: 100%;
      height: 100vh;
    }
  }
  .purple-Text{
    color:#8051DA;
  }

  .formButton{
    margin: 1rem auto;
    justify-self: center;
    background-color: $accent;
    border-radius: 5px;
    padding: 0.7rem 0.5rem;
    font-family: $gilroy-extra-bold;
    color: white;
    align-self: center;
    width: 100px;
    background-image: none;
  }
  .disabled{
    background: #A3A3A3;
    
  }
  
  .bp3-breadcrumbs-collapsed{
    background: none;
    color: blueviolet !important;
  }
  

  @import './components/step1/Step1.scss',
  './components/step2/Step2.scss',
  './components/step3/Step3.scss',
  './components/step4/Step4.scss',
  './components/generalStep/GeneralStep.scss',
  './components/showImage/ShowImage';
  
}
.bp3-menu-item > .bp3-fill{
  color:#8051da;
}




