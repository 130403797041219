@import "src/variables";

.campaign-item {
  margin-top: 10px;
  background-color: #ffffff;
  height: 248px;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  border-radius: 5px;
  cursor: pointer;

  .a, .b, .statistical-items {
    min-height: 30px;
    min-width: 85px;
  }
  
  .a {
    grid-column: 1fr;
    grid-row: 1 / 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right : 20px;
  }

  &__square-image{
    max-width: 85px;
    max-height: 85px;
    overflow: hidden;
    height: 100%;
    width: auto;
  }
  &__date-container {
    margin-left: 15px;
    margin-right: 15px;
  }
  &__title {
    &--primary {
      color: $primary;
    }
    &--blue {
      color: $blue;
    }
    font-family: $gilroy-extra-bold;
    font-size: 16px;
  
  }
  &__date {
    font-family: $gilroy-light;
    white-space: nowrap;
  } 
  &__kind {
    font-family: $gilroy-extra-bold;
  }   
  .b {
    display: flex;
    justify-content: space-between
  }
  .statistical-items {
    margin-top: 5px;
    grid-column: 1 / 3;
    grid-row: 2 / 2;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }

  @media screen and (min-width: 445px) { 
    &__title{
      font-size: 1.43em;
    }
  } 
  &__statistics{
    &-title{
      font-size: 17px; 
    }
    &-value{
      font-size: 16px;  
    }
  }

  @media screen and (min-width: 425px) { 
    height: 206px;
  }

  @media screen and (min-width: 768px) { 
    height: 248px;
    height: 125px;
    &__statistics{
      &-container{
        margin-left: 0;
        white-space: nowrap;
      }
    }
    .a {
      grid-row: 1 / 3;
    }

    .statistical-items {
      grid-column: 2 / 3;
      grid-row: 2 / 3;
      display: flex;
      justify-content: space-between;
      max-width: 75%;
    } 
  }

}
