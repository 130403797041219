@import 'src/variables';
.written-change-proposal {
  margin-top: 0.5em;
  .simple_title{
    color: $font;
    font-family: $gilroy-bold;
    &--optional{
      font-family: $gilroy;
      color: $font-extra-light;
      font-size: 14px;
    }
  }
}
