@import 'src/variables';
.IdVerififation_container{  
  .IdVerification{
    display: flex;
    padding: 1rem 0 0 0;

    @media screen and (max-width: 769px) {
      justify-content: center;
    }
    
    .links_container{
      font-family: $gilroy-medium;
      margin: 1.5rem 0 0 1rem;
      @media screen and (max-width: 768px) {
        display: none;
      }
    } 

    .text-align {
      text-align: center;
    }

    .section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 75%;
      padding-left: 1rem;
      @media screen and (max-width: 769px) {
        width: 95%;
        padding: 0;
      }
    }
    
    .inner{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .row{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
      .inner_card{
        justify-content: center;
        text-align: center;
      }
      .sliders {
        padding:0 5.2rem 0 6em;
        width: 100%;
        @media screen and (max-width: 576px) {
          width: 95%;
          padding: 0;
        }
      }
      .card{
        width: 100%;
        background-color: white;
        border-radius: 10px;
        padding: 2rem;
        margin-bottom: 2rem;
        justify-content: center;
        &__text{
          width: calc(56.75rem/3 * 2);
        }
        &__image{
          width: 13rem;
          margin-right: 0;
        }
        &__title{
          color: $primary;
          font-size: 2rem;
          font-family: $gilroy-extra-bold;
          text-align: center;
        }
        &__description_text{
          width: 17.125rem;
          font-size: 1.05rem;
          text-shadow: 0px 0px 1px black;
          font-family: $gilroy;
          padding-right: 1rem;
          text-align: left;
          margin: 1rem 0;
          b{
          text-shadow: 0px 0px 1px $accent;
          }
        }
      }
      .accent_text{
        color:$accent;
      }

      .locambio-card{
        align-self: center;
        width: 60%;    
        display: flex;
        flex-direction: column;
        align-items: center;
        @media screen and (max-width: 769px) {
          width: 100%;
        }
      }
      
      .black_text{
        color:$font-light;
      }
      .steps_title{
        text-align: center;
        font-size: 2rem;
        margin: 2rem;
        span{
          color:$primary;
        }
      }
      .steps_row{
        align-items: center;
        justify-content: space-around;
        display: flex;
        text-align: center;
        padding-bottom: 2rem;
        .number{
          width:3rem;
          height: 3rem;
          background: $primary;
          font-size: 2rem;
          font-weight: bolder;
          color:white;
          border-radius: 50%;
          margin: 0.5rem auto;
        }
        .text{
          font-size: 1.3rem;
          font-family: $gilroy;
          font-weight: 700;
          text-align: left;
          width: 15rem;
          margin-bottom: 1.25rem;
        }
      }
      .step{
        width: 13.75rem;
        
      }
      .bottom_card{
        text-align: center;
        font-size: 1.1rem;
        font-family: $gilroy;
        width: 90%;
        margin: auto;
      }
      .orange{
        color:$primary;
      }
      @media ($md) {
        .IdVerification{
          display: flex;
          padding-top: 1rem;
        }
        .card{
          width: 100%;
          max-width: 60rem;
          &__image{
            margin-right: 4.375rem;

          }
        }
        .bottom_card{
          width: 30rem;
          
        }
      }
      .purple_text{
        color:$accent;
        font-family: $gilroy-extra-bold;
      }
      .service_header{
        font-size: 2rem;
        text-align: center;
        font-family: $gilroy;
        color: $font-light;
        margin-bottom: 2rem;
        b{
          font-family: $gilroy-extra-bold;
        }
        span{
          color:$primary;      
        }
      }
    }
    
    @media ($md) {
      display: flex;
      padding-top: 1rem;
    }

  }

  @import "./../home/components/footer/footer";
  
}