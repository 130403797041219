@import 'src/variables';

.display-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  overflow: hidden;

  &__close {
    position: fixed;
    top: 0.5rem;
    right: 0.5rem;
    z-index: 5;
    &--black {
      color: $font;
    }
  }
 
  &__header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 40px;
    background-color: white;
  }

  &__section {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
