@import 'src/variables', 'src/pages/chat/variables';

.drawer-button {
  display: none;
  position: absolute;
  align-items: center;
  align-self: center;
  height: 40px;
  cursor: pointer;
  background-color: white;
  box-shadow: $shadow;
  border-radius: 0 $chat-base-radius $chat-base-radius 0;
  z-index: 2;

  &__icon {
    color: $accent;
  }

  @media ($sm) {
    display: flex;
  }

  @media ($lg) {
    display: none;
  }
}

