@import "src/variables";

.activity-new-publication {
  display: grid;
  grid-template-columns: 300px auto;
  grid-template-rows: auto;
  min-width: 260px;

  .picture{
    width: 275px;
    height: 275px;
    place-self: center;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    @media (max-width: 576px) {
      width: 240px;
      height: 240px;
    }

    .background{
      position: absolute;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      filter: blur(6px); 
      width: 100%;
      height: 100%;
    }

    .img_box{
      position: absolute;
    }

    .img_box img {
      width: 275px;
      height: 275px;
      object-fit: contain;
      @media (max-width: 576px) {
        width: 240px;
        height: 240px;
      }
    }
  }

  .info{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    font-size: 15px;
    grid-template-rows: auto;

    .details{
      padding: 3% 3% 0 3%;

      .title {
        color: $accent;
        overflow: hidden; 
        font-family: $gilroy-extra-bold;
        font-size: 17px;
        text-overflow: ellipsis; 
        display: -webkit-box; 
        -webkit-line-clamp: 2; 
        -webkit-box-orient: vertical; 
        margin-bottom: 0.5em;
        
        .premium, .premium_sponsored {
          color: $yellow;
        }

        p::first-letter {
          text-transform: capitalize;
        }

      }

      .details_tags{
        display: flex;
        flex-direction: row;
        margin-bottom: 1em;

        .dark_tag{
          display: flex;
          color: white;
          background-color: $font-light !important;
          span{
            margin: 0;
          }
          &--yellow{
            color: $secondary;
          }
        }

        p{
          margin: 0 2% 0 0;
          padding: 3px 5px 1px 5px;
          border-radius: 5px;
          background-color: $bg-light;
          font-family: $gilroy;
        }

      }

      .distance{
        margin-bottom: 0.7em;
        font-family: $gilroy-medium;
        font-size: 14px;
      }

      .user_info{
        margin-bottom: 0.4em;
      }

    }
    .interests_content{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .interests{
        padding: 0 3%;
        display: flex;
        flex-direction: column;
  
        h4{
          margin-bottom: 1em;
        }
  
        .article_tags{
          margin-bottom: 1em;
          p {
            font-size: 14px;
            font-family: $gilroy-medium;
          }
        }
      }

      .buttons{
        display: flex;
        flex-direction: row;

        .actions {
          width: 100%;
          @media screen and (max-width: 320px) {
            width: 93%;
          }
        }

        .bp3-button-text {
          flex: none; 
        }
        
        .button{
          width: 100% !important;
          font-family: $gilroy-bold;
          font-size: 0.9em;
          margin: 1%;
        }
      }

      .tagViolet {
        font-family: $gilroy-medium !important;

        p::first-letter {
          text-transform: capitalize;
        }
        
      }
      
    }

    .BtnLCPrimaryOutline {
      background-color: $accent;
      .bp3-button-text{
        color: $accent;
      }
      &--empty{
        span{
          svg{
            fill: none;
            stroke: $accent;
          }
        }
      }
    }

    .BtnLCPrimary {
      background-color: $accent;
      .bp3-button-text{
        color: white;
      }
      .bp3-fill{
        background-color: aqua;
      }
      &--empty{
        span{
          svg{
            fill: none;
            stroke: $accent;
          }
        }
      }
    }
  }

  @media screen and (max-width: 710px) {
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    .info{
      grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    }
  }

  @media (min-width: 990px) and (max-width: 1400px ) {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    .info{
      grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    }
  }
}