@import 'src/variables', 'src/pages/chat/variables';

.panel-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: start;
  justify-content: space-evenly;
  grid-column: 1 / -1;
  margin-bottom: 1em;
  margin-top: 1rem;
  width: 100%;

  &__waiting {
    font-size: 1em;
    font-family: $gilroy-medium !important;
    color: inherit;
    padding: 0.59em 1em;
    background-color: $bg;
    border-radius: $chat-base-radius;
  }

  .waiting_confirmation {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 99%;
    height: 5vh;
    color: $font;
    font-size: 15px;
    margin-bottom: 15px;

    background-color: rgba(163, 163, 163, 0.257);
    &__icon{
      margin-left: 0.2em;
    }
    &__text{
      margin-left: 0.4em;
    }
  }

  .button {
    flex-basis: 45%;
    margin: 0 0 15px 0;
  }

  .full-width {
    flex-basis: 93.6%;
  }

  .security_change {
    min-width: 50%;
    margin: 0;
    font-size: 15px;
    margin: 0 0 0 0;
  }

  @media ($lg) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: start;
    justify-content: center;
    margin-left: 1em;

    .button {
      flex-basis: 150px;
      margin-left: 15px;
    }

    .security_change {
      min-width: 250px;
      margin: 0;
      margin-left: 15px !important;
    }

    .waiting_confirmation {
      width: 98%;
      height: 5vh;
      color: $font;
      font-size: 15px;
      margin: 0 15px 0 0 !important;

    }
  }
}