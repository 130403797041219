@import 'src/variables';

.profile-avatar {
  position: relative;
  overflow: hidden;
  width: 18.5em;
  height: 18.5em;
  margin: 0;
  padding: 12px;
  border-radius: 5px;
  background: #ffffff;
  -webkit-border-radius: 3px px 0 0;
  -moz-border-radius: 3px 3px 0 0;

  &:hover {
    .profile-avatar{
      &__initials {
        filter: blur(10px);
        -webkit-filter: blur(10px);
        -moz-filter: blur(10px); 
        &--noBlur {
          filter: blur(0px);
          -webkit-filter: blur(0px);
          -moz-filter: blur(0px);
        }
      }

      &__edit-container {
        position: absolute;
        z-index: 3;
        top: 0.5em;
        left: 0.5em;
        transform: translateX(0px);
        -webkit-transform: translateX(0px);
        -moz-transform: translateX(0px);
      }
    }
  }

  .avatar-blur-image__picture--background {
    background: round
  }

  &__initials {
    font-size: 2em;
    text-align: center;
    margin-bottom: 0;
    letter-spacing: -2px
  }

  &__edit-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 3;
    top: 0.5em;
    left: 0.5em;
    transform: translateX(-50px);
    -webkit-transform: translateX(-50px);
    -moz-transform: translateX(-50px);
    transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
  }

  &__edit-button {
    margin-right: 0.5em;
    margin-bottom: 0.5em;
    background: rgba(70, 70, 70, 0.75) !important;
  }
  
  &__edit-button span { //react element
    color: white !important;
    font-size: 1.25em;
  }

  &__container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: rgba(138, 155, 168, 0.15);
    border-radius: 5px;
    overflow: hidden;
    margin: 0 auto;
    position: relative;
    min-height: 3rem;
    .blur-image {
      .blur-image { 
        &__picture {
          width: 17em;
          height: 17em;
          &--background {
            width: 17em;
            height: 17em;
          }
          &--img_box img {
            width: 17em;
            height: 17em;
          }
        }
      }
    }

    &__spinner {
      display: flex;
      flex-direction: column;
      align-items: center;
      &__text{
        font-size: 10px;
        color: $light-gray;
        margin-top: 1em;
      }
    }
  }


  @media ($md) {
    height: 112px;
    width: 112px;
    padding: 5px;
    
    &__container{
      .blur-image {
        .blur-image { 
          &__picture {
            width: 7.25em;
            height: 7.25em;
            &--background {
              width: 7.25em;
              height: 7.25em;
            }
            &--img_box img {
              width: 7.25em;
              height: 7.25em;
            }
          }
        }
      }
    }
  }


  @media ($lg) {
    height: 220px;
    width: 220px;
    padding: 8px;

    &__container{
      .blur-image {
        .blur-image { 
          &__picture {
            width: 220px;
            height: 220px;
            &--background {
              width: 220px;
              height: 220px;
            }
            &--img_box img {
              width: 220px;
              height: 220px;
            }
          }
        }
      }
    }
  }

}
