.blur-image {
  display: flex;
  justify-content: center;
  align-items: center;
  &__picture{
    width: 75px;
    height: 75px;
    place-self: center;
    border-radius: 5px;
    overflow: hidden;
    position: relative;

    &--background{
      position: absolute;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      filter: blur(6px); 
      width: 75px;
      height: 75px;
    }

    &--img_box{
      position: absolute;
    }

    &--img_box img {
      width: 75px;
      height: 75px;
      object-fit: contain;
    }
  }
}
