.price {
  display: flex;
  align-items: end;
  font-weight: bold;
  margin-bottom: 5px;

  &__currency {
    font-size: 22px;
  }

  &__number {
    line-height: 1;
    font-size: 31px;
  }

  &__decimals {
    line-height: 1.5;
    font-size: 13px;
  }
}
