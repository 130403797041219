@import "src/variables";

.booking-request {
  background-color: $bg-light;
  padding: 0.7em 1em 0 0.7em;
  font-family: $gilroy-medium;
  text-align: center;
  font-size: 15px;

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__logo {
      max-width: 10em;
    }

    &__product {
      display: flex;
      flex-direction: column;
      align-items: center;
      &__title {
        margin: 1em 0 0.3em 0;
        color: $accent;
        font-size: 23px;
      }
      &__subtitle {
        font-size: 14px !important;
        max-width: 250px;
        text-align: center;
      }
    }
  }

  &__action {
    margin: 1em 0 0.3em 0;
    text-align: left;
    &__name {
      font-family: $gilroy-extra-bold;
      color: $font;
    }
    &__request {
      color: $primary;
    }
    &__schedule {
      color: $blue;
    }
  }
  &__headquarter {
    text-align: left;
    color: $blue;
    margin: 1em 0 1em 0;
  }

  &__confirmation {
    text-align: left;
    &__contact {
      font-family: $gilroy-extra-bold;
    }
    margin-bottom: 2em;
  }

  @media ($sm) {
    .booking-request {
      padding: 0.7em 0;
      &__header {
        display: flex;
        flex-direction: row;

        &__logo {
          max-width: 8em;
          margin-right: 2em;
        }

        &__product {
          &__title {
            margin: 1em 0 0.3em 0;
            font-size: 22px;
            align-self: flex-start;
          }
          &__subtitle {
            font-size: 15px;
            max-width: 250px;
            text-align: left;
          }
        }
      }
    }

    &__confirmation {
      margin-bottom: 0;
    }
  }
}
