@import 'src/variables';

.faq {
  display: flex;
  padding: 1rem 0 0 0;
  background-image: url('https://storage.googleapis.com/locambio-admin/pages/faqs/faqs.png');
  background-repeat: no-repeat;
  background-size: inherit;
  background-attachment: fixed;
  background-position: -10rem 8rem;

  &__container {
    width: 75%;
  }

  &__links {
    font-family: $gilroy-medium;
    margin: 1.5rem 0 0 1rem;
  }

  &__answer {
    font-size: 1rem;
    font-family: $gilroy-medium;

    &--alone {
      padding-bottom: 1rem;
    }

    &--mid {
      padding-top: 1rem;
    }
  }

  &__list {
    list-style-type: disc;
    font-size: 1rem;
    font-family: $gilroy-medium;
    padding-bottom: 1rem;
    & li {
      padding: 0.5rem 0;
    }
    &--sub {
      margin-left: 1.5rem;
    }
    &--number {
      list-style-type: decimal;
    }
  }

  @media screen and (max-width: 768px) {
    background-position: -10rem bottom;
    background-size: 150%;
  
    &__container {
      width: 95%;
    }
  
    &__links {
      display: none;
    }
  }

  @media screen and (max-width: 576px) {
    &__container {
      width: 100%;
    }
  }

}
