@import 'src/variables';

.inputLabel {
  font-weight: bold;
  font-size: 17px;
  font-family: $gilroy-extra-bold;
  width: 100%;
  @media screen and ($md){
  width: 215%;
  }

  @media screen and ($lg){
    width: 40rem;
  }

  @media screen and ($xl){
    width: 17rem;
  }
  
}
.bp3-form-group {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.form-width{
  width: 90%;
  margin:auto;
  @media screen and ($md){
    justify-content: space-around;
    margin-top: 1rem;
    margin-left:auto;
    margin-right: 5%;
    width: 61%;
    max-width: 25rem;
  }
}
.form-title{
  color:$accent;
  font-size: 2rem;
}

.form-description{
  font-size: 1.05rem;
  text-align: left;
  margin-top: 1.5rem;
  font-weight: bolder;
}

.fullWidth{
  width:100%;
}

.contact-form-footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  .formButton {

    background-color: #8051da;
    border-radius: 5px;
    padding: 0.3rem;
    font-family: $gilroy-extra-bold;
    color: white;
    align-self: center;
    margin-bottom: 1rem;
    min-width: 120px;
    min-height: 35px;
    margin-top: 1em;
    &--loading {
      background-color: $font-extra-light;
    }
    &--disabled {
      background-color: $font-extra-light;
    }
  }
}

.contact-form-error-message {
  margin-top: 0.2em;
  font-family: $gilroy-medium;
  font-size: 12px;
  color: $alert;
}
