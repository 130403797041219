@import 'src/variables', 'src/pages/chat/variables';

.confirmation {
  max-width: 300px;
  padding: 2em !important;
  text-align: center;
  background-color: white;
  color: black;

  &__header {
    font-family: $gilroy-extra-bold;
    font-size: 1.25em;
    margin-bottom: 1.25em;

    & h2 {
      color: black;
    }
  }

  &__body {
    font-family: $gilroy-medium;
    font-size: 1.25em;
    margin-bottom: 1.5em;
  }

  @media ($sm) {
    max-width: 450px;
  }
}