@import "src/variables";

.activity-successful-change-decorator {
  display: flex;
  flex-direction: column;
  .date{
    font-family: $gilroy-bold;
    align-self: flex-end;
    font-size: 12px;
    margin-bottom: 0.5em;
  }

  .puplication_type {
    font-family: $gilroy-extra-bold;
    text-align: end;
  }

  .ribbon {
    font-family: $gilroy-medium;
    transform: rotate(-42deg);
    position: absolute;
    text-align: center;
    padding: 0.3rem 0;
    font-size: 12px;
    // TODO: add mediaquerys when the other cards are built 
    top: 20px;
    left: -65px;
    // *******************************************************
    width: 200px;
    z-index: 1;

    // Add ribbon styles HERE

    &__background {

      &--black_gradient {
        background: transparent linear-gradient(400deg, #747474 0%, #2D2D2D 100%) 0% 0% no-repeat padding-box;
      }

      &--blue_gradient {
        background: transparent linear-gradient(400deg, #479FD6 20%, #8051DA 100%) 0% 0% no-repeat padding-box;
      }

      &--orange {
        background-color: $primary;
      }

      &--blue {
        background-color: $blue;
      }

      &--red_gradient {
        background: transparent linear-gradient(400deg, #9B0000 0%, #FF0000 100%) 0% 0% no-repeat padding-box;
      }

      &--orange_gradient {
        background: transparent linear-gradient(400deg, #f8b723 0%, #FF6D00 100%) 0% 0% no-repeat padding-box;
      }

      &--purple {
        background-color: $accent;
      }

      &--yellow {
        background: transparent linear-gradient(400deg, #F2D43C 20%, #f8a202 100%) 0% 0% no-repeat padding-box;
      }

      &--gray {
        background: #747474;
      }

    }

    &__color {

      &--white {
        color: white;
      }

      &--yellow {
        color: $yellow;
      }

      &--black {
        color: black;
      }

    }


    // ****************************
  } 
}
