@import 'src/variables';
$gap: .75em;
$unread-size: 1.25em;

.item {
    cursor: pointer;
    display: grid;
    grid-template-columns: 51px 1fr $unread-size;
    grid-template-rows: min(72px);
    padding: $gap;
    height: 95px;
    grid-gap: $gap;
    width: calc(95%);
    @media ($sm) {
      width: 377px;
    }
    &:hover{
      background-color: $bg-light;
    }
    &__box-avatar {
      display: grid;
      grid-template-columns: 40px;
      grid-template-rows: 40px;
      align-self: center;
      align-items: flex-start !important;
      justify-content: center;
      padding-top: 0.7em;
    }
    &__middle-container {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr 1fr;
    }
    &__title {
      display: inline;
      color: $font;
      font-size: 1.1em;
      font-family: $gilroy-extra-bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      align-self: center;
      cursor: pointer;
    }
    &__text {
      color: $font;
      font-size: .85em;
      font-family: $gilroy-medium;
      align-self: center;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      cursor: pointer;
    }
    &__publication {
      color: $accent;
      font-size: 1.15em;
      font-family: $gilroy-extra-bold;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      &::first-letter {
        text-transform: uppercase;
      }
    }
    &__info {
      display: grid;
      grid-template-columns: 0.8fr;
      align-items: end;
    }
    &__date{
      color: $font;
      grid-column: -1;
      text-align: center;
      font-size: .85em;
      font-family: $gilroy-bold;
    }

    &__unread {
      display: grid;
      grid-template-columns: $unread-size;
      grid-template-rows: 1fr;
      align-self: center;
      justify-content: center;
      &-bubble{
        height: $unread-size;
        border-radius: 50%;
        background-color: $blue;

      }
    }
  }