@import "src/variables";

.campaigns {
  width: 100%;

  &__anchor {
    &--in-line {
      display: inline;
    }
  }

  &__empty-state {
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $font-light;
    &-title {
      font-family: $gilroy-bold;

      font-size: 12px;
      display: flex;
      align-items: center;
      margin-bottom: 1em;
    }
    &-text {
      font-family: $gilroy-medium;
      height: 40px;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      display: inline;
      text-align: center;
    }
  }

  &__list {
    &-pagination {
      margin-top: 1em;
    }
  }
  &__panel{
    display: flex;
    width: 93vw;
    max-width: 100%;
  }
  &__tab-option{
    min-width: 175px;
    min-height: 19px;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      color: $accent;
    }
    &--active {
      color: $accent;
    }
  }
  &__rounded-square {
    height: 26px;
    width: 26px;
    border-radius: 4px;
    background-color: $accent;
    display: flex;
    align-items: center;
    justify-content: center;    
    font-family: $gilroy-bold;
    color: #ffffff;
    margin-right: 10px;
  }

  @media screen and (min-width: 768px) { 
    &__panel{
      width: 100vw;
    }
  }

}

@media ($md) {
  .campaigns {
    &__empty-state {
      &-title {
        font-family: $gilroy-light;
        font-size: 1.7em;
      }
      &-text {
        font-size: 20px;
        font-size: 1.4em;
      }
    }
  }
}
