@import 'src/variables';

.home {
  @supports (content-visibility: auto) {
    .categories {
      .categories {
        &__category {
          flex: 1 0 calc(25% - 1em);
          margin: 0;
        }
      }
    }

  };

  font-size: 0.9rem;
  font-family: $gilroy-medium;
  padding-top: $header-gap;

  .section {
    margin-bottom: 2.75em;

    &--with-padding {
      margin-bottom: 0;
      padding-bottom: 3em;
    }

    &--with-max-width {
      max-width: calc(1050px + 1em);
      margin-right: auto;
      margin-left: auto;
    }

    &__title {
      font-size: 2em;
      text-align: center;
      margin-bottom: 1em;
      line-height: 1em;

      &--bolder {
        color: #252525;
        font-family: $gilroy-extra-bold;
      }

      &--gilroy {
        font-family: $gilroy;

      }

      &--accent {
        color: $accent;
      }

      &--white {
        color: white;
      }

      &--yellow-light {
        color: $yellow-light;
      }

      &--primary {
        color: $primary;
      }

      &--secondary {
        color: $secondary;
      }

      &--font {
        color: $font;
      }
    }

    &--video-faqs {
      background: transparent linear-gradient(180deg, #8051DA 0%, #479FD6 100%) 0% 0% no-repeat padding-box;
    }

  }

  .categories {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &__container {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: center;
      grid-gap: 1em;
      gap: 1em;
      -webkit-margin-end: 1em;
    }

    &__category {
      min-width: 7rem;
      display: flex;
      flex: 1 0 calc(25% - 2em);
      flex-direction: column;
      border-radius: 5px;
      overflow: hidden;
      background-color: white;
      box-shadow: 0 3px 6px #00000029;
      margin: 0.5em;
    }

    &__image {
      width: 100%;
    }

    &__name {
      font-size: 1.25em;
      padding: 1em 0;
      margin: auto 0;
    }

    .swiper-initialized {
      .swiper-button-next,
      .swiper-button-prev {
        &:not(.swiper-button-disabled) {
          color: white;
          background-color: $accent;
        }
      }

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        pointer-events: none;
        z-index: 2;
        background: rgb(232, 232, 232);
        background: linear-gradient(
            90deg,
            $bg 0%,
            transparent 20%,
            transparent 80%,
            $bg 100%
        );
      }
    }
  }

  .video {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &__title {
      font-size: 3.25em;
      margin-top: 0.5em;
      margin-bottom: 1em;
      width: 100%;
    }

    &__iframe {
      width: 100%;
      height: 40vw;
    }
  }

  .faqs {
    position: relative;
    display: flex;
    justify-content: flex-end;

    &__cover {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 100%;
      max-height: 34em;
      background-image: url('../../images/faq-min.png');
      background-repeat: no-repeat;
      background-position: -4em 100%;
      background-size: contain;
      pointer-events: none;
      z-index: 1;
    }

    &__container {
      width: 100%;
      z-index: 2;
      margin: 0 2em;
    }

    &__title {
      font-family: $gilroy-extra-bold;
      font-size: 5em;
      color: white;
      text-align: center;
    }

    &__moreQuestions {
      display: flex;
      justify-content: center;
      margin-top: 15px;

      &--button {
        font-family: $gilroy-extra-bold;
        padding: 0 30px;
        background: $accent;
        color: white;
        border-radius: 4px;

        &:hover {
          background-color: $accent-hover;
        }
      }
    }

    &__button {
      position: relative;
      font-family: $gilroy-extra-bold;
      font-size: 1em;
      text-align: center;
      color: $accent;
      padding: 1.5em 4.5em;
      line-height: 1.5;
      border-radius: 5px;

      &:hover {
        background-color: #ddd;
      }
    }

    &__icon {
      position: absolute;
      top: calc(50% - 0.5em);
      right: 2em;
      color: $accent;

      svg {
        width: 1.5em;
        height: 1.5em;
      }

      &--active {
        transform: rotate(180deg);
      }
    }

    &__content {
      text-align: center;
      box-shadow: none;
      margin-top: 0.5rem;
      border-radius: 5px;
      background-color: $accent;
      color: white;

      p {
        b,
        i,
        a {
          margin: 0.25em;
        }
        a {
          color: inherit !important;
          font-weight: bold;
        }
      }
    }
  }

  .slider {
    margin-bottom: 1em;
    .swiper-button-next,
    .swiper-button-prev {
      top: calc(50% - 1em);
    }

    .swiper-initialized {
      padding-bottom: 20px;
      margin-bottom: 0;
    }

    .swiper-pagination-bullets {
      bottom: 0 !important;
    }

    .swiper-pagination-bullet-active {
      background: $accent;
    }
  }

  .swiper-initialized {
    .swiper-button-next,
    .swiper-button-prev {
      color: $accent;
      width: 2.75em;
      height: 2.75em;
      background-color: #ffffffcc;
      border-radius: 50%;
      cursor: pointer;

      &::after {
        font-size: 1.25em !important;
        font-weight: bold;
      }
    }

    .swiper-button-prev {
      padding-right: 0.25em;
    }

    .swiper-button-next {
      padding-left: 0.25em;
    }
  }

  @import "./../home/components/footer/footer";
}

@media ($md) {
  .home {
    font-size: 1rem;

    .section {
      &--with-max-width-to-slider {
        max-width: calc(1150px + 1em);
        margin-right: auto;
        margin-left: auto;
        padding: 0 20px 0 0;
      }
    }

    .faq {
      &__container {
        width: 60%;
      }
    }

    .categories__name {
      color: $font;
      font-size: 0.9em;
    }

  }
}

@media ($lg) {
  .home {
    font-size: 1.125rem;

    .faqs {
      &__container {
        width: auto;
      }
    }
  }
}

@media ($xl) {
  .home {
    font-size: 1.25rem;

    .faqs {
      &__container {
        width: 80%;
      }
    }

  }

}
