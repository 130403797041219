@import "src/variables";

.statistical-items {
  &__container{
    color: #A3A3A3;  
    margin-right: 19px;
    margin-left: 21px;
    margin-top: 5px;

    &--black{
      color: $font-light;
    }
  }
  &__title{
    font-family: $gilroy-bold;
    font-size: 15px; 
    white-space: nowrap;
  }
  &__value{
    font-family: $gilroy-medium;
    font-size: 15px;  
  }

  @media screen and (min-width: 445px) { 
    &__title{
      font-size: 17px; 
    }
    &__value{
      font-size: 16px;  
    }
  }

  @media screen and (min-width: 768px) { 
    max-width: 750px;
    &__container{
      margin-left: 0;
      white-space: nowrap;
    }
  }
}