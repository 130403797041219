@import 'src/variables', 'variables';

$height: 100%;

.chat {
  position: fixed;
  display: grid;
  width: 100%;
  height: calc(100% - 10.5rem);
  top: 8.8rem;
  bottom: 0;

  &--full {
    height: calc(100%);
    top: 0;
    z-index: 21;
  }

  &__icon {
    color: $accent;
    cursor: pointer;

    &:hover {
      color: darken($accent, 10%) !important;
    }

    &:active {
      color: darken($accent, 20%) !important;
    }
  }

  &__empty-state {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1em;
    text-align: center;
    color: $accent;
    pointer-events: none;

    h2 {
      color: $accent;
    }
  }

  .bp3-drawer {
    background: $bg;
    border-radius: 0 $chat-base-radius $chat-base-radius 0;
    box-shadow: none;
  }

  .bp3-overlay {
    z-index: 19 !important;
  }

  .bp3-overlay-backdrop {
    background-color: rgba($font, .3);
  }

  .blur-avatar{
    width: 65px;
    height: 65px;
    .blur-image {
      width: 65px;
      height: 65px;
      &__picture{
        width: 65px;
        height: 65px;
        &--background{
          width: 65px;
          height: 65px;
        }
        &--img_box img {
          width: 65px;
          height: 65px;
        }
      }
    }
  }

  .AvatarInitials {
    width: 65px;
    height: 65px;
  }

  @media ($sm) {
    height: calc(100% - 7.3rem);
    top: 7.3rem;

    &--full {
      z-index: 1;
    }
  }

  @media ($md) {
    height: calc(100% - 7.5rem);
    top: 7.5rem;
  }

  @media ($lg) {
    grid-template-columns: max(27rem) 1fr;
    height: calc(100% - 7.5rem);
    top: 7.5rem;
  }

  @import 'components/panel/Panel',
  'components/list/List',
  'components/item/Item',
  'components/message/Message',
  'components/listButton/ListButton',
  'components/panelHeader/PanelHeader',
  'components/panelButtons/PanelButtons',
  'components/drawerButton/DrawerButton';
}
