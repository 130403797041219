@import "src/variables";


.visualizations-summary {
  &__visuals {
    display: flex;
    justify-content: space-between;
  }

  p:nth-child(1) {
    color: $blue;
    font-size: 20px;
    padding-bottom: 15px;
    font-weight: bold;
  }

  p:nth-child(3) {
    color: $font;
    font-size: 18px;
    padding: 15px 0 10px 0;
    font-weight: bolder;
  }

  p:nth-child(4) {
    color: $light-gray;
    font-size: 18px;
    padding: 0 0 10px 0;
    font-weight: bold;
  }

  p:nth-child(5) {
    color: $blue;
    font-size: 18px;
    padding: 0 0 10px 0;
    font-weight: bold;
  }

  p:nth-child(6) {
    color: $font;
    font-size: 18px;
    padding: 0 0 10px 0;
    font-weight: bold;
  }

  p:nth-child(7) {

    span:first-child {
      color: $font;
      font-size: 18px;
      font-weight: bolder;
    }

    span:last-child {
      color: $gray;
      font-size: 18px;
      font-weight: normal;
      // border: 1px solid black;
    }
  }
}