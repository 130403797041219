@import 'src/variables';

.chat-interested-publications-modal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .bp3-input {
    padding-left: 3em;
  }

  &__top {
    width: 90%;
    margin: 0.8em 0 0.1em 0;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 0.2em;

    &__selected {
      align-self: center;
      color: $accent;
      margin: 0.5em 0 0.5em 0;
      &--primary{
        color: $primary;
      }
      &--accent{
        color: $accent;
      }
    }
  }

  &__middle {
    width: 100%;

    &--publication-form {
      &__container {
        width: 100%;
        height: 400px;
        padding: 0 0.5em 0.5em 0.85em;

        &__without_publication {
          margin-top: 8em;
          font-size: 12px;
          color: $font-extra-light;
        }

        &::-webkit-scrollbar {
          width: 7px;
          background-color: white;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $font-extra-light;
          border-radius: 3px;
          height: 10px !important;
        }

        overflow-x: hidden;
        overflow-y: scroll;

      }
    }

  }

  &__bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    font-size: 15px;
    position: relative;

    &--total {
      position: absolute;
      right: 20px;
      top: 5px;
      color: $dark-purple;
      font-family: $gilroy-medium;

      &-bold {
        font-family: $gilroy-bold;
      }
    }
  }

  &__button-wrapper {
    width: 100%;
    padding-top: 1.25em;
    display: flex;
    justify-content: center;
    gap: 2em;
  }

  &__button {
    font-family: $gilroy-bold;
  }
}

.generic-modal.bp3-dialog.publications-card.modal {
  padding: 0 !important;
  max-width: 600px;
  padding-bottom: 1em !important;
}