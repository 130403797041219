@import 'src/variables';
$gap: .75em;
$unread-size: 1.25em;

.elements-dropdown {
  background-color: white;
  color: $font;
  display: grid;
  grid-template-rows: 68px 1fr 62px;
  height: calc(100vh);
  width: calc(100vw);
  position: fixed;
  top: 0;
  left: 0;

  .item__box-avatar{
    height: 100%;
    display: flex;
    align-items: flex-end;
    padding-bottom: 4px;
  }
  .blur-avatar{
    width: 45px;
    height: 45px;
    .blur-image {
      width: 45px;
      height: 45px;
      &__picture{
        width: 45px;
        height: 45px;
        &--background{
          width: 45px;
          height: 45px;
        }
        &--img_box img {
          width: 45px;
          height: 45px;
        }
      }
    }
  }

  .AvatarInitials {
    margin-top: 7px;
    width: 45px;
    height: 45px;
  }

  @media ($sm) {
    position: relative;
    border-radius: 5px;
    width: 386px;
    height: min-content;
    max-height: 691px;
  }
  &--chat {
    grid-template-rows: 68px 36px 1fr 62px;
  }
  &--short-chat {
    grid-template-rows: 68px 36px 1fr;
  }
  &--short-notification {
    grid-template-rows: 68px 1fr;
  }
  &__chat-buttons-box {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr;
    text-align: end;
    height: 100%;
  }
  &__chat-button {  
    height: 100%;
    align-content: center;
    display: grid;
    grid-template-columns: 0.5fr 0.25fr;
    grid-gap: 20px;
    justify-content: center;
    font-family: $gilroy-extra-bold;
    color: $font-light;
    cursor: pointer;
    white-space: nowrap;
    &--active{
      color: $accent;
    }
    &:hover{
      background-color: $bg-light;
    }
  }
  &__title {
    font-size: 1.8em;
    align-self: center;
  }
  &__header{
    text-align: center;
    display: grid;
  }
  &__footer{
    cursor: pointer;
    display: grid;
    text-align: center;
    &-text{
      align-self: center;
      font-size: 1.3em;
      color: $accent;
    }
    &:hover{
      background-color: $bg-light;
    }
  }

  &__icon {
    &-container {
      position: relative;
      margin: 0;
      cursor: pointer;
    }

    &-bubble {
      position: absolute;
      border-radius: 100%;
      background-color: #479FD6;
      width: 22px;
      height: 22px;
      left: 11px;
      top: -11px;
      padding: 0;
    }
    
    &-text {
      font-size: 14px;
      font-family: $gilroy;
      text-align: center;
      margin-top: 2px;
      margin-right: -1px;
    }
  }
}
