@import "src/variables";

.SearchList {
  padding-top: 10px;

  span.bp3-breadcrumb {
    color: rgba(70, 70, 70, 1);
    font-family: $gilroy-extra-bold;
    font-size: 1em;
    box-shadow: 0 0 0 1px rgba(70, 70, 70, 1) inset;
    -webkit-box-shadow: 0 0 0 1px rgba(70, 70, 70, 1) inset;
    -moz-box-shadow: 0 0 0 1px rgba(70, 70, 70, 1) inset;
    padding: 0.25em 0.5em;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
  }

  .RankBlock {
    text-align: center;
    margin-top: -15px;
  }

  .newUserText {
    text-align: center;
    margin-top: -15px;
  }

  .IconGold {
    color: #ffbb05;
  }

  &__categories {
    display: flex;
    justify-content: center;
    padding: 10px 5px 10px 5px !important;
    margin-bottom: 1em;

    &__slider {
      margin-bottom: 8px;
      display: flex;
      flex-direction: row;
      font-size: 13px;
      line-height: 1;
      font-family: "gilroy-medium", Helvetica, Arial, serif;
      margin: 0;

      &__item {
        font-family: $gilroy-bold;
        border: 1px solid $accent;
        justify-content: flex-end;
        background-color: white;
        flex-direction: row;
        color: $accent;
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        margin: 5px;

        &--active {
          background-color: $accent;
          color: white;
        }

        &__left {
          padding: 3px 5px 3px 10px;
          display: flex;
          align-items: flex-end;
          &--father {
            font-size: 10px;
            color: $light-gray;
            margin-bottom: 0.4em;
            font-family: $gilroy-medium;
            &--white {
              color: white;
            }
          }
        }

        &__right {
          padding: 4px;
          width: 1.5em;
          display: flex;
          justify-content: flex-end;
        }
      }

      @media screen and ($md) {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        font-size: 14px;
      }

      &--accent:hover {
        color: $primary;
      }
    }
  }
}

.SearchListResults {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
