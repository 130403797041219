@import 'src/variables';
.step4{
  display: flex;
  width: inherit;
  flex-direction: column;

  .purple-text {
    color: $accent;
    cursor: pointer;
  }

  .red-text {
    color: $alert;
  }

  .errorMessage {
    color: $alert;
    font-size: 12px;
    font-family: $gilroy-extra-bold;
    margin: 0px;
  }

  .card {
    margin: 0%;
    display: flex;
    flex-direction: row;
    height: 30rem;
    .text_fields {
      width: 50%;
      h2 {
        margin-bottom: 3%;
      }

      .changePlace{
        width: 100%;
        margin-bottom: 1rem;
      }

      .bp3-html-select {
        width: 100%;
      }
      .location-search-input {
        width: 100%;
      }

      .bp3-form-group.inputLabel {
        width: 100%;
      }

      .autocompleteForm {
        margin: 4px 0;
      }

      .word-counter {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        font-family: $gilroy;
        font-size: 14px;
        margin: 5px 3px 0 0;
      }

      .inputLabel {
        width: 100%;
        select{
          height: 2rem;
          background-image: none;
          background-color: #FFFFFF;
          font-size: 15px;
          option:nth-child(n + 2):nth-child(-n + 4) {
            font-family: $gilroy-bold;
            color: $accent;
          }
          option:nth-child(5){
            font-family: $gilroy-bold;
            color: $blue;
          }
        }
      }
      .bp3-label {
        font-family: $gilroy-bold;
        
        p { 
          .bolded {
            font-family: $gilroy-extra-bold;
          }
          a {
            color: $accent;
            text-decoration: underline !important; 
          }
        }
        
      }
    }
    .map-wrapper *{
      transition: unset;
    }
    .map-selected *{
      background-color: rgba(245, 245, 245, 0.068);
    }
    .map{
      width: 50%;
      background-color: blanchedalmond;
      height: 25rem;
      margin: auto;
      overflow-x: hidden;
      position: relative;
      margin-left: 2rem;
    }
    @media (max-width:769px) {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-content: center;
      .text_fields {
        width: 100%;
        display: flex;
        flex-direction: column;
      }
      .map{
        width: 100%;
        margin-left: 0rem;
      }
      div{
        width: 100%;
      }
      .bp3-html-select.bp3-minimal select {
        width: 100%;
      }
    }
    @media (max-width:769px) {
      height: 35rem;
    }
  }

  .bp3-form-group{
    width: 45%;
    margin-top: 15px;
    @media (max-width:769px) {
      width: 95%;
    }
  }
  
  .autocomplete-dropdown-container{
    font-size: 15px;
    font-family: $gilroy;
  }

  .loading{
    font-size: 14px;
    font-family: $gilroy;
  }

  .autocompleteForm{
    width: 100%;
  }
  
}
