@import "src/variables";

.card {
  box-shadow: 0 0 10px $shadow-color;
  border-radius: 5px;
  position: relative;
  overflow: hidden;

  &--small {
    padding: 1em
  }

  &--medium {
    padding: 1.5em
  }

  &--large {
    padding: 2em
  }

  &--shadow-blue {
    box-shadow: 0 0 15px $blue;

    &.bp3-card.bp3-interactive:hover {
      box-shadow: 0 0 0 1px lighten($blue, 25%), 0 2px 4px lighten($blue, 15%), 0 8px 24px lighten($blue, 5%);
    }
  }

  &--shadow-green {
    box-shadow: 0 0 15px $green;

    &.bp3-card.bp3-interactive:hover {
      box-shadow: 0 0 0 1px lighten($green, 25%), 0 2px 4px lighten($green, 15%), 0 8px 24px lighten($green, 5%);
    }
  }

  &--shadow-yellow {
    box-shadow: 0 0 15px $yellow-light;

    &.bp3-card.bp3-interactive:hover {
      box-shadow: 0 0 0 1px lighten($yellow-light, 25%), 0 2px 4px lighten($yellow-light, 15%), 0 8px 24px lighten($yellow-light, 5%);
    }
  }
}