@import 'src/variables';

.collection-item {
  font-size: 1rem;
  margin: 20px;
  position: relative;
  width: 270px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
  align-items: center;
  padding: 1em 1.5em 2.5em 1.5em;

  &--grey-filter {
    filter: grayscale(.8);
  }

  &__image {
    width: auto;
    height: 75px;
    object-fit: cover;
    margin-bottom: 20px;
  }

  &__title {
    font-size: 1.125rem;
    line-height: inherit;
  }

  &__content {
    padding: 0 1em;
  }

  @media ($md) {
    font-size: 0.9rem;
  }
}


