@import 'src/variables';

.change-proposal-map {
  width: 100%;
  height: 20em;
  position: relative;
  top: 0.3em;

  .map-wrapper *{
    transition: unset;
  }

}

.bp3-dialog.generic-modal.map_modal.modal {
  padding: 0 !important;
  max-width: 600px;
  padding-bottom: 0.7em !important;
  margin: 1em;
  background-color: $bg;
}

