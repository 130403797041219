@import 'src/variables';

.progress-bar {
  &__bar-wrapper {
    display: flex;
    justify-content: end;
    align-items: center;
    position: relative;
  }

  &__bar {
    height: 25px;
  }

  &__label {
    font-family: $gilroy-extra-bold;
    position: absolute;
    margin: 0 0.5em;
    z-index: 1;
  }
}