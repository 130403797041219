.map-attachment {

  position: relative;
  width: 95vw;
  height: 80vh;

  * {
    transition: unset;
  }

  &__suggestion--active {
    background-color: #f5f5f5;
  }

  &__suggestion {
    background-color: #f5f5f5;
    border-radius: 5px;
    border: solid 1px #ccc;
    z-index: 1;
    cursor: pointer !important;
  }
}