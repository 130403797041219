@import 'src/variables';

.google-button {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #dadce0;
  font-family: $gilroy-bold;
  margin-top: 10px;
  padding: 10px;
  box-shadow: none;

  &:active, &:hover {
    color: black !important;
  }
}
