@import "src/variables";
.update-profile {
  width: 100%;

  .bp3-input::placeholder {
    color: black;
    opacity: 1;
    font-size: 15px;
    font-family: $gilroy;
  }

  .bp3-html-select .bp3-icon {
    color: #8051da;
    padding-left: 1rem;
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .bp3-html-select.bp3-minimal select {
    border: 1px solid #a3a3a3;
    border-radius: 5px;
    height: 2.15rem;
    font-family: $gilroy-light;
  }

  .bp3-input {
    height: 2.15rem;
    font-family: $gilroy;
    box-shadow: none;
    border: 1px solid #a3a3a3;
  }

  .bp3-input:hover {
    background-color: #e4e9ed;
  }

  .bp3-text-muted {
    color: red;
  }

  label {
    font-weight: bold;
  }

  .BtnLCPrimary,
  .BtnLCPrimary:hover {
    background-color: $accent;
    padding: 1em;
    font-size: 13px;
    font-family: $gilroy-bold;
    margin-top: 1.5em;
  }

  &__gender {
    width: 100%;
    .bp3-control-indicator {
      background-color: white;
      border-radius: 50% !important;
      ::before {
        background-color: $accent;
        border-radius: 50% !important;
      }
    }
    &__male {
      margin-right: 1em;
    }
  }

  &__date-of-birth {
    &__selects {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &__year {
        width: 80px;
      }
      &__month {
        width: 140px;
      }
      &__day {
        width: 65px;
      }
    }
  }

  &__location {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .bp3-html-select {
      width: 147px;
    }
  }

  &__full-phone {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    &__code {
      width: 35%;
      .bp3-html-select {
        width: 100%;
      }
    }
    &__phone {
      width: 62% !important;
    }
  }

  &__password-title {
    margin-bottom: 0.8em;
  }

  &__submit {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media ($md) {
  .update-profile {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    & > * {
      width: 48%;
    }

    &__date-of-birth {
      &__selects {
        &__year {
          width: 80px;
        }
        &__month {
          width: 150px;
        }
        &__day {
          width: 80px;
        }
      }
    }

    &__location {
      .bp3-html-select {
        width: 160px;
      }
    }

    &__password-title {
      width: 100%;
    }

    &__submit {
      width: 100%;
    }
  }
}

@media ($lg) {
  .update-profile {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    & > * {
      width: 49%;
    }

    &__date-of-birth {
      &__selects {
        &__year {
          width: 140px;
        }
        &__month {
          width: 300px;
        }
        &__day {
          width: 100px;
        }
      }
    }

    &__location {
      .bp3-html-select {
        width: 280px;
      }
    }

    &__password-title {
      width: 100%;
    }

    &__submit {
      width: 100%;
    }
  }
}
