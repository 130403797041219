.user_info_box {
  display: flex;
  align-items: center;
  justify-content: left;  
}

.user_name {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  &__name{}
  &__checkmark {}
}

