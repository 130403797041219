@import "src/variables";
.LinkInfoPages{
  font-size: 1rem;
  width: 12rem;
  .link_page{
    margin-top: 1rem;
    margin-bottom: 1rem;
    a{
    color:$accent;
    }
  }
  .disabled{
    a{
      color:#A3A3A3;
    }
  }
  
}