@import 'src/variables';

$item-gap: .625rem;

.info-content {
  padding: 1.875rem;
  background-color: white;
  font-size: 1rem;
  font-family: $gilroy-medium;
  min-width: 22rem;
  border-radius: $popover-radius;
  position: relative;

  & h3 {
    font-size: 1rem;
  }

  &__title {
    font-family: $gilroy-extra-bold;
    line-height: 1.5;
    padding-bottom: $item-gap;
  }

  &__item {
    display: flex;
    flex-direction: column;

    &::after {
      content: '';
      width: 100%;
      height: 1.5px;
      background-color: $bg;
      margin-top: $item-gap;
      margin-bottom: calc(#{$item-gap} * 2);
    }

    &:last-child::after {
      content: unset;
    }
  }

  &__link, a {
    color: $accent !important;
    border-radius: $base-radius;
    padding: $item-gap 0;
    line-height: 1;

    &:hover {
      color: $primary !important;
    }
  }
}

.accent {
  color: $accent !important
} 

.info-content__header {
  margin-top: 1rem;
}