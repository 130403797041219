@import "src/variables";

section.recoverPasswordSection{

  input{
      font-family: $gilroy-extra-bold;
  }

  .gradientBackground{
      width: 100%;
      height: 40vh;
      position: absolute;
      // top: 0;
      left: 0;
      right: 0;
  }

  .recoverPasswordContainer{
      max-width: 500px;
      margin: 0 auto;
  }

}

