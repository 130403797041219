.xs-mobileHide{
  display: none;
}

.xs-mobileShow{
  display: block;
}

.sm-mobileHide{
  display: none;
}

.sm-mobileShow{
  display: block;
}


.xs-minCustomWrapper{
  width: 90%;
  margin: 0 auto;
}


// MEDIAQUERIES --------------------------------------------------------------------
.arrowRotate90{
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
}

.arrowRotate0{
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
}
