@import 'src/variables';

.min_size_error{
  &-title{
    font-size: 24px;
    color: $alert;
    font-family: $gilroy-bold;
  }
  font-family: $gilroy-medium;
  width: 370px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
}
