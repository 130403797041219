@import 'src/variables';

.generic-modal {
  position: relative;
  padding: 1em !important;
  max-width: 490px;
  border-radius: 3px;

  &__header {
    font-size: 1.25em;
    margin-top: 2.25em;
  }

  &__close {
    position: absolute;
    top: 0.4em;
    right: 0.4em;
    cursor: pointer;
    z-index: 20;
  }

  @media ($sm) {
    padding: 2.25em !important;

    &__header {
      font-size: 1.375em;
      margin-top: 0;
    }
  }
}