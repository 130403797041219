@import "src/variables";

.AvatarImage {
  background-position: center;
  background-size: cover;
  border-radius: 50%;

  .img_box img{
    border-radius: 50%;
  }

  .img_box img.avatar {
    object-fit: contain;
  } 

}

.AvatarInitials {
  background-color: $bg-light;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  height: width;
  p{
    color: $accent;
    font-family: $gilroy-bold;
    font-size: 12px;
  }
  
}

.blur-avatar{
  border-radius: 50%;
  overflow: hidden;
  width: 40px;
  height: 40px;
  .blur-image {

    &__picture{
      width: 40px;
      height: 40px;
  
      &--background{
        filter: blur(6px); 
        width: 40px;
        height: 40px;
      }

      &--img_box img {
        width: 40px;
        height: 40px;
      }
    }
  }

}