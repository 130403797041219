@import "src/variables";

.loginSection {
  position: relative !important;

  #signinDiv {
    display: flex !important;
    justify-content: center !important;
    width: 105%;

    &>div {

      .nsm7Bb-HzV7m-LgbsSe{
        margin-top: 0.75em !important;
        width: 95% !important;
        overflow: hidden !important;
      }
    }

    .S9gUrf-YoZ4jf {
      width: 100% !important;

      iframe {
        margin-top: 0.75em !important;
        padding: 0px !important;
        width: 100% !important;
        overflow: hidden;
      }
    }
  }
  
  .BtnLCPrimaryLink {
    font-family: $gilroy-extra-bold;
    background: transparent;
    color: $accent;
  }

  input {
    font-family: $gilroy-extra-bold;
  }

  .gradientBackground {
    width: 100%;
    height: 40vh;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  &__title {
    margin-top: .5em;
    margin-bottom: 0.67em;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    font-size: 2.85em;
  }

  &__forgot_password {
    width: min-content;
    min-width: 6.2em;
    @media screen and (min-width: 425px){
      width: fit-content;
    }
  }

  &__slogan-bar-orange {
    width: 100%;
    background-color: #FFFFFF;
    text-align: center;
    font-size: 1.5em;
    align-items: center;
    font-family: $gilroy-extra-bold;
    span {
      color: $primary;
    }

    @media screen and (min-width: 575px){
      height: 2em;
      display: flex;
      justify-content: center;
    }
  }

  .loginContainer {
    max-width: 500px;
    margin: 0 auto;
    padding-top: 1.5rem;
  }
  .extra-bold {
    font-family: $gilroy-extra-bold;
    color: #2D2D2D;
  }

  .btnFacebook {
    background: rgb(59, 89, 152) !important;
    border: 0;
    box-shadow: none;
    color: #fff !important;
  }

  .buttonLoginGoogle {
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    font-size: 14px;
    justify-content: center;
    padding: 5px 10px;
    text-align: left;
    vertical-align: middle;
  }

  .example-enter {
    opacity: 0.01;
  }

  .example-enter.example-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }

  .recaptcha {
    display: flex;
    justify-content: left;
  }

  .example-leave {
    opacity: 1;
  }

  .example-leave.example-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
  }
}

.login__password-icon {
  position: relative;
  top: -25px;
  left: 94%;
  cursor: pointer; 
}
