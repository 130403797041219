@import 'src/variables';

.ribbon {
  width: 50%;
  top: 0;
  right: 0;
  transform: rotate(45deg) translate(27%, -27%);
  position: absolute;
  padding: 0.5rem 0;
  background-color: $secondary;
  color: black;
  text-align: center;
  font-size: 14px;
  font-family: $gilroy-extra-bold;
  text-transform: uppercase;
  z-index: 1;

  &__wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    z-index: 0;
    pointer-events: none;
  }
}
