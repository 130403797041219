@import "src/variables";

.product-modal-img {
  object-fit: contain;
  max-height: 90vh;
}

.zoom-in {
  cursor: zoom-in;
}

.zoom-out {
  cursor: zoom-out;
}

.first-in-mobile {
  order: -1;
}

.product-detail-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.product-detail-section {
  display: flex;
  flex-wrap: wrap;
  max-width: 93vw;
}

.buttons {
  display: flex;
  gap: 20px;
  margin-top: 0;
  margin-bottom: 20px;
  justify-content: center;

  &--desktop {
    gap: 40px;
  }

  &--first {
    width: 193px;
  }

  &--second {
    width: 150px;
    height: 20px;
  }

  &__border {
    
  }

  @media ($md) {
    margin-top: 15px;
    margin-bottom: 0;
    justify-content: start;
  }
}

@media (min-width: 768px) {
  .first-in-mobile {
    order: unset;
  }

  .product-detail-section {
    max-width: 100%;
  }
}