@import 'src/variables';

.layer {
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  font-family: $gilroy;
  width: 100%;
  height: 100%;
  background-color: #000000b1;
  color: white;
  z-index: 5;

  &__title {
    display: flex;
    align-items: center;
    height: 70%;
    font-family: $gilroy;
    color: white;
    padding-top: 2em;
  }

  &__actions {
    display: flex;
    align-items: center;
    height: 30%;
    font-family: $gilroy;
    color: white;
    padding-bottom: 1em;
  }

}
