.lh-14 {
  line-height: 1.4em;
}

.flex-jc-sb {
  display: flex;
  padding-right: 1em;
  justify-content: space-between;
  align-items: flex-end;
}

/*.chat-list {*/
/*  max-height: calc(100vh - 260px);*/
/*  overflow-y: scroll;*/
/*}*/

.min-w-8 {
  min-width: 8em;
}

.publication-list {
  max-height: calc(74vh - 260px);
  overflow-y: auto;
}

/*.FixMessagesChat {*/
/*  max-height: calc(100vh - 240px);*/
/*  overflow: hidden;*/
/*}*/

.rankingGraphContent{
  width: 100%;
  height: 6px;
  margin-bottom: 2em;
}

.rankingGraphContent .rankingGraphNormal{
  float: left;
  width: 25%;
  height: 100%;
  background: #5C7080;
  border-radius: 4px 0 0 4px;
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
}

.rankingGraphContent .rankingGraphRegular{
  float: left;
  width: 25%;
  height: 100%;
  background: #DB3737;
}

.rankingGraphContent .rankingGraphGood{
  float: left;
  width: 25%;
  height: 100%;
  background: #F29D49;
}

.rankingGraphContent .rankingGraphExcelent{
  float: left;
  width: 25%;
  height: 100%;
  background: #3DCC91;
  border-radius: 0 4px 4px 0;
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
}

.rankingGraphContent .opacity {
  opacity: 0.5;
}

.rankingGraphContent div p{
  font-size: 0.75em;
  text-align: center;
  margin-top: 10px;
}

.rankingPorcentage{
  font-size: 1.75em;
  font-family: 'gilroy-extra-bold', serif;
  color: #464646;
  vertical-align: middle;
}

.newUserText {
  font-family: "gilroy-light", serif;
  font-weight: 600;
  font-size: small;
}

.IconGold {
  color:#ffbb05;
}

.boxAreaSkeletonStyle {
  min-height: 265px;
}

.smallBoxAreaSkeletonStyle {
  min-height: 107px;
}

@media screen and (max-width: 1070px) {
  .LeyendaRank {
    display: none;
  }
}
