@import 'src/variables';

.actions {
  display: flex;
  gap: 1rem;
  justify-content: center;
  z-index: 1;

  @media ($md) {
    justify-content: flex-start;

    &__buttons {
      grid-column: 2 / -1;
      grid-row: 3;
    }

  }
}
