@import "src/variables";

.security-change {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &__open-modal{
    color: $accent;
    cursor: pointer;
  }

  &__container {
    padding: 20px;
    @media screen and (max-width: 768px) {
      padding: 10px;
    }
  }

  &__modal {
    width: 450px;
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    overflow: hidden;
    background-color: $bg-light;
    box-shadow: 0 3px 6px #00000029;
  }

  &__demo-Flag {
    width: 100%;
    left: 42%;
    top: 4%;
    transform: rotate(45deg);
    position: absolute;
    padding: 0.5rem 0;
    background-color: $secondary;
    color: black;
    text-align: center;
    font-size: 14px;
    font-family: $gilroy-extra-bold;
    text-transform: uppercase;
    z-index: 1;
    @media screen and (max-width: 768px) {
      left: 41%;
      top: 5%;
    }
  }

  &__terms{
    display: flex;
    flex-direction: row;
    text-align: left;
    margin-bottom: 2em;
    &__required{
      color: red;
    }
  }
  

  &__header {
    display: flex;
    flex-direction: row;
  }

  &__logo {
    width: 40%;
    @media screen and (max-width: 768px) {
      width: 60%;
      margin-left: 3em;
    }
  }

  &__text {
    &--top {
      font-family: $gilroy-medium;
      margin-top: 1.5em;
      width: 60%;
      padding: 0px 20px 0px 20px;
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 768px) {
        margin-top: 1em;
        display: none;
      }
      p {
        width: 80%;
      }
      h1 {
        font-size: 2em;
        margin: 0px !important;
        color: $accent;
      }
    }
    &--middle {
      font-family: $gilroy-medium;
      width: 100%;
      margin-top: 1.5em;
      font-size: 1em;
      margin-left: 10px;
      padding: 0px 5px 0px 5px;
      @media screen and (max-width: 768px) {
        margin-top: 0.8em;
        font-size: 0.9em;
        margin-left: 7px;
      }
      .first {
        color: $primary;
      }
      .end {
        color: $accent;
      }
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    margin-top: 20px;
    margin-left: 0 !important;
    font-family: $gilroy-medium;
    padding: 0px 17px 0px 17px;
    &__places {
      width: 100%;
      background-color: white;
      box-shadow: 0px 3px 6px #00000029;
      .bp3-icon-double-caret-vertical {
   
        svg{
          fill: $accent;
          width: 17px;
          height: 17px;
        }
      }
    }
    @media screen and (max-width: 768px) {
      margin-top: 10px;
      font-size: 0.85em;
      margin-left: -5px !important;
    }

    &--section {
      text-align: left;
      margin-bottom: 30px;
    }
  }

  &__form-title {
    text-align: left;
    margin-bottom: 15px;
    font-size: 13px;
  }

  &__form-subtitle {
    text-align: left;
    margin: 2em 0 1em 0 ;
    font-size: 13px;
  }

  &__cross {
    display: flex;
    justify-content: flex-end;
    margin: 1.5% 1.5% 0 0;
    font-size: 14px;

    svg {
      width: 1.4em;
      height: 1.4em;
    }
    svg:hover {
      background-color: #ddd;
    }
  }
}

.bp3-control.bp3-radio input:checked ~ .bp3-control-indicator::before {
  background-color: $accent !important;
  border-radius: 50% !important;
}
.bp3-control.bp3-radio {
  margin-right: 2rem !important;
}

.checkBoxAlign {
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    font-size: 0.85em;
    margin-bottom: 20px;
  }
}

.bp3-control:hover input:checked ~ .bp3-control-indicator {
  background-color: $accent !important;
}

.bp3-dialog {
  border-style: none !important;
}
