@import "src/variables";
.pagination_wrapper {
  display: flex;
  justify-content: center;
  margin-top: 1em;
  .pagination {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  
  ul {
    list-style: none;
    padding: 0;
  }
  
  ul.pagination li {
    display: inline-block;
    width: 35px;
    border: 1px solid #e2e2e2;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    @media screen and (max-width: 768px) { 
      width: 20px;
    }
  }
  
  ul.pagination li a {
    border-radius: 5px;
    padding: 0.3rem 0.5rem;
    font-family: $gilroy-medium;
    color: white;


    text-decoration: none;
    color: $accent;
    font-size: 15px;
    @media screen and (max-width: 768px) { 
      font-size: 13px;
    }
  }
  
  ul.pagination li.active a {
    
    color: white;
  }

  ul.pagination li.active {
    border-radius: 5px;
    background-color: $accent;
  }

  ul.pagination li.prev {
    background-color: $accent;
    width: 100px;
    border-radius: 5px;
    a , a:hover{
      color: white;
    }
    @media screen and (max-width: 768px) { 
      margin-left: 6%;
      a , a:hover{
        font-size: 13px;
        margin: 5px;
      }
    }
  }
  ul.pagination li.prev.disabled{
    background-color: $font-extra-light;
    a {
      cursor: default;
    }
  }

  ul.pagination li.next{
    background-color: $accent;
    width: 100px;
    border-radius: 5px;
    a , a:hover{
      color: white;
    }
    @media screen and (max-width: 768px) { 
      margin-right: 6%;
      a , a:hover{
        font-size: 13px;
        margin: 5px;
      }
    }
  }

  ul.pagination li.next.disabled{
    background-color: $font-extra-light;
    a {
      cursor: default;
    }
  }
  
  .page-selection {
    width: 48px;
    height: 30px;
    color: #337ab7;
  }
  
  .pagination-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
}

