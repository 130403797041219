@import "src/variables";

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__directory {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3em 0 0 0;
    font-size: 1rem;

    &__section {
      text-align: center;
      padding-bottom: 2em;
      margin: 0 2em 0 2em;

      &__social-container {
        width: 150px;
        text-align: center;
        padding-bottom: 2em;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &__title {
        font-size: 1.25rem;
        margin-bottom: 0.5em;
      }

      &__social-icons {
        margin-left: 10px;
        width: 100%;
        padding: 0.5rem 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;

        &--row {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
        }
      }

      &__url {
        color: $accent;
        font-family: $gilroy-medium;
        padding: 0.3rem 0;

        &__label-disabled {
          color: #a3a3a3 !important;
          padding: 0.5rem 0;
        }

        &--disabled {
          pointer-events: none;
          cursor: default;
        }
      }
    }
  }

  &__logo {
    width: 200px;
    margin-top: -10px;
  }

  &__disclaimer {
    font-family: $gilroy-bold;
    text-align: center;
    color: white;
    padding: 2em 3em;
    margin: 0;
  }

  &__grey-bar {
    font-size: 1rem;
    padding: 2em 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #464646;
    width: 100%;
  }

  &__social-icon {
    width: 30px;
    height: 30px;
    margin: 0 10px 15px 0;
  }

  @media ($md) {
    .footer {

      &__directory {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-around;
      }
    }
  }

  @media ($lg) {
    .footer {

      &__directory {
        flex-wrap: nowrap;
        width: 100%;

        &__section {
          text-align: left;

          &__social-icons {

            &--row {
              justify-content: left;
            }
          }
        }
      }
    }
  }
}
