@import 'src/variables';

.collection {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;


  // START OVERWRITE SWIPER CSS
  .swiper {
    overflow: hidden;
  }

  .swiper-pagination-bullets {
    bottom: 0 !important;
  }

  .swiper-pagination-bullet-active {
    background: $accent;
  }

  .swiper-initialized {
    padding-bottom: 20px;
    margin-bottom: 0;

    .swiper-button-next,
    .swiper-button-prev {
      color: $accent;
      width: 2.75em;
      height: 2.75em;
      background-color: #ffffffcc;
      border-radius: 50%;
      cursor: pointer;
      //top: calc(50% - 1em);

      &::after {
        font-size: 1.25em !important;
        font-weight: bold;
      }

      &:not(.swiper-button-disabled) {
        color: white;
        background-color: $accent;
      }
    }

    .swiper-slide {
      display: flex;
      justify-content: center;
    }
    // ENDS OVERWRITE SWIPER CSS
  }
}
