@import 'src/variables';

.modal {
  max-width: 300px;
  padding: 2em !important;
  text-align: center;
  background-color: white;
  color: $font-light;

  &__header {
    font-family: $gilroy-extra-bold;
    font-size: 1.25em;
    margin-bottom: 1.25em;

    & h2 {
      color: $font-light;
    }
  }

  &__body {
    font-family: $gilroy-medium;
    font-size: 1.25em;

    &--with-space {
      margin-bottom: 1.5em;
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    gap: 2em;
  }

  @media ($sm) {
    max-width: 450px;
  }
}