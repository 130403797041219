@import "src/variables";

.detailProduct {
  .profile-interest {
    order: -1;
    margin-bottom: 1em;

    @media (min-width: 768px) {
      order: 0;
      margin-bottom: 0;
    }
  }
  .InfoProductContainer {
    .publicationName h1 {
      font-size: 1.75em;
      color: #464646;
      margin-top: 0;
    }

    .titleWithButton {
      display: flex;
      justify-content: space-between;
    }

    .infoDetailPublication h4 {
      font-size: 1em;
    }
  }

  p.titleNumberOfChanges {
    margin-bottom: 0 !important;
    text-align: center;
  }

  h4.numberOfChanges {
    font-size: 2em;
    margin-bottom: 0;
    text-align: center;
    margin-top: 0;
  }

  .changePointProduct {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
  }

  .positionNewTag {
    position: absolute;
    z-index: 10;
    top: 1em;
    left: 2em;
  }

  .mapDetailProduct {
    width: calc(100% - 1.75em) !important;
    height: 0 !important;
    padding-bottom: 100% !important;
    box-shadow: 0 0 0 1px rgba(80, 80, 80, 0.25);
    -webkit-box-shadow: 0 0 0 1px rgba(80, 80, 80, 0.25);
    -moz-box-shadow: 0 0 0 1px rgba(80, 80, 80, 0.25);
  }

  .UserChangeDetail h2 {
    margin: 0;
    font-size: 1.25em;
    text-align: center;
  }

  .UserChangeDetail h2 span {
    font-size: 0.75em;
    margin-bottom: 0;
    text-align: center;
    font-family: -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Open Sans", "Helvetica Neue", "Icons16",
      sans-serif;
    font-weight: 100;
  }

  .UserChangeDetail p {
    margin-bottom: 0;
    text-align: center;
  }

  .FixCSSReactImageMagnify {
    z-index: 100;
  }

  .atrrCaracteristicas {
    text-transform: capitalize;
  }

  .bp3-button:not([class*="bp3-intent-"]):disabled,
  .bp3-button:not([class*="bp3-intent-"]).bp3-disabled {
    color: rgba($color: #464646, $alpha: 1);
  }

  .swiper-button-next {
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    margin-right: 0px;
    position: absolute;
    background-color: #c0c0c0;
    background-size: 10px;
    width: 40px;
    height: 40px;
    color: $accent;
  }

  .swiper-button-prev {
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    margin-right: 0px;
    position: absolute;
    background-color: #c0c0c0;
    background-size: 10px;
    width: 40px;
    height: 40px;
    color: $accent;
  }

  .mrec {
    width: 99%;
    margin-left: 2px;
  }

  .mrec .adImage {
    width: auto;
  }

  .HalfPage {
    width: auto;
  }

  .HalfPage .adImage {
    width: auto;
  }

  .rounded-t {
    width: 100%;
  }

  .RankBlock {
    text-align: center;
    margin-top: -15px;
  }

  .newUserText {
    text-align: center;
    margin-top: -15px;
  }

  .publication__share {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    background-color: $accent;
    color: #fff;
    padding: 0.25rem;
    border-radius: 5px;
    box-shadow: 1px 1px 1px 1px #464646;
  }

  .social-share__container {
    display: flex;
    align-items: baseline;
    position: absolute;
    top: 3.5rem;
    right: 4.1rem;
    width: 100px;
    background-color: #fff;
    padding: 0 1rem;
    z-index: 100;
    gap: 4px;
  }

  .social__url-copy {
    padding: 0.3rem;
    background-color: #464646;
    color: #fff;
    border-radius: 50%;
    cursor: pointer;
  }

  .blur-avatar{
    border-radius: 50%;
    overflow: hidden;
    width: 50px;
    height: 50px;
    .blur-image {
  
      &__picture{
        width: 50px;
        height: 50px;
    
        &--background{
          filter: blur(6px); 
          width: 50px;
          height: 50px;
        }
  
        &--img_box img {
          width: 50px;
          height: 50px;
        }
      }
    }
  
  }

  /*************** MEDIA QUERY ***************/

  @media (min-width: 948px) {
    padding-top: 1.5rem;
  }

  @media (max-width: 949px) {
    padding-top: 1rem;

    .social-share__container {
      right: 3.3rem;
    }
  }
}
