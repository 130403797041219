@import "src/variables";
.productSwiper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .imageWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-container {
    max-width: 100%;
  }

  .blur-image {
    &__picture{
      width: 150px;
      height: 150px;
  
      &--background{
        width: 150px;
        height: 150px;
      }
  
      &--img_box{
        position: absolute;
      }
  
      &--img_box img {
        width: 150px;
        height: 150px;
      }
    }
  }

  .blur-image-with-zoom {
    &__picture{
      width: 200px ;
      height: 200px;
  
      &--background{
        width: 200px;
        height: 200px;
      }
      &--img_box img {
        width: 200px;
        height: 200px;
      }
    }

    @media ($xl) {
      &__picture{
        width: 400px;
        height: 400px ;
    
        &--background{
          width: 400px;
          height: 400px ;
        }
        &--img_box img {
          width: 400px ;
          height: 400px ;
        }
      }
    }
  }

}
