@import "src/variables";

.wall {
  display: flex;
  flex-direction: column;
  margin: 30px 3%;
  min-height: 100vh;
  gap: 30px;

  &--sticky {
    position: sticky;
    top: 30px;
    height: fit-content;
  }

  &--side {
    flex: 1;
  }

  &--content {
    flex: 2;
  }

  &__main {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }

  &__ad-wrapper {
    display: none;
    flex: 0.8;

    .swiper {
      max-width: unset;
    }
  }

  &__profile-wrapper {
    display: none;
  }

  &__user-wrapper {
    display: none;
  }

  @media ($md) {
    flex-direction: column;

    &__profile-wrapper {
      display: block;
    }
  }

  @media ($lg) {
    flex-direction: row;

    &__user-wrapper {
      display: block;
    }

    &--side {
      max-width: 500px;
    }
  }

  @media ($xl) {
    &__ad-wrapper {
      display: block;
    }
  }
}
