@import 'src/variables';

.campaign-modal {
  position: relative;
  padding: 1em !important;
  max-width: 490px;

  &__header {
    font-size: 1.25em;
    margin-top: 2.25em;
  }

  &__close {
    position: absolute;
    top: 1em;
    right: 1em;
    cursor: pointer;
  }

  @media ($sm) {
    padding: 2.25em !important;

    &__header {
      font-size: 1.375em;
      margin-top: 0;
    }
  }
}
