@import "src/variables";

.PubCount {
  text-align: center;
  font-family: $gilroy-extra-bold;
  line-height: 1em;
  color: rgba(70, 70, 70, 1) !important;
}

.ChangeCount {
  text-align: center;
  font-family: $gilroy-extra-bold;
  line-height: 1em;
  color: rgba(70, 70, 70, 1) !important;
}