@import 'src/variables';

.panel-header-navbar {
  display: grid;
  grid-template-columns: repeat(5, 1fr) auto;
  grid-template-rows: repeat(2, auto);

  &--mobile {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icons {
    display: flex;
    justify-content: space-around;
    grid-row: 1 / 2;
    grid-column: 1 / -2;
    
    &-icon {
      cursor: pointer;
      color: white !important;
      margin: 0 0.6rem;
      @media screen and (min-width: 1024px) {
        margin: 0 1rem;
      }
    } 

    &-logged--Out {
      min-width: fit-content;
      display: flex;
      align-items: center;
      color: white !important;
      font-size: 1rem;
      padding-bottom: 0.5rem;
    }
  }

  &__icons-mobile {
    position: absolute;
    width: 48vw;
    @media screen and (min-width: 321px) {
      width: 60vw;
    }
    display: flex;
    justify-content: space-around;
    font-size: 14px;
    right: 1rem;
    top: 0.9rem;
    
    &-icon {
      margin: 0rem;
      cursor: pointer;
      color: white !important;
    }

    &-logged--Out {
      min-width: fit-content;
      display: flex;
      align-items: center;
      color: white !important;
      font-size: 1rem;
      padding-bottom: 0.5rem;
    }
  }

  &__tags-bar {
      border-left: 2px white solid;
      height: 20px;
      margin: 0 0 0 1rem;
    &--mobile {
      margin: 0;
      border-left: 2px white solid;
      height: 20px;
    }
  }

  &__profile {
    grid-row: 1 / 2;
    grid-column: -2 / -1;
  }

  &__profile-mobile {
    position: absolute;
    top: 1rem;
    right: 0.5rem;
  }

  &__buttons {
    grid-row: 2 / 3;
    grid-column: 1 / -1;

    &-button {
      display: flex;
      justify-content: space-evenly;
      margin-top: 0.5rem;
      width: 115px;
      height: 40px;
      background-color: $accent;
      border-radius: 5px;
      box-shadow: 0px 2px 2px $shadow-color;
      cursor: pointer;
    }

    & a {
      color: white;
      display: flex;
      align-items: center;
    }

    &-icon {
      padding-right: 0.7rem;
    }

    & p {
      font-size: 14px;
      font-family: $gilroy-bold;
    }
  }

  &-display {
    &__mobile {
      color: white !important;
      margin: 0 0.5rem
    }
  }

  &__mobile {
    display: flex;
    flex-flow: column;
    align-items: center;
    height: 80px;
    justify-content: space-evenly;
    margin: 2rem 0 0 0;

    &-icons {
      color: white !important;
      padding: 0 0.25rem;
    }
    
    &-button {
      background-color: $accent;
      padding: 0.25rem;
      border-radius: 5px;
      box-shadow: 0px 2px 2px $shadow-color;
    }
  }
}

.panel__burger {
  position: absolute;
  top: 0px;
  left: 0 px;
  width: 100vw;
  height: 101vh;
  background-color: white;
  overflow: hidden;
}
