@import 'src/variables';

.user-bubble {
  display: flex;
  align-items: center;
  margin: -5px 0 0 0;
  cursor: pointer;
  border-radius: 50%;
  overflow: hidden;
  background-color: white;
  width: 32px;
  height: 32px;

  .blur-image {
    &__picture{
      width: 32px;
      height: 32px;
  
      &--background{
        width: 32px;
        height: 32px;
        filter: blur(12px); 
      }
  
      &--img_box img {
        width: 32px;
        height: 32px;
      }
    }
  }

  .img_box_initial{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 9px;
    background-color: rgb(255, 255, 255);
  }

  p {
    background-color: white;
    font-family: $gilroy-extra-bold;
    font-size: 10px;
    padding: 0.6rem;
    color: $accent;
  }
}