@import "src/variables";

section.forgotPasswordSection {

  input {
    font-family: $gilroy-extra-bold;
  }

  .gradientBackground {
    width: 100%;
    height: 40vh;
    position: absolute;
    // top: 0;
    left: 0;
    right: 0;
  }

  .forgotPasswordContainer {
    max-width: 500px;
    margin: 0 auto;

  }

  .centerRecaptcha {
    display: grid;
    justify-content: center;
    margin-top: 10px;
    width: 100%;
  }

  .ButtonTransparent {
    color: $accent;
    background: transparent;
    box-shadow: none !important;
  }

  /******************** MEDIA QUERY ********************/

  @media (max-width: 575px) {
    padding-top: 3rem;
  }


}