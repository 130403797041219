@import 'src/variables';

.select-plan {
  font-family: $gilroy-extra-bold;
  font-size: 18px;

  &__title {
    &--large{
      font-size: 20px;
    }
  }

  &__image {
    max-width: 100%;
    max-height: 120px;
    margin: 18px 0;
  }

  &__button {
    margin-top: 12px;
  }

  &__info {
    margin-bottom: 15px;
  }

  .blur-image {
    &__picture{
      width: 150px;
      height: 150px;
      
      &--background{
        width: 150px;
        height: 150px;
      }
  
      &--img_box{
        position: absolute;
      }
  
      &--img_box img {
        width: 150px;
        height: 150px;
      }
    }
  }
  

  @media ($sm) {
    &__info {
      margin-bottom: 30px;
    }

    &__image {
      margin: 25px 0;
    }

    &__button {
      margin-top: 24px;
    }
  }
}
