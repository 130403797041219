@import "src/variables";

.payment-success-modal {
  max-width: 400px;
  font-family: $gilroy-bold;
  h3 {
    text-align: justify;
  }
  &__note {
    margin: 2em 0;
    font-family: $gilroy-medium;
    font-size: 15px;
    text-align: justify;
    &__bold {
      font-family: $gilroy-extra-bold !important;
    }
  }
}
