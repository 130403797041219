@import 'src/variables';

.locambio-promo {
  $class: &;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  cursor: pointer;
  padding: 12px;

  &--active {
    outline: 1px solid;

    &#{$class} {
      &--basic {
        outline-color: $blue;
      }

      &--medium {
        outline-color: $blue;
      }

      &--advanced {
        outline-color: $primary;
      }
    }

    #{$class} {
      &__button:not(.bp3-disabled):not(.bp3-minimal) {
        background-color: transparent !important;
        color: $accent;
        box-shadow: none !important;
        padding: 7px 4px;


        &:hover{
          color: $accent !important;
        }
      }
    }
  }

  &--basic {
    #{$class} {
      &__title {
        background-color: $accent;
      }
    }
  }

  &--medium {
    color: $accent;

    #{$class} {
      &__sub-title {
        color: $blue;
      }

      &__title {
        background: transparent linear-gradient(281deg, $blue 0%, $accent 100%) 0 0 no-repeat padding-box;
      }
    }
  }

  &--advanced {
    color: $primary;

    #{$class} {
      &__title {
        background: transparent linear-gradient(281deg, $primary 0%, $secondary 100%) 0% 0% no-repeat padding-box;
      }
    }
  }

  &__title {
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% + 32px);
    height: 26px;
    margin-top: -8px;
  }

  &__sub-title {
    font-size: 11px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__button {
    padding: 7px 10px;
    transition: none;
  }
}
