@import 'src/variables';

section.Registration {
  .gradientBackground {
    width: 100%;
    height: 40vh;
    position: absolute;
    left: 0;
    right: 0;
  }
  .avatarContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  @media screen and (max-width: 600px) {
    .bp3-tab {
      width: 100% !important;
    }
    .bp3-tab-list {
      display: block;
    }
  }
}
.registrationContainer {
  width: 50%;
  margin: 4.5rem auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 0px 10px #00000029;
  border-radius: 5px;
  min-width: 45rem;
  font-family: $gilroy;
  padding: 2%;
  @media screen and (max-width: 768px) {
    width: 90%;
    min-width: 0;
  }

  .row {
    display: flex;
    justify-content: space-around;
    width: 100%;
    flex-wrap: wrap;
    flex-grow: 1;
    .bp3-popover-wrapper {
      width: 50%;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
    .bp3-popover-target {
      width: 200%;
      margin-left: 0.5rem;
      @media screen and (max-width: 768px) {
        width: 100%;
        margin-left: 0;
      }
    }
    @media screen and (max-width: 768px) {
      justify-content: flex-start;
      margin-left: 1.5rem;
    }
    .marginLeft {
      @media screen and (max-width: 768px) {
        margin-left: 0;
      }
    }
  }
  .address {
    display: flex;
    justify-content: space-around;
    width: 100%;
    flex-wrap: wrap;
    flex-grow: 1;
    @media screen and (max-width: 768px) {
      justify-content: flex-start;
      margin-left: 0rem;
    }
  }
  .tabRow{
    display: flex;
    justify-content: space-around;
    width: 100%;
    flex-wrap: wrap;
    flex-grow: 1;
  }
  .tab {
    padding-top: 2rem;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    width: 50%;
    font-size: 14px;
    @media screen and (max-width: 768px) {
      font-size: 12px;
      padding-top: 1rem;
    }
  }
  .active {
    color: #8051da;
  }
  .disabled {
    color: #a3a3a3;
  }
  .activeBar {
    color: #8051da;
    border-radius: 5px;
    width: 100%;
    height: 4px;
    background-color: #8051da;
    margin-top: 0.5rem;
  }
  .location {
    width: 100%;
    display: flex;
    justify-content: space-between 
  }
  .column {
    display: flex;
    flex-direction: column;
    width: 50%;
    .bp3-form-group label.bp3-label {
      width: max-content;
    }
  }
  .orangeText {
    color: #ff6d00;
  }
  .inputLabel {
    font-size: 17px;
    font-family: $gilroy-extra-bold;
    width: 100%;
    .bp3-text-muted {
      color: #ff6d00;
    }
    .bp3-form-content {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      grid-gap: 1rem;
      gap: 1rem;
    }
  }
  .bp3-form-group {
    margin-bottom: 0;
    margin-top: 1rem;
  }
  .countrySelect {
    .bp3-form-content {
      justify-content: flex-start;
    }
  }
  .inputField {
    border-radius: 5px;
    font-size: 15px;
    margin-bottom: 0.25rem;
    box-shadow: none;
    background-color: #ffffff;
    width: 100%;
    height: 2.15rem;
    .bp3-input {
      height: 2.15rem;
      font-family: $gilroy;
    }
    .bp3-input::placeholder {
      color: black;
      opacity: 1;
      font-size: 15px;
      font-family: $gilroy;
    }
  }
  .bp3-intent-danger {
    .bp3-input::placeholder {
      color: #d64747;
    }
  }
  .bp3-html-select {
    width: min-content;
  }
  .bp3-html-select .bp3-icon {
    color: #8051da;
    padding-left: 1rem;
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .disabled .bp3-icon {
    color: black;
  }
  .bp3-html-select.bp3-minimal select {
    padding-right: 2rem;
    border: 1px solid #a3a3a3;
    border-radius: 5px;
    width: fit-content;
    height: 2.15rem;
    font-family: $gilroy;
  }
  .bp3-intent-danger > select {
    border-color: #d64747 !important;
    color: #d64747;
  }
  .bp3-html-select.bp3-minimal select:disabled {
    background-color: #a3a3a3;
    margin-bottom: 0;
    color: black;
    .bp3-icon {
      color: black;
    }
  }
  .selectInputContainer {
    display: flex;
    width: inherit;
  }
  .dniSelect .bp3-html-select.bp3-minimal {
    select {
      border-radius: 5px 0px 0px 5px;
    }
  }
  .radioTags {
    font-family: $gilroy;
    font-size: 15px;
    display: flex;
    width: 100%;
    .bp3-control.bp3-radio input:checked ~ .bp3-control-indicator::before {
      background-color: #8051da;
      border-radius: 50%;
    }
    .bp3-control.bp3-radio {
      margin-right: 2rem;
    }
  }
  .countrySelect .registrationContainer .inputLabel .bp3-form-content {
    justify-content: flex-start;
  }
  .img_box{
    display: flex;
    justify-content: flex-start;
    width: 100%;
    flex-wrap: wrap;
  }

  .dropzone {
    .blur-image {
      &__picture{
        width: 150px;
        height: 150px;
        
        &--background{
          width: 150px;
          height: 150px;
        }
    
        &--img_box{
          position: absolute;
        }
    
        &--img_box img {
          width: 150px;
          height: 150px;
        }
      }
    }
  }





  #state{
    width: 200px;
    @media screen and (max-width: 1536px) {
      width: 150px;
    }
  }
  #country{
      width: 150px;
  }
  .date{
    select {
      width: 6.4vw !important;
      @media screen and (max-width: 1536px) {
        width: 90px !important;
      }
    }
  }
  .fullWidth {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .formButton {
    margin: 1rem auto;
    justify-self: center;
    background-color: #8051da;
    border-radius: 5px;
    padding: 0.7rem 0.5rem;
    font-family: $gilroy-extra-bold;
    color: white;
    background-image: none;
    .disabled {
      background-color: $bg-light;
    }
  }
  .divisionLine {
    border: 1px solid $font-extra-light;
    width: 100%;
    margin: 20px 0px 20px 0px;
  }
  .checkBoxAlign {
    display: flex;
    align-items: center;
  }
  .marginLeft {
    margin-left: 1rem;
  }
  .profileText {
    align-self: center;
    color: #8051da;
    margin: 0.5rem;
    font-size: 20px;
    cursor: pointer;
    font-family: $gilroy-extra-bold;
  }
  .errorMessage {
    color: #d64747;
    font-size: 12px;
    font-family: $gilroy-extra-bold;
  }
  .redText{
    color:#d64747;
  }
  .captchaError{
    border-color: #d64747;
  }
  .alignLeft{
    justify-content: flex-start;
    margin-left: 1.5rem;
  }
  
  @import '../../components/selectWithInput/SelectWithInput';
  @import '../../components/customDropZone/CustomDropZone';
}

.verification_link {
  color: $accent;
  cursor: pointer;
  font-family: $gilroy-extra-bold;
  z-index: 50;
  text-decoration: underline !important;
}