@import "src/variables";

.profile-control-activation {
  width: 100%;
  font-size: 15px;
  &__container {
    &__email {
      margin-bottom: 1.5em;
    }
    .bp3-control {
      font-size: 14px;
    }
    h3 {
      margin-bottom: 0.7em;
    }
  }
}

@media screen and ($md) {
  .profile-control-activation {
    &__container {
      width: 400px;
    }
  }
}

.deactivation_warning_modal {
  &__title {
    margin-bottom: 1em !important;
    color: $alert !important;
  }
}
