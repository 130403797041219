@import 'src/variables';

.lo-cambio-premium {
  display: flex;
  padding: 1rem 0 0 0;

  li {
    list-style-type: disc;
  }

  ul li::marker {
    font-size: 1.4em;
  }

  ol, ul {
    padding-left: 5%;
    margin-bottom: 5%;
  }

  h3 {
    color: white;
    margin-bottom: 3%;
    font-size: 1.5rem;
  }

  .purple-bold {
    color:$accent;
    font-family: $gilroy-extra-bold;
  }

  .purple-text {
    color: $accent;
  }

  .bold {
    font-family: $gilroy-extra-bold;
  }

  h2 {
    font-size: 1.5rem;
  }

  .default-text {
    color: $font;
    font-size: 1.5em;
    font-family: $gilroy-medium;
    margin-bottom: 5%;
    @media screen and (max-width: 769px) {
      width: 100%;
    }
  }

  .description{
    min-height: 120px;
    margin: 0 0 0 5px;
  }

  .section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 75%;
    padding: 0 3% 0 3%;
  }

  .locambio-card{
    align-self: center;
    width: 65%;    
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 769px) {
      width: 80%;
    }
  }

  &__slider-container {
    width: 100%;
  }

  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    margin: 2rem 0 2rem 3%;
    padding: 0 2rem 0 2rem;
    border-radius: 10px;
    &--top {
      display: flex;
      flex-direction: row;
      margin-bottom: 1rem;
      &--left {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 40%;
        padding-top: 3%;
      }
      &--right {
        width: 60%;
        &--box {
          width: auto;
          background: rgb(85,140,215);
          background: linear-gradient(41deg, rgba(85,140,215,1) 25%, rgba(127,82,218,1) 100%, rgba(0,212,255,1) 100%);       
          margin-bottom: 1rem;
          margin: 10% 0 0 2rem;
          border-radius: 10px;
          padding: 1rem 1rem 0.5rem 1rem;
          color: white;
          font-size: 1.5em;
        }
      }
    }
    &--mid {
      display: flex;
      flex-direction: column;
      align-items: center;
      &--steps {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
        @media screen and (max-width: 567px) {
          flex-direction: column;
          align-items: center;
          margin-bottom: 0;
          padding-bottom: 0;
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }
      &--business {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        margin-top: 4%;
        @media screen and (max-width: 1120px) {
          flex-wrap: wrap;
          margin-bottom: 0;
          padding-bottom: 0;
        }

        @media screen and (max-width: 5px) {
          flex-direction: column;
          align-items: center;
        }
        @media screen and (max-width: 576px) {
          flex-direction: column;
          align-items: center;
        }
        // @media screen and (min-width: 1281px) {
        //   padding: 0 8rem 0 8rem;
        // }
      }
      &--bottom-text {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 1rem 0 1rem 0;
        padding: 0 15% 0 15%;
        font-family: $gilroy-medium;
        font-size: 1.4em;
        p {
          text-align: center;
        }
        .coin-img {
          margin: 2%;
          width: 43px;
          height: 43px;
        }
        @media screen and (max-width: 1024px) {
          padding: 0 10% 0 10%;
        }
        @media screen and (max-width: 1024px) {
          padding: 0 1% 0 1%;
        }
        @media screen and (max-width: 576px) {
          font-size: 0.8em;
          padding: 0;
          .coin-img {
            margin: 2%;
            width: 33px;
            height: 33px;
          }
        }
      }

    }

    &--bot {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

  }

  &__secure-change-titles {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 30px;

    &--align-left{
      text-align: left;
      font-family: $gilroy-extra-bold;
    }
  }

  &__secure-change-msj {
    text-align: center;
    font-size: 1.5em;
    font-family: $gilroy-light;
    margin-bottom: 3%;
  }
  
  &__links {
    font-family: $gilroy-medium;
    margin: 1.5rem 0 0 1rem;
  }

  &__info-box {
    display: flex;
    flex-direction: column;
    padding: 2%;
    width: 300px;
    align-items: center;
    text-align: center;
    &--step-number {
      margin-bottom: 1.5rem;
      background: $primary;
      border-radius: 1.5em;
     -moz-border-radius: 1.5em;
     -webkit-border-radius: 01.5em;
     color: #ffffff;
     display: inline-block;
     font-weight: bold;
     font-size: 2rem;
     line-height: 1.5em;
     text-align: center;
     width: 1.5em;
     font-family: $gilroy-bold;
    }
  }

  &__images {
  margin-bottom: 3em;
  }

  &__logo {
    width: 65%;
    margin:  1rem 0 1.5rem;
  }
  .orange {
    color: $primary;
  }
  .extra-bold {
    font-family: $gilroy-extra-bold;
  }
  .black {
    font-family: $gilroy;
  }
  .default {
    font-family: $gilroy;
  }
  .light_orange {
    color: $secondary,
  }
}


@media screen and (max-width: 1420px) {
  .lo-cambio-premium {

    &__importance {
      font-size: 1em;
    }

    &__box, h2, h3, li, .default-text {
      font-size: 1rem;
    }

  }
}

@media screen and (max-width: 915px) {
  .lo-cambio-premium {

    &__container {
      width: 100%;
    }

    &__secure-change-titles {
      font-size: 25px;
  
      &--align-left{
        font-size: 18px;
      }
    }

    &__importance {
      font-size: 0.8em;
    }

    &__box, h2, h3, li, .default-text {
      font-size: 1rem;
    }

    &__box, .default-text {
      font-size: 1.2em;
    }

  }
}

@media screen and (max-width: 769px) {
  .lo-cambio-premium {
    display: flex;
    flex-direction: column;
    &__links {
      display: none;
    }
    &__container {
      margin: 0;
      padding: 0 2rem 0 2rem;
      &--top {
        &--left {
          align-items: flex-start;
        }
      }
    }
    &__secure-change-titles {
      font-size: 30px;
      align-items: center;
      padding: 0 15% 0 15%;
      margin: 0;
      &--align-left{
        font-size: 30px;
        text-align: left;
      }
    }
    .section {
      width: 100%;
    }
  }
}

@media screen and (max-width: 567px) {
  .lo-cambio-premium {
    flex-direction: column;
    margin: 0%;
    padding: 3%;
    .section {
      width: 100%;
      padding: 0%;
    }
    &__slider-container {
      margin: 0;
      width: 100%;
    }

    &__container {
      margin: 0 0 5% 0;
      &--top {
        flex-direction: column;
        margin-bottom: 0;
        &--left {
          width: 100%;
          align-items: center;
        }
        &--right {
          width: 100%;
          margin-bottom: 5%;
          &--box {
            width: 100%;      
            margin: 0 0 1rem 0;
            font-size: 1.5em;
          }
        }
      }
      &--mid {
        .lo-cambio-premium
        &--steps {
          flex-direction: column;
          align-items: center;
          width: 100%;
        }
        &--business{
          margin-top: 20px;
        }
  
      }
  
      &--bot {
        width: 100%;
      }
  
    }

    &__secure-change-titles {
      font-size: 30px;
  
      &--align-left{
        font-size: 28px;
        text-align: center;
      }
    }

    &__info-box {
    height: 350px;
    align-items: center;
    }

    &__logo {
      width: 60%;
    }

    &__importance {
      font-size: 0.5em;
    }

    &__links {
      display: none;
    }

    h2, h3, li, .default-text {
      font-size: 1rem;
    }

    ul li::marker {
      font-size: 0.8em;
    }

  }
}