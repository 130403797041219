@import 'src/variables';

yellowLight {
  color: $yellow-light;
}

font {
  color: $font;
}

ol li::marker {
  font-size: 25px;
  line-height: 0%;
}

.lo-cambio-seguro {
  display: flex;
  padding: 1rem 0 0 0;

  &__slider-container {
    width: 100%;
  }

  li {
    list-style-type: disc;
  }

  ul li::marker {
    font-size: 1.1em;
  }

  ol {
    margin-bottom: 5%;
  }

  .service_header{
    font-size: 2rem;
    text-align: center;
    font-family: $gilroy;
    color: $font-light;
    margin-bottom: 2rem;
    b{
      font-family: $gilroy-extra-bold;
    }
    span{
      color:$primary;
    }
  }

  h3 {
    color: white;
    margin-bottom: 3%;
    font-size: 1.5rem;
  }

  .purple-bold {
    color:$accent;
    font-family: $gilroy-extra-bold;
  }

  .purple-text {
    color: $accent;
  }

  .bold {
    font-family: $gilroy-extra-bold;
  }

  h2 {
    font-size: 1.5rem;
  }

  .default-text {
    color: $font;
    font-size: 1.5em;
    font-family: $gilroy-medium;
    margin-bottom: 5%;
  }

  .section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 75%;
    padding: 0 3% 0 3%;
  }

  .locambio-card{
    align-self: center;
    width: 65%;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 769px) {
      width: 80%;
    }
  }

  .servicesLC {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: max-content;
    margin-bottom: 2rem;
    flex-wrap: wrap;

    &__container {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: center;
      grid-gap: 1em;
      gap: 1em;
      @media screen and (max-width: 769px) {
        flex-direction: column;
        width: 100%;
        padding: 0;
      }
    }

    &__service {
      position: relative;
      min-width: 10rem;
      flex: 1 0 calc(25% - 1em);
      display: flex;
      flex-direction: column;
      border-radius: 5px;
      overflow: hidden;
      background-color: white;
      box-shadow: 0 3px 6px #00000029;
    }

    &__status-Bar {
      width: 100%;
      left: 32%;
      top: 5%;
      margin-top:1rem;
      transform: rotate(45deg);
      position: absolute;
      padding: 0.5rem 0;
      background-color: $secondary;
      color: $font;
      text-align: center;
      font-size: 0.80rem;
      font-family: $gilroy-extra-bold;
      text-transform: uppercase;
      z-index: 1;

      &--grey {
        background-color: #D6D6D6;
      }
    }

    &__logo {
      margin: 2rem 2rem 1rem 2rem;
      align-self: center;
      height: 130px;

      &__0 {
        width: 115px !important;
      }

      &__1 {
        width: 178px !important;
      }

      &__2 {
        width: 175px !important;
      }

      &--grey {
        filter: grayscale(1);
      }
    }

    &__info {
      padding-bottom: 1rem;
    }

    &__title {
      font-size: 1.25em;
      padding: 1em;
      margin: auto 0;
      color: $primary;
      font-family: $gilroy-extra-bold;
      text-transform: uppercase;
      &--grey{
        color:$font-light
      }
      &--accent {
        color: $accent;
      }
    }

    &__description {
      padding: 0 1em;
      text-align: left;
    }

    &__button-Container {
      padding: 2rem 0;
      display: flex;
      justify-content: center;
    }

    &__button {
      background-color: $accent;
      color: white;
      padding: 1rem 3rem;
      width: fit-content;
      border-radius: 5px;
      cursor: pointer;

      &--grey {
        background-color: #D6D6D6;
        cursor: not-allowed;
      }
    }
  }

  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    margin: 2rem 0 2rem 3%;
    padding: 0 2rem 0 2rem;
    border-radius: 10px;
    &--top {
      display: flex;
      flex-direction: row;
      &--left {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 60%;
        padding: 5%;

        &--important {
          margin-top: 20PX;
          // border: 1px solid red !important;
          // background-color: #f8b723;
        }
      }
      &--right {
        width: 40%;
        padding-top: 2em;

        &--boxTop {
          background: linear-gradient(41deg, rgba(85,140,215,1) 25%, rgba(127,82,218,1) 100%, rgba(0,212,255,1) 100%);
          margin-bottom: 0.7rem;
          // margin: 10% 0 0 2rem;
          // margin: 0;
          border-radius: 10px;
          padding: 1rem 1rem 0.25rem 1rem;
          color: white;
          // font-size: 1.5em;
        }

        &--boxBottom {
          background: transparent linear-gradient(400deg, #FF6D00 0%, #f8b723 100%) 0% 0% no-repeat padding-box;
          margin-bottom: 0.7rem;
          // margin: 10% 0 0 2rem;
          // margin: 0;
          border-radius: 10px;
          padding: 1rem 1rem 0.25rem 1rem;
          color: white;
        }
      }
    }
    &--mid {
      display: flex;
      flex-direction: column;
      align-items: center;
      &--steps {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        width: 100%;

      }

    }

    &--bot {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

  }

  &__secure-change-title {
    // border: 1px solid red;
    margin: 20px;
    font-size: 2.5em;
    color: $primary;
    &--black {
      color: $font,
    }
  }

  &__secure-change-title-secondary {
    font-size: 2.5em;
    color: $font;
    &--orange {
      color: $primary;
    }
  }

  &__secure-change-msj {
    text-align: center;
    font-size: 1.5em;
    color: $font-light;
    font-family: $gilroy-light;
    margin-bottom: 3%;
    &--black {
      color: $font;
      font-family: $gilroy-bold;
    }
    &--orange {
      color: $primary;
    }
  }

  &__links {
    font-family: $gilroy-medium;
    margin: 1.5rem 0 0 1rem;
  }

  &__info-box {
    display: flex;
    flex-direction: column;
    padding: 2%;
    width: 300px;
    height: 350px;
    margin: 2%;
    align-items: center;
    text-align: center;
    &--step-number {
      margin-bottom: 1.5rem;
      background: $primary;
      border-radius: 1.5em;
      -moz-border-radius: 1.5em;
      -webkit-border-radius: 01.5em;
      color: #ffffff;
      display: inline-block;
      font-weight: bold;
      font-size: 2rem;
      line-height: 1.5em;
      text-align: center;
      width: 1.5em;
      font-family: $gilroy-bold;
    }
  }

  &__logo {
    width: 85%;
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 769px) {
  .lo-cambio-seguro {
    display: flex;
    flex-direction: column;
    align-items: center;
    &__links {
      display: none;
    }
    &__container {
      margin: 0;
      padding: 0 2rem 0 2rem;
      &--top--right {
        padding-top: 1em;
      }
    }
    .section {
      width: 100%;
    }
    .locambio-services-slider .slider-container .servicesLC {
      &__status-Bar {
        width: 100%;
        left: 32%;
        top: 5%;
        margin-top:1rem;
        transform: rotate(45deg);
        position: absolute;
        padding: 0.5rem 0;
        background-color: $secondary;
        color: $font;
        text-align: center;
        font-size: 0.80rem;
        font-family: $gilroy-extra-bold;
        text-transform: uppercase;
        z-index: 1;
  
        &--grey {
          background-color: #D6D6D6;
        }
      }
    }  
  }
}

@media screen and (max-width: 1420px) {
  .lo-cambio-seguro {

    &__importance {
      font-size: 1em;
    }

    &__secure-change-title {
      font-size: 2em;
    }

    &__box, h2, h3, li, .default-text {
      font-size: 1rem;
    }

  }
}

@media screen and (max-width: 1137px) {
  .lo-cambio-seguro {

    &__secure-change-title {
      font-size: 1em;
    }
    &__container--top--right {
      padding-top: 1.75em;
    }

  }
}

@media screen and (max-width: 915px) {
  .lo-cambio-seguro {

    &__container {
      width: 100%;
    }

    &__importance {
      font-size: 0.8em;
    }

    &__box, h2, h3, li, .default-text {
      font-size: 0.8em;
    }

    &__secure-change-title {
      font-size: 1em;
    }

  }
}

@media screen and (max-width: 567px) {
  .lo-cambio-seguro {
    flex-direction: column;
    margin: 0%;
    padding: 3%;
    .section {
      width: 100%;
      padding: 0%;
    }
    &__container {
      margin: 0 0 5% 0;
      &--top {
        flex-direction: column;
        &--left {
          width: 100%;
        }
        &--right {
          width: 100%;
          padding: 5%;
          padding-top: 0;
          &--box {
            width: 100%;
            margin: 0 0 1rem 0;
            font-size: 1.5em;
          }
        }
      }
      &--mid {
        .lo-cambio-seguro
        &--steps {
          flex-direction: column;
          align-items: center;
          width: 100%;

        }

      }

      &--bot {
        width: 100%;
      }

    }

    &__info-box {
      height: 350px;
      align-items: center;
    }

    &__logo {
      width: 90%;
    }

    &__importance {
      font-size: 0.5em;
    }

    &__links {
      display: none;
    }

    h2, h3, li, .default-text {
      font-size: 1rem;
    }

    ul li::marker {
      font-size: 0.8em;
    }

    &__secure-change-title {
      font-size: 2rem;
    }

  }
}