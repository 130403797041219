.highlight {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    animation: highlight 1000ms ease-out;
  }
}

@keyframes highlight {
  0% {
    background-color: #ff7815;
  }

  100% {
    background-color: transparent;
  }
}
