@import 'src/variables', 'src/pages/chat/variables';

.list {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
  padding: $gap 0;
  overflow-y: hidden;
  position: relative;

  &__options {
    display: grid;
    grid-template-columns: 50% 50%;
    margin: 0 $gap;
  }

  &__items {
    overflow-y: auto;
    position: relative;
    list-style-type: none;
    padding: 0 0 0 20px;
  }
}