@import 'src/variables';

.lo-cambio-ads {
  display: flex;
  padding: 1rem 0 0 0;

  .big-title {
    font-size: 3rem;
    margin-bottom: 2%;
    line-height: 90%;
  }

  .medium-title{
    font-size: 2.1rem;
    margin-bottom: 2%;
  }

  .small-title {
    font-size: 2rem;
    margin-bottom: 2%;
  }

  .orange {
    color: $primary;
  }

  .purple {
    color: $accent;
  }

  .extra-bold {
    font-family: $gilroy-extra-bold;
  }

  .bold {
    font-family: $gilroy-extra-bold;
  }

  .default-text {
    color: $font;
    font-size: 1.5em;
    font-family: $gilroy-medium;
  }

  .steps-text{
    min-height: 120px;
    margin: 0 0 0 5px;
    color: $font;
    font-size: 1.5em;
    font-family: $gilroy-medium;
  }

  .section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 75%;
    padding: 0 3% 0 3%;
  }

  .locambio-card{
    align-self: center;
    width: 65%;    
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__slider-container {
    width: 100%;
  }

  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    margin: 2rem 0 2rem 3%;
    padding: 0 2rem 0 2rem;
    border-radius: 10px;

    &__top {
      display: flex;
      flex-direction: column;
      margin-bottom: 2rem;
      &__logo {
        display: flex;
        flex-direction: row;
        &--left {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 40%;
          padding: 5%;
        }
        &--right {
          width: 60%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          &--box {
            width: 85%;
            background: rgb(85,140,215);
            background: linear-gradient(41deg, rgba(85,140,215,1) 25%, rgba(127,82,218,1) 100%, rgba(0,212,255,1) 100%);       
            margin-bottom: 1rem;
            margin: 15% 0 0 2rem;
            border-radius: 10px;
            padding: 1rem 1rem 0.5rem 1rem;
            color: white;
            font-size: 1.5em;
          }
        }
      }
      &__description {
        margin-left: 7%;
        text-align: left;
      }
    }

    &--mid {
      display: flex;
      flex-direction: column;
      align-items: center;
      &--steps {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
      }
      &--business {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        margin-top: 2%;
      }
      &--bottom-text {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 2.5rem 0 1rem 0;
        padding: 0 15% 0 15%;
        font-family: $gilroy-medium;
        font-size: 1.4em;
        p {
          text-align: center;
        }
        .coin-img {
          margin: 2%;
          width: 43px;
          height: 43px;
        }
      }
    }

    &--bot {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

  }

  &__titles {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 32px;
    margin: 0;

    &--align-left{
      text-align: left;
      font-family: $gilroy-extra-bold;
    }
  }

  &__secure-change-msj {
    text-align: center;
    font-size: 1.5em;
    font-family: $gilroy-light;
    margin-bottom: 3%;
  }
  
  &__links {
    font-family: $gilroy-medium;
    margin: 1.5rem 0 0 1rem;
  }

  &__info-box {
    display: flex;
    flex-direction: column;
    padding: 2%;
    width: 300px;
    align-items: center;
    text-align: center;
    &--step-number {
      margin-bottom: 1.5rem;
      background: $primary;
      border-radius: 1.5em;
     -moz-border-radius: 1.5em;
     -webkit-border-radius: 01.5em;
     color: #ffffff;
     display: inline-block;
     font-weight: bold;
     font-size: 2rem;
     line-height: 1.5em;
     text-align: center;
     width: 1.5em;
     font-family: $gilroy-bold;
    }
  }

  &__images {
  margin-bottom: 3em;
  }

  &__logo {
    width: 90%;
    margin-bottom: 1rem;
  }
}


@media screen and (max-width: 1200px) {
  .lo-cambio-ads {

    &__importance {
      font-size: 1em;
    }

    &__box, h2, h3, li, .default-text {
      font-size: 1rem;
    }

    .big-title {
      font-size: 2.5rem;
      margin-bottom: 2%;
      line-height: 90%;
    }
    .little-title {
      font-size: 1.5rem;
      margin-bottom: 2%;
    }
    &__container {
      &--mid {
        &--bottom-text {
          margin-top: 2.5em;
        }
      }
    }
  }
}

@media screen and (max-width: 1025px) {
  .lo-cambio-ads {

    &__container {
      width: 100%;
    }

    &__titles {
      font-size: 25px;
  
      &--align-left{
        font-size: 18px;
      }
    }

    &__importance {
      font-size: 0.8em;
    }

    &__box, h2, h3, li, .default-text {
      font-size: 1rem;
    }

    &__box, .default-text {
      font-size: 1.2em;
    }
    .big-title {
      font-size: 2rem;
      margin-bottom: 2%;
      line-height: 90%;
    }
    .little-title {
      font-size: 1rem;
      margin-bottom: 2%;
    }

  }
}

@media screen and (max-width: 769px) {
  .lo-cambio-ads {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__links {
      display: none;
    }
    &__container {
      margin: 0;
      padding: 0 2rem 0 2rem;
    }
    &__titles {
      font-size: 28px;
        padding: 0;
        margin: 0;
      &--align-left{
        font-size: 30px;
      }
    }

    .steps-text {
      font-size: 1.2em;
    }

    .default-text {
      margin: 0;
      font-size: 1.3em;
    }

    .locambio-card{
      width: 80%;
    }

    &__container {
      &__top {
        &__description {
          margin: 2.5%;
        }
      }
      &--mid {
        &--bottom-text {
          margin-top: 0;
          font-size: 1.3em;
          padding: 0 6% 0 6%;
          .coin-img {
            margin: 2%;
            width: 33px;
            height: 33px;
          }
        }
      }
    }

    .section {
      width: 100%;
    }
    .big-title {
      font-size: 2.3rem;
      margin-bottom: 2%;
      line-height: 90%;
    }
    .little-title {
      font-size: 1.5rem;
      margin-bottom: 2%;
    }
    .default-text {
      width: 100%;
    }
  }
}

@media screen and (max-width: 576px) {
  .lo-cambio-ads {
    flex-direction: column;
    margin: 0%;
    padding: 3%;

    .section {
      width: 100%;
      padding: 0%;
    }

    .steps-text {
      font-size: 1.5em;
    }

    &__slider-container {
      margin: 0;
      width: 100%;
    }

    &__container {
      margin: 0 0 5% 0;
      &__top {
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;
        &__logo {
          display: flex;
          flex-direction: column;
          align-items: center;
          &--left {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            padding: 5%;
          }
          &--right {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;

          }
        }
        &__description {
          text-align: left;
          margin: 0;
        }
      }
      &--mid {
        .lo-cambio-ads
        &--steps {
          flex-direction: column;
          align-items: center;
          margin-bottom: 0;
          padding-bottom: 0;
          width: 100%;
        }
        &--business{
          flex-direction: column;
          align-items: center;
          margin-top: 20px;
        }
        &--bottom-text {
          font-size: 1.35em;
          padding: 0;
          .coin-img {
            margin: 2%;
            width: 33px;
            height: 33px;
          }
        }
  
      }
  
      &--bot {
        width: 100%;
      }
  
    }

    &__titles {
      font-size: 30px;
  
      &--align-left{
        font-size: 28px;
        text-align: center;
      }
    }

    &__info-box {
    height: 350px;
    align-items: center;
    }

    &__importance {
      font-size: 0.5em;
    }

    &__links {
      display: none;
    }

    h2, h3, li, .default-text {
      font-size: 1.4rem;
    }

    ul li::marker {
      font-size: 0.8em;
    }

    .big-title {
      font-size: 3rem;
      margin-bottom: 2%;
      line-height: 90%;
    }
    .little-title {
      font-size: 1.5rem;
      margin-bottom: 2%;
      line-height: 90%;
    }

    .medium-title{
      line-height: 2.5rem;
    }
  }
}