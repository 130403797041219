@import "src/variables";

.custom-calendar {
  background-color: $bg-light;
  max-width: 300px;
  padding: 0 1em 0 1em !important;
  border-radius: 5px;
  position: relative;

  &__cross {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    font-size: 14px;
    background-color: $bg-light;
    border-radius: 5px 5px 0 0;
    z-index: 20;
    right: 0.3em;
    top: 0.3em;
    svg {
      width: 1.4em;
      height: 1.4em;
      margin: 1.5% 1.5% 0 0;
    }

    svg:hover {
      background-color: #ddd;
    }
  }

  &__content {
    margin-top: 0.7em;
    &__appointments_calendar {
      font-family: $gilroy-extra-bold;
      font-size: 0.8em;
      text-align: center;
      margin-top: 2.5em;
    }
    &__appointments-title {
      font-family: $gilroy-extra-bold;
      font-size: 0.8em;
      text-align: center;
      margin: 1em 0 1em 0;
    }
    &__schedule-select {
      margin: 0 0 1em 0;
    }
    &__appointments-button {
      align-self: center;
      max-width: 100px;
      margin-bottom: 1em;
    }
  }

  .react-calendar {
    background-color: $bg-light;
    color: white;
    border: none;
    font-family: $gilroy-bold;

    abbr {
      font-family: $gilroy-bold;
    }

    &__month-view {
      &__days {
        &__day {
          &--weekend abbr {
            color: $font-extra-light;
          }
        }
      }

      &__weekdays {
        &__weekday abbr {
          color: $dark-purple;
          text-decoration: none;
        }
      }
    }

    &__tile {
      border-radius: 50%;
      &--now {
        background-color: rgba(213, 65, 196, 0.307);
        :enabled:hover {
          background-color: rgba(174, 36, 174, 0.349);
        }
      }
      &--now:enabled:hover {
        background-color: rgba(174, 36, 174, 0.349);
      }
      &--active {
        background-color: $accent !important;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &__navigation__label {
      pointer-events: none;
    }

    &__navigation {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: $gilroy-bold;
      margin: 0;

      &__label {
        font-family: $gilroy-medium;
        max-width: 60%;
        color: $dark-purple;
        &::first-letter {
          text-transform: uppercase;
        }
      }

      &__arrow {
        font-size: 3rem;
        padding-bottom: 10px;
        font-family: "Lucida Sans";
        color: $dark-purple;
      }

      &__prev-button {
        text-align: right;
      }

      &__next-button {
        text-align: left;
      }
    }
  }
}
