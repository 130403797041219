@import "src/variables";
.step2 {
  .word-counter {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    font-family: $gilroy;
    font-size: 14px;
    margin: 5px 3px 0 0;
  }

  .red-text {
    color: $alert;
  }

  .blur-image {
    &__picture {
      width: 150px;
      height: 150px;

      &--background {
        width: 150px;
        height: 150px;
      }
      &--img_box img {
        width: 150px;
        height: 150px;
      }
    }
  }

  .suggestions-bar {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1%;
    .suggestions-input {
      width: 100%;
    }
  }

  .map {
    width: 95%;
    background-color: blanchedalmond;
    margin: auto;
    height: 25rem;
  }
  .bp3-form-group {
    width: 45%;
    @media (max-width: 769px) {
      width: 95%;
    }
  }
  .content-Formater {
    justify-content: flex-start;
    .bp3-form-group {
      width: 15rem;
    }
  }
  .dollarLabel {
    .bp3-form-content {
      gap: 0;
      grid-gap: 0;
      justify-self: center;
      align-items: center;
    }
  }

  .full-Width {
    width: 100%;
  }
  .loadImage {
    width: 15rem;
    height: 17rem;
    background-color: blanchedalmond;
    margin: 1rem;
  }
  .input-With-Button {
    display: flex;
    width: 100%;
    flex-direction: row;
    margin-bottom: 1rem;
    @media (max-width: 769px) {
      width: 95%;
    }
    .bp3-input-group {
      width: 40%;
      @media (max-width: 769px) {
        width: 100%;
      }
    }

    .bp3-tag {
    }
  }

  .scroll-container {
    overflow-x: auto;
  }

  .scroll-container {
    overflow-x: auto;
    padding-bottom: 1em;
  }

  .fileRow {
    justify-content: flex-end;
    flex-direction: row-reverse;
    display: inline-flex;
    white-space: nowrap;
    width: 100%;
    .bp3-form-group {
      width: fit-content;
    }
    .firstElem {
      order: 1;
    }
    .last-element {
      margin-right: auto;
    }
    .minWidth {
      min-width: 12rem;
    }
  }

  .purple-text {
    color: #8051da;
  }
  .columnRadio {
    .bp3-form-content {
      flex-direction: column;
    }
    .radioTags {
      flex-direction: column;
    }
  }
}

.step-one-seggestion-tags {
  margin: 0.7em 0.8em 0.5em 0;
  background: white;
  color: $accent !important;
  font-family: $gilroy-medium !important;
  border: #8051da solid 1px;
  border-radius: 5px;
}

.step-one-seggestion-tags-error {
  margin: 0.5em 0.8em 0.5em 0;
  background: white;
  color: $alert !important;
  font-family: $gilroy-medium !important;
  border: $alert solid 1px;
  border-radius: 5px;
}

.step-one-suggestion-pop {
  max-width: 70vw;
  padding: 6px;
}

.step-one-inputLabel {
  margin: 0;
}
