@import "src/variables";

.activity-successful-change{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  
  @media screen and (max-width: 590px) {
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 1025px ) and (min-width: 769px){
    flex-direction: column;
    align-items: center;
  }
}
