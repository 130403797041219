@import 'src/variables';

.change-proposal-item {
  padding: 0 5px 0 5px;
  position: relative;
  &__layer{
    position: absolute;
    background-color: rgb(0, 0, 0);
    width: 99%;
    height: 100%;
    left: 3;
    top: 0;
    opacity: 0.5; 
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p{
      color: rgb(255, 255, 255);
      opacity: 1; 
    }
  }

  &__card {
    padding: 0;
    margin: 0.1em 0 0.4em 0;
    box-shadow: 0 1px 7px -1px #00000045;
    -webkit-box-shadow: 0 1px 7px -1px #00000045;
    -moz-box-shadow: 0 1px 7px -1px #00000045;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &--left {
      display: flex;
      align-items: center;
      width: min-content;

      &-img {
        margin: 0.3em;

        .small {
          &__picture {
            width: 50px;
            height: 50px;

            &--background {
              width: 50px;
              height: 50px;
            }

            &--img_box img {
              width: 50px;
              height: 50px;
            }
          }
        }
      }

    }

    &--center {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      text-align: left;
      width: 100%;

      &-divider {
        width: 99%;
        margin: 0.5em 0 0.2em 0;
      }

      &-title {
        margin: 0.5em 0 0 0.5em;
        font-size: 12px;
        font-family: $gilroy-medium;
        align-self: flex-start;
      }

      &-price {
        font-size: 13px;
        align-self: flex-end;
        font-family: $gilroy-bold;
        margin-bottom: 0.5em;
        color: $dark-purple;
      }

    }

    &--right {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: min-content;

      .bp3-control.bp3-checkbox {
        margin: 0.9em 0.5em 1em 0.1em;
      }

      .tag_small {
        min-width: 50px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        p {
          color: white;
          padding: 0.3em;
          border-radius: 5px;
          background-color: $font;
          font-family: $gilroy;
          font-size: 12px;
        }
      }

      &-checkbox {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      &-cross {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        background-color: $bg-light;
        margin: 1em;
        border-radius: 5%;
        cursor: pointer;

        &:hover {
          background-color: #e5e5e5;
        }
      }

    }
  }
}


.custom-checkbox .bp3-control-indicator {
  background-color: white !important;
  border: 1px solid $accent;
  border-radius: 25% !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}