@import 'src/variables';

.change-operation {
  width: 90%;
  max-width: 650px;
  font-size: 15px;

  &__page_with_title {
    &__header{
      width: 90%;
      max-width: 46em;
    }
  }

  &__send_request {
    &--button {
      margin-top: 0.7rem;
      width: 170px;
      height: 40px;
      background-color: $accent;
      border-radius: 5px;
      box-shadow: 0px 2px 2px $shadow-color;
      font-family: $gilroy-bold;
      cursor: pointer;
      padding: 5px;

      a {
        color: white;
        display: flex;
        align-items: center;
      }
    }
  }

  .fullWidth {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}