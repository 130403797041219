@import 'src/variables';

.accordion {
  position: sticky;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 17rem;
  z-index: 1;

  &__title {
    color: $accent;
    font-family: $gilroy-extra-bold;
    font-size: 40px;
    text-align: center;
    padding: 1rem 0;
  }

  &__section {
    &-title {
      font-family: $gilroy-extra-bold;
      font-size: 25px;
      text-align: center;
      padding: 1rem 0;
    }

    &__question {
      background-color: white;
      opacity: 0.9;
      border-radius: 5px;
      margin: 1rem 0;
      padding: 0 5rem;

      &-container {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &-title {
        font-family: $gilroy-extra-bold;
        font-size: 1rem;
        text-align: center;
        padding: 1rem 0;
        color: $accent;
      }

      &-icon {
        position: absolute;
        right: 1rem;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .accordion {
    left: 0;
    margin: 0 1rem;
    &__title {
      font-size: 28px;
    }

    &__section {
      &__question {
        padding: 0 3rem;
        &-title {
          padding: 1rem 0;
        }
      }
    }
  }
}
