@import 'src/variables', 'src/pages/chat/variables';

.panel-header {
  background-color: white;
  overflow: hidden;

  .show-pointer {
    cursor: pointer;
  }

  .hide-card {
    cursor: default;
    border: none;
    box-shadow: none;
    white-space: pre-line;
  }

  .AvatarImage,
  .AvatarInitials {
    width: 2.5em;
    height: 2.5em;
    margin-right: 1em;
  }

  &__navigation {
    display: grid;
    grid-template-columns: 2em auto 1fr 3.5em;
    grid-template-rows: 3.5em;
    align-items: center;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;

    &--down {
      transform: rotate(180deg);
    }
  }

  &__info {
    display: grid;
    grid-template-columns: 1fr auto;
    margin-bottom: .75em;
  }

  &__image-wrapper {
    grid-row: 1 / -1;
    grid-template-columns: min-content;
    width: 70%;
    height: 100%;
    border-radius: $chat-base-radius;
    border-radius: unset;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__left {
      margin-right: 7px;
    }

    &__right {
      margin-left: 7px;
    }

    .small {
      margin-left: 2em;

      &__picture {
        width: 100px;
        height: 100px;

        &--background {
          width: 100px;
          height: 100px;
        }

        &--img_box img {
          width: 100px;
          height: 100px;
        }
      }
    }
  }

  &__content-wrapper {
    display: grid;
    grid-template-columns: 0.3fr 1fr;
    grid-template-rows: repeat(3, auto);
    align-items: center;
    border-bottom: 1px solid $shadow-color;
    padding: 0 .5em;
  }

  &__content {
    display: flex;
    gap: 10px;
    padding: 0 10px;

    &--interests {
      cursor: pointer;
      flex-basis: 50%;
      padding: 5px;
      display: flex;
      border: 2px solid #E8E8E8;
    }
  }

  &__left {
    justify-content: start;
  }

  &__right {
    justify-content: end;
  }

  &__title {
    display: inline;
    font-family: $gilroy-extra-bold;
    font-size: 1.1em;

    &--arrow {
      padding: 0 !important;
      margin: 0 10px 3px;
    }

    &--accent {
      color: $accent;
      text-align: right;
    }

    &--primary {
      color: $primary;
      text-align: left;
    }

    &--sub-title {
      font-family: $gilroy-medium;
      font-size: 1em;
      font-weight: normal;
      color: $font-light;

      a {
        color: #464646;
      }
    }

    &--link {
      font-family: $gilroy-bold;
      font-size: 15px;
      font-weight: normal;
      color: $dark-purple;

      &--written-proposal {
        &:hover {
          cursor: none !important;
        }
      }

      a {
        color: #464646;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__date {
    font-size: .75em;
    font-family: $gilroy-medium;
    grid-column: 1 / -1;
    grid-row: 1;
    text-align: right;
    margin-right: 0.45em;
  }

  &__center-buttons {
    display: flex;
    justify-content: center;
  }

  @media ($sm) {
    box-shadow: $shadow;
    border-radius: $chat-base-radius;

    &__navigation {
      display: none;
    }

    &__content-wrapper {
      grid-template-columns: 145px 1fr auto;
      align-items: unset;
      border-bottom: unset;
      padding: unset;
    }

    &__date {
      grid-column: unset;
      grid-row: unset;
      text-align: left;

    }

    &__content {
      padding-top: 10px;
    }

    &__image-wrapper {
      grid-row: 1 / -1;
      width: 100%;
      height: 100%;
      border-radius: unset;
    }

    &__center-buttons {
      display: flex;
      justify-content: flex-start;
    }

  }

  @media (max-width: 768px) {

        &__title {

          &--sub-title {
            font-size: 0.9em;
          }

        }
  }

  @media (max-width: 575px) {
    .show-pointer { flex-basis: 60%; }
    .hide-card    { flex-basis: 40%; }
  }

  @media (min-width: 576px) and (max-width: 767px) {
    .show-pointer { flex-basis: 65%; }
    .hide-card    { flex-basis: 35%; }
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    .show-pointer { flex-basis: 75%; }
    .hide-card    { flex-basis: 25%; }
  }

  @media (min-width: 1025px) and (max-width: 1119px) {
    .show-pointer { flex-basis: 65%; }
    .hide-card    { flex-basis: 35%; }
  }

  @media (min-width: 1120px) {
    .show-pointer { flex-basis: 75%; }
    .hide-card    { flex-basis: 25%; }
  }

} 