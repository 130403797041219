@import 'src/variables';

.locambio-promos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5px;

  &__info {
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 100%;

    &--active {
      margin-top: 20px;
    }
  }

  &__title {
    font-size: 21px;
  }

  &__views {
    font-size: 50px;
    font-family: $gilroy-medium;
  }

  @media ($sm) {
    gap: 10px;

    &__info {
      &--active {
        margin-top: 40px;
      }
    }
  }
}
