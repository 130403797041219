@import "src/variables";

.LoginDropdown {
  width: 100%;
  display: grid;
  justify-content: center;

  .icon {
    color: $accent;
    margin-left: 5px;
  }

  .collapse {
    font-family: $gilroy-light;
    font-size: medium;
    font-weight: bold;
    line-height: 30px;
    margin-top: -10px;
    text-align: center;

    .collapseWords {
      color: $accent;
      outline: none;
    }
  }


}