@import "src/variables";

.share-button {
  border-radius: 10%;
  margin-top: 3px;
  color: $accent;
  display: flex;
  align-items: center;
  font-size: 17px;
  gap: 5px;
  font-family: $gilroy-extra-bold;

  &:hover {
    transform: translateY(-2px);
  }
  &:active {
    // box-shadow: 2px 2px 2px $font;
  }

  &__image {
    width: 20px;
    max-width: none;
  }
}

.share-modal {
  &__container {
    background-color: $bg;
    border-radius: 30px;
    height: 255px;
    & h2 {
      padding: 1rem;
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    & button {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
    }
  }
  &__URL {
    display: flex;
    justify-content: space-around;
    padding: 2rem 1rem 1rem 1rem;
    &-icon {
      padding: 0.5rem;
      background-color: #464646;
      color: #fff;
      border-radius: 50%;
      z-index: 1;
    }
    &-input {
      margin-left: -1.5rem;
      width: 240px;
      z-index: 0;
      padding-left: 1rem;
      border: 2px solid $accent;
    }
  }
  &__button {
    display: flex;
    justify-content: flex-end;
    margin-right: 1.5rem;
    & button {
      width: 120px;
      background-color: $accent;
      border-radius: 5px;
      padding: 0.5rem;
      box-shadow: 2px 2px 2px $font;
      color: $bg;
      &:hover {
        background-color: $primary;
      }
      &:active {
        box-shadow: 2px 2px 2px $font;
        transform: translateY(-4px);
      }
    }
  }
}
