@import "src/variables";

.payment-resume-modal {
  margin-top: 1em;
  background-color: $bg-light;
  padding: 3em 4em 3em 3em;
  font-family: $gilroy-medium;
  font-size: 14px;
  &__title {
    text-align: left;
    margin-bottom: 1em;
  }
  &__details {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
  .div1 {
    grid-area: 1 / 1 / 2 / 4;
    text-align: left;
  }
  .div2 {
    grid-area: 1 / 4 / 2 / 10;
    justify-self: end;
    font-family: $gilroy-bold;
  }
  .div3 {
    grid-area: 2 / 1 / 3 / 5;
    text-align: left;
  }
  .div4 {
    grid-area: 2 / 4 / 3 / 10;
    justify-self: end;
  }
  .div5 {
    grid-area: 3 / 4 / 3 / 10;
    text-align: right;
    &__tax{
      font-size: 12px;
    }
  }
  .div6 {
    grid-area: 3 / 4 / 4 / 10;
    justify-self: end;
  }

  .div7 {
    grid-area: 3 / 1 / 3 / 5;
    text-align: left;
    
    &__tax{
      font-size: 12px;
    }
  }
  .div8 {
    grid-area: 3 / 4 / 4 / 10;
    justify-self: end;
  }

  &__total {
    text-align: right;
    font-weight: bold;
    &--remaining{
      font-size: 12px;
      font-weight: normal;
    }
    &--bs{
      font-size: 14px;
    }
  }
}

