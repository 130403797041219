@import "src/variables";
.password-validator {
  &__container {
    ul {
      padding: 0;
    }
    li {
      list-style-type: none;
    }
  }

  &__icon {
    &--green {
      color: green;
    }

    &--red {
      color: red;
    }
  }
}
