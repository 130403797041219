@import 'src/variables';

.text {
  &--primary {
    color: $primary;
  }

  &--accent {
    color: $accent;
  }

  &--green {
    color: $green;
  }

  &--blue {
    color: $blue
  }

  &--white {
    color: white;
  }
}