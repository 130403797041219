@import 'src/variables';

.search {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  margin: 0 1rem 0 1rem;

  @media screen and (min-width: 1024px) {
    margin: 0 1rem 0 3rem;
  }

  &__tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $gilroy-extra-bold;
    font-size: 1rem;
    text-align: center;
    cursor: pointer;

    &-values {
      background-color: $font-light;
      width: 114px;
      padding: 0.5rem;
      border-radius: 0px 5px 0px 0px;
    }

    &-orange {
      background-color: $primary;
      width: 114px;
      padding: 0.5rem;
    }

    &-purple {
      background-color: $accent;
      width: 114px;
      padding: 0.5rem;
      border-radius: 5px 0px 0px 0px;
    }

    &-disable--orange {
      width: 114px;
      background-color: $bg;
      padding: 0.5rem;
      color: $font-light;
      box-shadow: -2px 0 2px -2px $font-light inset ,  1px 0 1px -1px $font-light inset ;
    }

    &-disable--values {
      width: 114px;
      background-color: $bg;
      padding: 0.5rem;
      color: $font-light;
      border-radius: 0px 5px 0px 0px;
    }

    &-disable--purple {
      width: 114px;
      background-color: $bg;
      color: $font-light;
      padding: 0.5rem;
      border-radius: 5px 0px 0px 0px;
    }
  }

  &__bars {
    border-radius: 0px 5px 5px 5px;
    overflow: hidden;
  }

  &__bar {
    display: flex;
    align-items: center;
    width: 100%;

    &-icon {
      color: $green;
      margin-left: 0.2rem;
      margin-right: 0.2rem;
    }
  }

  &__bar-purple {
    background-color: white;
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    box-shadow: 0px 2px 2px $shadow-color;
    border-radius: 0px 5px 5px 5px;

    & p {
      margin: 0 1rem;
      color: $font-light;
      font-family: $gilroy-medium;
      font-size: 1rem;

      & span {
        color: $accent;
      }
    }

    & input {
      width: 100%;
      height: 100%;
      font-family: $gilroy-medium;
      font-size: 1rem;
      padding: 0 1rem;
      background-color: white;
      box-shadow: none;

      &:focus {
        outline: none;
      }
    }
  }

  &__bar-orange {
    background-color: white;
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    box-shadow: 0px 2px 2px $shadow-color;
    border-radius: 0px 5px 5px 5px;

    & p {
      margin: 0 1rem;
      color: $font-light;
      font-family: $gilroy-medium;
      font-size: 1rem;

      & span {
        color: $primary;
      }
    }

    & input {
      width: 100%;
      height: 100%;
      font-family: $gilroy-medium;
      font-size: 1rem;
      padding: 0 1rem;
      background-color: white;
      box-shadow: none;

      &:focus {
        outline: none;
      }
    }
  }

  &__bar-values {
    background-color: white;
    display: inline-flex;
    align-items: center;
    height: 40px;
    box-shadow: 0px 2px 2px $shadow-color;

    cursor: pointer;
    width: 171px;

    @media screen and (min-width: 321px) {
      width: 50%;
    }

    & p {
      margin: 0 1rem;
      color: $font-light;
      font-family: $gilroy-medium;
      font-size: 14px;

      & span {
        color: $primary;
      }
    }

    & input {
      width: 100%;
      height: 100%;
      font-family: $gilroy-medium;
      font-size: 14px;
      padding: 0 1rem;
      box-shadow: none;

      &:focus {
        outline: none;
      }
    }
  }

  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
  }
}

.mobile {
  margin: 0;
  font-size: 14px;

  &__tabs {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: $gilroy-extra-bold;
    font-size: 14px;
    text-align: center;

    &-values {
      background-color: $font-light;
      padding: 0.5rem;
      border-radius: 0px 5px 0px 0px;
      width: 100px;

      @media screen and (min-width: 321px) {
        width: 100px;
      }
    }

    &-orange {
      background-color: $primary;
      padding: 0.5rem;
      width: 100px;

      @media screen and (min-width: 321px) {
        width: 100px;
      }
    }

    &-purple {
      background-color: $accent;
      padding: 0.5rem;
      border-radius: 5px 0px 0px 0px;
      width: 100px;

      @media screen and (min-width: 321px) {
        width: 100px;
      }
    }

    &-disable--values {
      background-color: $bg;
      padding: 0.5rem;
      color: $font-light;
      border-radius: 0px 5px 0px 0px;
      width: 100px;

      @media screen and (min-width: 321px) {
        width: 100px;
      }
    }

    &-disable--orange {
      background-color: $bg;
      padding: 0.5rem;
      color: $font-light;
      box-shadow: -2px 0 2px -2px $font-light inset ,  1px 0 1px -1px $font-light inset ;
      width: 100px;

      @media screen and (min-width: 321px) {
        width: 100px;
      }
    }

    &-disable--purple {
      background-color: $bg;
      color: $font-light;
      padding: 0.5rem;
      border-radius: 5px 0px 0px 0px;
      width: 100px;

      @media screen and (min-width: 321px) {
        width: 100px;
      }
    }
  }

  &__bars {
    border-radius: 0px 0px 5px 5px;
    overflow: hidden;
  }

  &__bar {
    display: flex;
    align-items: center;
    width: 100%;

    &-icon {
      color: $green;
      justify-self: flex-end;
      margin-left: 0.2rem;
      margin-right: 0.2rem;
    }
  }

  &__bar-purple {
    background-color: white;
    display: flex;
    align-items: center;
    height: 40px;
    box-shadow: 0px 2px 2px $shadow-color;
    border-radius: 0px 0px 5px 5px;
    cursor: pointer;
    width: 260px;

    @media screen and (min-width: 321px) {
      width: 300px;
    }

    & p {
      margin: 0 1rem;
      color: $font-light;
      font-family: $gilroy-medium;
      font-size: 14px;

      & span {
        color: $accent;
      }
    }

    & input {
      width: 100%;
      height: 100%;
      font-family: $gilroy-medium;
      font-size: 14px;
      padding: 0 1rem;
      background-color: white;
      box-shadow: none;

      &:focus {
        outline: none;
      }
    }
  }

  &__bar-orange {
    background-color: white;
    display: flex;
    align-items: center;
    height: 40px;
    box-shadow: 0px 2px 2px $shadow-color;
    border-radius: 0px 0px 5px 5px;
    cursor: pointer;
    width: 260px;

    @media screen and (min-width: 321px) {
      width: 300px;
    }

    & p {
      margin: 0 1rem;
      color: $font-light;
      font-family: $gilroy-medium;
      font-size: 14px;

      & span {
        color: $primary;
      }
    }

    & input {
      width: 100%;
      height: 100%;
      font-family: $gilroy-medium;
      font-size: 14px;
      padding: 0 1rem;
      background-color: white;
      box-shadow: none;

      &:focus {
        outline: none;
      }
    }
  }

  &__bar-values {
    background-color: white;
    display: inline-flex;
    align-items: center;
    height: 40px;
    box-shadow: 0px 2px 2px $shadow-color;
    
    .left {
      border-radius: 15px;
      background-color: aqua;
    }

    .right {
      border-radius: 0px 0px 5px 0px;
      background-color: aqua;
    }

    cursor: pointer;
    width: 260px;

    @media screen and (min-width: 321px) {
      width: 150px;
    }

    & p {
      margin: 0 1rem;
      color: $font-light;
      font-family: $gilroy-medium;
      font-size: 14px;

      & span {
        color: $primary;
      }
    }

    & input {
    width: 100%;
      height: 100%;
      font-family: $gilroy-medium;
      font-size: 14px;
      padding: 0 1rem;
      box-shadow: none;

      &:focus {
        outline: none;
      }
    }
  }

  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
  }
}