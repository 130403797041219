@import 'src/variables';
.step1{
  .map{
    width: 95%;
    background-color: blanchedalmond;
    margin: auto;
    height: 25rem;
  }
  .interest{
    margin: 1rem;
    margin-top: 0;
    border-radius: 5px;
    min-height: 9rem;
    height: auto;
    h4{
      font-size: 20px;
    }
    
  }
  .no-interests{
    margin: 0.01rem 0.5rem;
    padding: 1.5rem;
    .bp3-icon{
      margin-right: 0.5rem;
    }
  }
  .bp3-form-group{
    width: 45%;
    @media (max-width:769px) {
      width: 95%;
    }
  }
  .input-With-Button{
    display: flex;
    width: 100%;
    flex-direction: row;
    margin-bottom: 1rem;
    padding: 0.5rem;
    @media (max-width:769px) {
      width: 95%;
    }
    .bp3-input-group{
      width: 40%;
      @media (max-width:769px) {
        width: 100%;
      }
    }
  }
  .disabled{
    opacity: 0.75;
  }
  .row{
    justify-content: flex-start;
    margin: 1.5rem 2rem;
    @media (max-width:769px) {
      margin: 0;
    }
  }
  .interesItem{
    margin:0.5rem 0;
    background-color: #FFFFFF;
    width: fit-content;
    font-family: $gilroy;
    border-radius: 5px;
    padding: 0.5rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    p{
      margin-right: 0.3rem;
    }

    
  }
  
}
