@import "src/variables";

.pop-up {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &__icon__container {
    display: contents;
  }

  &__cross {
    position: absolute;
    margin-top: 0.25rem;
    margin-right: 0.25rem;
    align-self: flex-end;
    color: #464646;
    font-size: 36px;
    &:hover {
      color: $accent;
    }
  }
}

.bp3-dialog {
  max-width: fit-content;
}
