.selectWithInput {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 0px 5px 5px 0px;
  width: 100%;
  height: 2.1rem;
}
.inputField .selectWithInput {
  border-radius: 0px 5px 5px 0px;
  width: 95%;
  .bp3-input {
    width: 90%;
  }
}
.box {
  width: 100%;
}
