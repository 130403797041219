@import 'src/variables';

.show-image{
  &__image {
    position: relative;
    white-space: normal;
    height: fit-content;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    color: $font-light;
    border: 3px $light-gray;
    background-color: $light-gray;
    border-style: solid;
    font-family: "gilroy", Helvetica, Arial, serif;
    font-size: 14px;
    font-weight: 10;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
  }
}