@font-face {
  font-family: "gilroy-heavy";
  src: url("./Assets/Fonts/gilroy/gilroy-heavy.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gilroy-extra-bold";
  src: url("./Assets/Fonts/gilroy/gilroy-extrabold.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gilroy-bold";
  src: url("./Assets/Fonts/gilroy/gilroy-bold.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gilroy-medium";
  src: url("./Assets/Fonts/gilroy/gilroy-medium.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gilroy";
  src: url("./Assets/Fonts/gilroy/gilroy-regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gilroy-light";
  src: url("./Assets/Fonts/gilroy/gilroy-light.otf");
  font-weight: normal;
  font-style: normal;
}