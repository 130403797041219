@import "src/variables";
.ProfileBanner{
  width: 100%;
  .swiper {
    width: 100%;
  }
  .footer{
    margin: 0.5em 0 2em 0;
    font-family: $gilroy-extra_bold;
    font-size: 17px;
  }
  overflow-x: hidden;
}

@media screen and (max-width: 800px) {
  .ProfileBanner{
    .swiper {
     width: 100%;
    }
  }
}