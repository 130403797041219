@import "src/variables";

.successful-change-status{
  width: 140px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 14;
  .activity-img{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    img{
      width: 90%;
    }
  }
  .title{
    text-align: center;
    font-family: $gilroy-extra-bold;
    background: #E8E8E8 0% 0% no-repeat padding-box;
    border-radius: 6px;;
    margin: 1.2em 0 2em 0;
    padding: 5px;
    .change{
      color: $font;
    }
    .successful{
      color: $green;
    }
  }
}