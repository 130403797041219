@import "src/variables";

.NotificationsPage {
  background-color: #fff;
  
  .changeDesignLabel {
    padding: 0.5em;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    display: inline-flex;
    position: relative;
  }

  .changeDesignLabel h2 {
    font-size: 1em;
    vertical-align: middle;
    color: #fff !important;
  }

  .changeDesignLabel span.bp3-icon svg {
    font-size: 2em;
    fill: #fff;
  }

  .NotBox {
    max-width: 1200px;
    min-height: 14.5rem;
    margin: 0px auto;
    border: 1px solid rgb(169, 169, 169);
  }

  .Header1 {
    font-family: $gilroy-extra-bold;
    font-weight: 600;
  }

  .Line1 {
    justify-content: space-between;
  }

  .NotDate {
    font-family: $gilroy-light;
    font-size: 0.7rem;
    font-weight: 600;
    color: #464646;
    // padding: 10px 10px;
  }

  .NotDate2 {
    font-family: $gilroy-light;
    font-size: 0.7rem;
    font-weight: 600;
    color: #464646;
    //padding: 10px 10px;
  }

  /********************  MEDIA QUERY ********************/

  @media (max-width: 1459px) {
    padding-top: 3.5rem;
  }

  @media (max-width: 1305px) {
    padding-top: 4rem;
  }
}
