@import "src/variables";


.product-modal-img {
  object-fit: contain;
  max-height: 90vh;
}

.zoom-in {
  cursor: zoom-in;
}

.zoom-out {
  cursor: zoom-out;
}

.product-detail-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .toRightTop {
    text-align: right;
    margin-right: 10px;
  }

  .dateAndType {
    line-height: 8px;
  }

  .interests {
    padding-left: 0;
  }
}

.product-detail-section {
  display: flex;
  flex-wrap: wrap;
  max-width: 93vw;
}

.buttons {
  display: flex;
  gap: 20px;
  margin-top: 0;
  margin-bottom: 20px;
  justify-content: center;



  &--desktop {
    gap: 40px;
  }

  &--first {
    width: 193px;
    padding: 0;
    white-space: nowrap;
  }

  &--second {
    width: 150px;
  }

  &__border {
    border-radius: 5px;
  }

  @media ($md) {
    margin-top: 15px;
    margin-bottom: 0;
    justify-content: start;
  }
}

@media (min-width: 768px) {

  .product-detail-section {
    max-width: 100%;
  }
}
