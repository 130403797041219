@import 'src/variables';

.header {
  position: sticky;
  top: 0;
  display: grid;
  grid-template-columns: auto 1fr auto;
  width: 100%;
  z-index: 20;
  padding: 1.25rem 1.4rem;
  @media screen and (min-width: 1024px) {
    padding: 1.25rem 5.4rem;
  }
}

.header__mobile {
  position: sticky;
  top: 0;
  display: grid;
  grid-template-columns: auto 1fr auto;
  width: 100%;
  z-index: 20;

  &-info-search {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem 0.6rem;
  }
}

@import 'components/info/Info', 'components/panel/Panel',
  'components/search/Search';
