@import 'src/variables';
.dropzone {
  white-space: normal;
  cursor: pointer;
  padding: 1rem;
  max-width: 10rem;
  max-height: 157px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  color: rgba($color: #464646, $alpha: 1);
  border: 3px #a3a3a3;
  border-style: dashed;
  font-family: $gilroy;
  font-size: 14px;
  font-weight: 10;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
  margin-left: 1rem;
  .bolder {
    font-family: $gilroy-extra-bold;
  }
  .purple {
    color: #8051da;
  }
  .mr-0{
    margin-right: 0;
  }
  .biggerText {
    font-size: 18px;
  }
  .center{
    margin:auto;
  }
}
.innerMargin {
  .bp3-label {
    margin-left: 1rem;
  }
}
