@import 'src/variables';

.security-advice {
  display: flex;
  padding: 1rem 0 0 0;
  background-image: url('https://storage.googleapis.com/locambio-admin/pages/security/security.png');
  background-repeat: no-repeat;
  background-size: inherit;
  background-attachment: fixed;
  background-position: -11rem 8rem;
  
  &__container {
    width: 75%;
  }

  &__links {
    font-family: $gilroy-medium;
    height: fit-content;
    margin: 1.5rem 0 0 1rem;
    padding-bottom: 2rem;
  }

  &__list {
    list-style-type: disc;
    font-size: 1rem;
    font-family: $gilroy-medium;
    padding-bottom: 1rem;
    & li {
      padding: 0.5rem 0;
    }
  }
} 

@media screen and (max-width: 768px) {
  .security-advice {
    background-position: -10rem bottom;
    background-size: 150%;

    &__container {
      width: 95%;
    }

    &__links {
      display: none;
    }
  }
}

@media screen and (max-width: 576px) {
  .security-advice {
    &__container {
      width: 100%;
    }
  }
}
