@import 'src/variables', 'src/pages/chat/variables';

.panel {
  display: grid;
  grid-template-rows: auto 1fr auto;
  max-height: 100%;
  overflow: hidden;
  background: white;

  &__body {
    background-color: white;
    padding: $gap calc(#{$gap} * 2) 0;
    overflow-y: auto;
  }

  &__controls {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: $gap;
    gap: $gap;
    z-index: 999999;
    padding: calc(#{$gap} * 1.5) $gap;
    background-color: $bg;
  }

  &__input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
  }

  &__input {
    width: 100%;
    height: 100%;
    border-radius: $chat-base-radius;
    font-family: $gilroy-medium;
    font-size: 1.25em;
    padding: .75em 4.5em .75em 1em;
    box-shadow: $shadow;
  }

  &__icon-wrapper {
    position: absolute;
    display: flex;
    right: 0;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: $gap;

    & svg {
      width: 2em;
      height: 2em;
    }
  }

  @media ($sm) {
    background: unset;
    padding: $gap;
    gap: $gap;
    grid-gap: $gap;

    &__body {
      box-shadow: $shadow;
      border-radius: $chat-base-radius;
    }

    &__controls {
      padding: unset;
      background-color: unset;
    }
  }

  @media ($xl) {
    padding: $gap $gap $gap calc(#{$gap} / 2);
  }
}