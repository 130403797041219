@import 'src/variables';

.link_to_modal {
  color: $accent !important;
  text-align: center;
  cursor: pointer;

  *, *:after, *:before {
    color: $accent;
    transition: all 150ms ease-in-out;
    -webkit-tap-highlight-color: transparent;
}
}
