@import 'src/variables';

.price-tag { 
  display: flex;
  justify-content: flex-end;
  align-items: center;
  p{
    color: white;
    border-radius: 5px;
    background-color: $font;
    font-family: $gilroy;
  }
}
