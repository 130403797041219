// TODO: split every group of variables in files

// Colors
$accent: #8051DA;
$accent-hover: #6011CF;
$primary: #FF6D00;
$secondary:#F8B723;
$alert: #D64747;

$bg: #E8E8E8;
$bg-light: #EFEFEF;

$font: #2D2D2D;
$font-light: #464646;
$font-extra-light: #bdbdbd;

$blue: #479FD6;
$yellow: #F8B723;
$yellow-light: #F2D43C;
$green: #54D454;
$gray: #747474;
$light-gray: #A3A3A3;
$dark-purple: #503289;

$shadow-color: #00000029;
$bg-body: #E8E8E8;

$delete: #D64747;

// Fonts
$defaults: Helvetica, Arial, serif;
$gilroy-heavy: 'gilroy-heavy', $defaults;
$gilroy-extra-bold: 'gilroy-extra-bold', $defaults;
$gilroy-bold: 'gilroy-bold', $defaults;
$gilroy-medium: 'gilroy-medium', $defaults;
$gilroy: 'gilroy', $defaults;
$gilroy-light: 'gilroy-light', $defaults;

// Shadows
$shadow: 0 0 10px $shadow-color;

// Radius
$base-radius: .25rem;
$popover-radius: 0.375rem;

// Header
$header-gap: 0;

// Breakpoints
// TODO: Use tailwind screens
$sm: 'min-width: 576px';
$md: 'min-width: 768px';
$lg: 'min-width: 1025px';
$xl: 'min-width: 1200px';

// Gaps
$interest-gap: 0.625rem;

//Premium checkmark
$checkmark-premium: brightness(0) saturate(100%) invert(88%) sepia(17%) saturate(5907%) hue-rotate(341deg) brightness(102%) contrast(94%);
