@import 'src/variables';

.lo-cambio-creditos {
  display: flex;
  padding: 1rem 0 0 0;
  font-family: $gilroy-medium;

  .center{
    text-align: center;
  }

  .left{
    text-align: left;
  }

  .orange {
    color: $primary;
  }

  .purple{
    color: $accent;
  }

  .yellow{
    color: $yellow-light;
  }

  .extra-bold{
    font-family: $gilroy-extra-bold;
  }

  .bold{
    font-family: $gilroy-bold;
  }

  .description-text {
    color: $font;
    font-size: 1.5em;
    margin: 0 0 0 5%;
  }

  .section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 75%;
    padding: 0 3% 0 3%;
  }

  &__slider-container {
    width: 100%;
  }

  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    margin: 2rem 0 2rem 3%;
    padding: 0 2rem 0 2rem;
    border-radius: 10px;
    &--top {
      display: flex;
      flex-direction: row;
      margin-bottom: 1rem;
      &--left {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 40%;
        padding-top: 3%;
      }
      &--right {
        width: 60%;
        &--box {
          width: auto;
          background: linear-gradient(41deg, rgba(85,140,215,1) 25%, rgba(127,82,218,1) 100%, rgba(0,212,255,1) 100%);
          margin: 10% 0 0 2rem;
          border-radius: 10px;
          padding: 1.7rem 3.5rem 0.5rem 1rem;
          font-size: 1.5em;

          li {
            list-style-type: disc;
            color: white;
            margin-bottom: 3%;
          }
        
          ul p {
            color: white;
            margin-bottom: 4%;
          }
        
          ul li::marker {
            font-size: 1.4em;
          }
        
          ol, ul {
            padding-left: 5%;
            margin-bottom: 5%;
          }
        }
      }
    }
    &--mid {
      display: flex;
      flex-direction: column;
      align-items: center;
      &--bottom-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 1.5rem 0 1.5rem 0;
        padding: 0 3% 0 3%;
        font-size: 1.5em;
        p {
          text-align: center;
        }
        @media screen and (max-width: 1024px) {
          padding: 0 10% 0 10%;
        }
        @media screen and (max-width: 1024px) {
          padding: 0 1% 0 1%;
        }
        @media screen and (max-width: 576px) {
          font-size: 0.8em;
          padding: 0;
        }
      }
    }
  }

  &__main-title {
    &--line-1{
      font-size: 2.7rem;
    }
    &--line-2{
      font-size: 2.8rem;
    }
    &--line-3{
      font-size: 1.9rem;
    }
    text-align: center;
    margin-bottom: 5%;
  }
  
  &__links {
    margin: 1.5rem 0 0 1rem;
  }

  &__logo {
    width: 50%;
    margin:  1.5rem 0 1.5rem;
  }
}


@media screen and (max-width: 1420px) {
  .lo-cambio-creditos {

    &__box, h2, h3, li, .description-text {
      font-size: 1rem;
    }

  }
  
}

@media screen and (max-width: 915px) {
  .lo-cambio-creditos {

    &__container {
      width: 100%;
    }

    &__main-title {
      font-size: 25px;
  
      &--align-left{
        font-size: 18px;
      }
    }

    h2, h3, li {
      font-size: 1rem;
    }

    .description-text {
      font-size: 1.2em;
    }

  }
}

@media screen and (max-width: 769px) {
  .lo-cambio-creditos {
    display: flex;
    flex-direction: column;

    &__logo {
      width: 65%;
    }

    &__links {
      display: none;
    }

    &__container {
      margin: 0;
      padding: 0 2rem 0 2rem;
      &--mid{
        &--bottom-text {
          font-size: 1.2em;
        }
      }
    }

    &__main-title {
      &--line-1{
        font-size: 2.3rem;
      }
      &--line-2{
        font-size: 2.4rem;
      }
      &--line-3{
        font-size: 1.3rem;
      }
    }
    
    .section {
      width: 100%;
    }
  }
}

@media screen and (max-width: 567px) {
  .lo-cambio-creditos {
    flex-direction: column;
    margin: 0%;
    padding: 3%;

    .section {
      width: 100%;
      padding: 0%;
    }

    &__logo {
      width: 60%;
    }

    &__slider-container {
      margin: 0;
      width: 100%;
    }

    &__container {
      padding: 5%;
      margin: 0 0 5% 0;
      &--top {
        flex-direction: column;
        margin-bottom: 0;
        &--left {
          width: 100%;
          align-items: center;
        }
        &--right {
          width: 100%;
          margin-bottom: 5%;
          &--box {
            width: 100%;      
            margin: 0 0 1rem 0;
            font-size: 1.5em;
          }
        }
      }
    }

    &__main-title {
      font-size: 30px;
  
      &--align-left{
        font-size: 28px;
        text-align: center;
      }
    }

    &__links {
      display: none;
    }

    li, .description-text {
      font-size: 1.4rem;
    }

    .description-text {
      margin-bottom: 10%;
    }

    ul li::marker {
      font-size: 1.4rem;
    }

  }
}