@import 'src/variables';

.info {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-template-rows: repeat(2, auto);
  font-family: $gilroy-medium;
  font-size: 1rem;
  color: $font;

  &__logo {
    display: flex;
    justify-content: center;
    grid-column: 1 / -1;
    grid-row: 1 / 2;
    margin-top: -.75rem;
    margin-bottom: .75rem;

    &-mobile {
      width: 119px;
      height: 30px;
    }

    &-desktop {
      width: 178px;
      height: 46px;
    }
  }

  &__item {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  &__label {
    line-height: 1;
  }

  &__icon {
    margin-right: .375rem;
  }

  &__actions {
    display: flex;
    justify-content: space-evenly;
    grid-column: 1 / -1;
    grid-row: 2 / 3;
    grid-gap: 1em;
    gap: 1em;
  }
}

.panel-stack__container {
  width: 22rem;
  height: 500px;
  border-radius: 6px;
}
