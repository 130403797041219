@import "src/variables";
.user-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em 1em 0 1em;
  gap: 0.8em;
  min-width: 375px;

  &__spinner{
    height: 50vh; 
  }

  &__credit {
    width: 100%;
  };

  .campaigns {
    margin-top: 2em;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1em 1em 0 1em;
  }

  &__banner {
    padding: 0 1em 0 1em;
  }

  &__tabs{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 1em 0 1em;
  }
}

.user-profile-loading {
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    transition: opacity 0.3s ease-in;
  }
  
  .spinner {
    max-width: 100%;
    max-height: 100%;
    width: 60px;
    height: 60px;
  }
}

.user-profile > * {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 375px;
  max-width: 1240px;
  width: 100%;
  gap: 0.8em;
}

@media ($md) {
  .user-profile {
    &__info {
      flex-direction: row;
      flex-wrap: wrap;
    }

    &__credit {
      width: 100%;
      height: 220px;
    }
  }
}

@media ($lg) {
  .user-profile {
    gap: 1.2em;
    
    &__info {
      justify-content: space-between;
      padding: 0;
      flex-wrap: nowrap;
    }

    &__tabs{
      padding: 0;
    }

    &__banner {
      padding: 0;
    }

    .campaigns {
      margin: 0;
    }

    &__credit {
      width: 35%;
      height: 220px;
      padding: 1.5em;
      .credit__footer {
        margin-top: 1em;
      }
    }
  }
}


