.PublicationItem {
  h1 {
    font-size: 1em;
  }

  h1 a {
    font-size: inherit;
    color: #8051DA !important;
  }

  .buttonsContainer {
    margin-left: -0.5rem !important;
    display: flex;
    justify-content: flex-start;
  }
}
