.DangerDialog {
  padding: 0;

  .bp3-dialog-header .bp3-icon svg {
    fill: #c23030;
  }

  .bp3-callout .bp3-heading {
    margin-bottom: 0;
  }

  .bp3-dialog-header .bp3-icon.bp3-icon-small-cross svg {
    fill: #5c7080;
  }
}
