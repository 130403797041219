@import "src/variables";

.user-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 5px;
  width: 100%;
  overflow: hidden;
  .text-xs{
    padding: 14px;
    border-radius: 50%;
    margin: 0;
    background-color: rgb(236, 236, 236);
    font-family: $gilroy-extra-bold;
    color: $accent;
  }

  .h-12 {
    height: 40px;
  }

  .w-12 {
    width: 40px;
  }

  .AvatarImage{
    @media screen and (min-width: 1px) {
      width: 40px;
      height: 40px;
    }
  }

  .avatar {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .user {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 6px;
  font-family: $gilroy-extra-bold;
  font-size: 15px;

    .name{
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 14px;
      max-width: 10.55em;

      a {
        color: $font;
        text-decoration: none;
      }
    }

    .ranking{
      display: flex;
      flex-direction: row;
      &--text {
        margin-left: 0.2em;
      }
    }
  }
  .verified{
    max-height: 17px;
    width: auto;
    font-size: 15px !important;
    &__name{
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
