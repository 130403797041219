@import 'src/variables';

.chat-interested-publications-item {
  padding: 0 5px 0 5px;
  position: relative;

  &__layer{
    position: absolute;
    background-color: rgb(0, 0, 0);
    width: 98%;
    height: 100%;
    left: 3;
    top: 0;
    opacity: 0.5; 
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p{
      color: rgb(255, 255, 255);
      opacity: 1; 
    }
  }

  &__card {
    padding: 0;
    margin: 0.1em 0 0.4em 0;
    box-shadow: 0 1px 7px -1px #00000045;
    -webkit-box-shadow: 0 1px 7px -1px #00000045;
    -moz-box-shadow: 0 1px 7px -1px #00000045;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &--left {
      display: flex;
      align-items: center;
      width: min-content;

      &-img {
        margin: 0.3em;

        .small {
          &__picture {
            width: 50px;
            height: 50px;

            &--background {
              width: 50px;
              height: 50px;
            }

            &--img_box img {
              width: 50px;
              height: 50px;
            }
          }
        }
      }

    }

    &--center {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      text-align: left;
      width: 100%;

      &-title {
        margin: 0.5em 0 0 0.5em;
        font-size: 12px;
        font-family: $gilroy-medium;
        align-self: flex-start;
      }

    }

    &--right {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: max-content;
      font-size: 14px;
      padding: 0 1em 0 ;

      &__price_tag { 
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: max-content;
        p{
          color: white;
          padding: 3px 5px 1px 5px;
          border-radius: 5px;
          background-color: $font;
          font-family: $gilroy;
        }
      }
      
    }
  }
}



.custom-checkbox .bp3-control-indicator {
  background-color: white !important;
  border: 1px solid $accent;
  border-radius: 25% !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}