@import 'src/variables';

.panel-stack {
  min-width: 100%;
  min-height: 100%;
  font-size: 1rem;
  font-family: $gilroy-medium;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 9px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #A3A3A3 0% 0% no-repeat padding-box;
  }
  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background: #D6D6D6 0% 0% no-repeat padding-box;
  }
  .bp3-panel-stack-view {
    position: inherit;
    top: inherit;
    bottom: inherit;
    left: inherit;
    right: inherit;
  }

  .bp3-panel-stack-header {
    padding: 0.5rem 0;
  }
  
  .bp3-panel-stack-header,
  .bp3-heading,
  .bp3-text-overflow-ellipsis {
    display: flex;
    align-items: center;
    box-shadow: none;
    width: 100%;
    font-size: 14px;
    font-family: $gilroy-medium;
    color: $accent;
    margin: 0;
    height: fit-content;
  }

  .bp3-button.bp3-panel-stack-header-back {
    margin-left: 20px;
  }

  .bp3-panel-stack-header-back {
    p {
      display: none;
    }
  }

  .bp3-popover,
  .bp3-popover-content {
    border-radius: 10px;
  }

  &__popover .bp3-popover2-arrow-fill {
    fill: $bg;
  }

  &__item {
    display: flex;
    padding: 0.5rem 0.8rem 0.5rem 1.5rem;
    align-items: center;
    justify-content: space-between;
    color: $accent !important;
    cursor: pointer;

    &:hover {
      color: $primary !important;
    }

    &:focus {
      outline: unset;
    }
  }

  &__father {
    display: flex;

    &-item {
      color: rgb(150, 150, 150);
      font-size: 12px;
      padding-right: 0.2rem;
      & svg {
        color: $accent;
      }
    }
  }

  .has__father {
    align-self: flex-end;
    margin-bottom: 0.1rem;
  }

  & svg {
    &:hover {
      color: $primary !important;
    }
  }

  &__icon {
    color: $accent !important;
  }
}

.panel-stack__header {
  margin-top: 3rem;
}

@import 'components/searchCategory/SearchCategory';
