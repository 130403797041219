.horizontalThumbanil{

  .InfoProductContainer{
    .publicationName h1 a{
      font-size: 0.75em;
      color: #8051DA;
      margin-top: 0;
    }

    .info-publication{
      max-width: 100%;
    }

    .infoDetailPublication{
      width: 100%;
    }

    .infoDetailPublication p{
      margin-bottom: 0 !important;
    }
    .infoDetailPublication h4{
      font-size: 1em;
    }
  }

  .positionNewTag{
    position: absolute;
    z-index: 10;
    top: 0.5em;
    left: 1.5em;
  }

  .interestsForChange h4{
    margin: 0;
  }

}
