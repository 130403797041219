.wrapperImageZoom {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .imageZoom {
    z-index: 20;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80% !important;
    height: 60%  !important;
    box-shadow: 0 10px 10px #a7a7a7;

    img {
      transition: initial;
      width: auto;
      height: auto;
      pointer-events: none;
      transform: scale(3);
    }
  }
}