@import 'src/variables';

.change-proposal-publications {
  width: 100%;
  padding: 0 0.5em 0 0.85em;

  &::-webkit-scrollbar {
    width: 7px;
    background-color: white;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $font-extra-light;
    border-radius: 3px;
    height: 10px !important;
  }

  overflow-x: hidden;
  overflow-y: scroll;


  @media ($sm) {
    padding: 0 1.05em 0 1.45em;
  }
}