@import 'src/variables';

.spinner {
  position: relative;
  margin-top: 50%;
  width: 100%;

  .bp3-spinner-head {
    stroke: $primary !important;
  }
}