@import 'src/variables';

.credit {
  width: 100%;
  font-family: $gilroy-medium;

  &__title {
    font-family: $gilroy-extra-bold;
    font-size: 1.125rem;
    margin-bottom: .75rem;
  }

  &__subtitle-wrapper {
    font-size: 1rem;
    color: $accent;
    display: flex;
    align-items: center;
    width: fit-content;
    margin-bottom: .75rem;

    &:hover {
      color: $accent;
    }
  }

  &__subtitle {
    display: inline;
    text-decoration: underline;
  }

  &__icon {
    margin-left: .25rem;
  }

  &__footer {
    display: flex;
    align-items: center;
    font-family: $gilroy-extra-bold;
    margin-top: 2.25em;
  }

  &__verification-logo {
    margin-right: .5rem;
  }


  @media ($sm) {
    &__title {
      font-size: 1.25rem;
    }
  }
}