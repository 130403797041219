.Footer {
  .swiper {
    max-width: 16.25vw;
  }
  overflow-x: hidden;
  ul {
    list-style: none !important;
  }

  ul li {
    float: left;
    margin-bottom: 0.5em;
  }
}

@media screen and (max-width: 800px) {
  .Footer {
    .swiper {
      max-width: 18.75rem;
    }
  }
}