@import 'src/variables';

.page-with-title {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__header{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;

    &__title {
      font-family: $gilroy-bold;
      font-size: 1.75em;
      font-weight: normal;
      text-align: center;
      padding: 1em 0.5em;
      margin: 0;
    }
  
    &__icon {
      color: $accent;
      position: absolute;
      left: 1em;
      cursor: pointer;
    }
  }
}