@import 'src/variables';
.saved-publications {
  display: flex;
  flex-direction: column;
  gap: 1em;
  max-width: 1240px;
  padding: 0 1.25rem;
  width: 100%;

  .not-publications-saveds{
    display: flex;
    justify-content: center;
    font-family: $gilroy-bold;
  }
}

.pagination {
  margin: 10px 0;
}