@import 'src/variables';
.box__container{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 224px;
  height: 213px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  border-radius: 5px;
  margin: 7px;
  .title{
    width: 100%;
    height: 12%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 5px 5px 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .promotion_months{
    width: 100%;
    height: 22%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .plans-text{
      font-family: $gilroy-bold;
      font-size: 1.2em;
      color: $bg-light;
      text-shadow: 0px 3px 6px #0000001A;
    }
  }
  .orange_gradient{
    background: transparent linear-gradient(102deg, #FF6D00 0%, #F8B723 100%) 0% 0% no-repeat padding-box;
  }
  .blue_gradient{
    background: transparent linear-gradient(102deg, #8051DA 0%, #479FD6 100%) 0% 0% no-repeat padding-box;
  }
  .plain_gradient{
    background: $accent;
  }

  .box-price{
    width: 100%;
    height: 66%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: $gilroy-extra-bold;
    color: $font-light;
    .price{
      .price-currency{
        font-size: 2.5em;
      }
      .price-text{
        font-size: 2.2em;
        &__big {
          font-size: 3.5em;
        }
      }
      .price-decimals{
        font-size: 1.5em;
      }
    }

    .total-impressions{
      font-family: $gilroy-extra-bold;
      font-size: 1em;
      .purple-text{
        color: $accent;
      }
      .blue-text{
        color: $blue;
      }
      .orange-text{
        color: $primary;
      }
    }
  }

  .go-to-credit{
    width: 100%;
    height: 20%;
    border-radius: 0 0 5px 5px;
    font-family: $gilroy-bold;
    .credit-button{
      width: 60%;
      height: 40px;
      background: #464646 0% 0% no-repeat padding-box;
      border-radius: 4px;
      color: $bg;
      .yellow-text{
        color: $yellow-light;
      } 
    }
  }
}

.basico{
  &--title{
    color: #A3A3A3;
    font-family: $gilroy-medium;
  }
  &--text{
    color: $font-light;
  }
  &--shadow{
    box-shadow: 0px 0px 15px #00000029;
  }
}

.medio{
  &--title{
    color: $accent;
    font-family: $gilroy-medium;
  }
  &--text{
    color: $accent;
  }
  &--shadow{
    box-shadow: 0px 0px 15px #479FD6CC;
  }
}

.avanzado{
  &--title{
    color: $primary;
    font-family: $gilroy-medium;
  }
  &--text{
    color: $primary;
  }
  &--shadow{
    box-shadow: 0px 0px 15px #F2D43C;
  }
}

@media screen and (max-width: 1024px) {
  .box__container{
    width: 200px;
    height: 180px;
    margin: 7px;
    .title{
      margin: 5px 0 5px 0;
    }
    .promotion_months{
      .plans-text{
        font-size: 1em;
      }
    }
    .box-price{
      .price{
        .price-currency{
          font-size: 1.2em;
        }
        .price-text{
          font-size: 1.6em;
          &__big {
            font-size: 2.8em;
          }
        }
        .price-decimals{
          font-size: 0.8em;
        }
      }
  
      .security-change-quantity{
        font-size: 0.7em;
      }

      .price-by-month {
        font-size: 1em !important;
        .price-currency {
          font-size: 0.8em;
        }
        .plans-text {
          font-size: 0.8em;
        }
      }
    }
  }
}


@media screen and (max-width: 576px) {
  .box__container{
    width: 224px;
    height: 213px;
    margin: 5px;
    .title{
      margin: 2px;
    }
    .promotion_months{
      .plans-text{
        font-size: 1.2em;
      }
    }
    .box-price{
      .price{
        .price-currency{
          font-size: 1em;
        }
        .price-text{
          font-size: 2.2em;
          &__big {
            font-size: 3em;
          }
        }
        .price-decimals{
          font-size: 1em;
        }
      }
  
      .security-change-quantity{
        font-size: 0.8em;
      }

      .price-by-month {
        font-size: 1em !important;
        .price-currency {
          font-size: 1.3em;
        }
        .plans-text {
          font-size: 1.3em;
        }
      }
    }
  }
}

