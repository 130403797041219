@import "src/variables";

.Comments {
  h5 {
    margin-top: 0;
    margin-right: 0.25em;
    margin-bottom: 0.5em;

    span.bp3-form-helper-text {
      font-family: -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Open Sans", "Helvetica Neue",
        "Icons16", sans-serif;
      font-weight: 100;
    }

    span.bp3-icon {
      margin-right: 0.5em;
    }
  }

  // FIX INPUT COMMENT
  .bp3-control-group.bp3-fill > *:not(.bp3-fixed) {
    flex: none;
    -webkit-box-flex: none;
  }

  .InputSize {
    width: 80% !important;
  }

  .InputSize input {
    width: 100% !important;
  }

  .btnSizeFix {
    width: 20% !important;
  }

  .toWriteCommentQuestionContainer {
    margin-bottom: 1em;
  }

  .CommentsContainer {
    background: rgba(255, 255, 255, 1);
  }

  .CommentsQuestionContainer {
    margin-bottom: 0.125em;
  }

  .CommentsAnswersContainer {
    margin-bottom: 0.125em;
  }

  .answer-input {
    display: grid;
    grid-template-columns: 1fr;
    @media screen and ($lg) {
      grid-template-columns: .8fr .15fr;
    }
  }

  .CommentMessage {
    position: relative;
    background: rgb(249, 249, 249);
    padding: 0.5em;
    border: 1px solid #ededed;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    padding-left: 0.5em;
    display: inline;
    font-family: $gilroy-medium !important;
    .IconGold {
      color: $yellow;
    }

    .ranking--text{
      color: black;
    }

    .userName {
      display: inline;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      display: inline;
    }
    .action {
      font-size: $gilroy-bold;
      display: inline;
      font-weight: bold;
    }
    .date-message {
      font-family: $gilroy-bold;
      position: absolute;
      right: 5px;
      top: 7px;
    }
    .userName__checkmark {
      display: none;
    }
  }

  .CommentMessage p {
    font-family: $gilroy-medium;
    padding-left: 5px;
    background: transparent;
    padding: 0;
    margin: 0;
    overflow-wrap: break-word;
  }

  .btnPreguntar .bp3-button-text {
    @media (max-width: 370px) {
      font-size: 10px;
    }
  }

  .name {
    font-family: $gilroy-medium !important;
    font-weight: lighter;
  }
}



// TOAST HEADER ACTIVITY
.headerUserCard {
  position: relative;
  display: flex;
  margin-bottom: 0.5em;

  div {
    flex-flow: row wrap;
    justify-content: space-around;
  }

  div.AvatarContainer {
    position: relative;
    width: 48px;
    height: 48px;
    background: #ebebeb;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    overflow: hidden;
    min-width: 48px;
    min-height: 48px;
    margin-right: 0.5em;
  }

  .AvatarContainer .AvatarInitials {
    position: absolute;
    width: 100%;
    height: 100%;
    font-family: $gilroy-extra-bold;
    display: flex;
    align-items: center;
    align-content: center;
    color: rgba(70, 70, 70, 1) !important;
  }

  .AvatarContainer .AvatarInitials p {
    text-align: center;
    margin-bottom: 0;
  }

  .AvatarContainer .AvatarImage img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .UserDetailsContainer {
    padding-left: 1em;
    padding-right: 1em;
    width: 100%;
    // display: inline-grid;
    // display: -moz-inline-grid;
    // display: -ms-inline-grid;
  }

  .UserDetailsContainer h4 {
    font-family: $gilroy-extra-bold;
    font-size: 1em;
    margin-top: 0.4em;
  }

  @media (max-width: 768px) {
    .UserDetailsContainer h4 {
      // visibility: hidden;
    }
  }

  .UserDetailsContainer h4 a {
    color: rgba(70, 70, 70, 1) !important;
  }

  .UserDetailsContainer p {
    font-size: 1em;
    margin-bottom: 0;
  }
}
