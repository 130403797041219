@import 'src/variables';
$gap: .75em;
$unread-size: 1.25em;

.item-list {
  &__empty-state {
    color: #747474;
    height: 116px;
    display: grid;
    grid-template-columns: 0.25fr 0.5fr;
    justify-content: center;
    align-self: center;
    &-icon {
      color: #e8e8e8;
      align-self: center;
      justify-self: center; 
    }
    &-text{
      font-size: 16px;
      font-family: $gilroy-bold;
      align-self: center;
    }
  }
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 9px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #A3A3A3 0% 0% no-repeat padding-box;
  }
  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background: #D6D6D6 0% 0% no-repeat padding-box;
  }
  @media ($sm) {
    max-height: 558px;
  }
}
