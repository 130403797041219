@import 'src/variables';

.user-menu {
  width: 22rem;
  height: fit-content;
  display: flex;
  flex-direction: column;

  &__name {
    &__checkmark{
      margin-top: 4px;
    }
  }

  &--mobile {
    width: 100%;
  }

  .blur-image {
    &__picture{
      &--background{
        filter: blur(10px); 
      }
    }
  }

  &__disabled {
    color: #a3a3a3 !important;
    pointer-events: none; 
    cursor: default; 
  }

  &__info {
    align-self: center;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 90px;

    & p {
      margin-left: 1rem;
      font-size: 20px;
      font-family: $gilroy-extra-bold;
    }
  } 

  &__avatar {
    margin-left: 0.5em;
    width: 58px;
    height: 58px;
    border-radius: 50%;
    box-shadow: 0px 2px 2px $shadow-color;
    object-fit: contain;
    background-color: white;
    overflow: hidden;
    &-initial {
      width: 50px;
      height: 50px;
      background-color: white;
      color: $accent;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
    }
  }

  &__section {
    display: flex;
    padding: 0.5rem 1.5rem;
    color: $font;
    cursor: pointer;

    :hover {
      color: $accent !important;
    }

    :nth-child(2) {
      margin-left: 1rem;
    }

    :nth-child(3) {
      color: $accent;
      margin-left: auto;
    }

  }

}
