@import 'src/variables';

.modal-image {
  position: relative;
  &--info-mobile {
    width: 250px;
    height: 298px;
  } 
  &__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    z-index: 5;
    border-radius: 5px;
    background-color: $bg-light;
    &--cross {
      color: $font;
    }
  }
}