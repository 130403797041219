@import "src/variables";

.about_container{
  display: flex;
  flex-direction: row;
  padding: 1rem 0 0 0;
  @media screen and (max-width: 1080px) {
    justify-content: center;
  }

  .section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 75%;
    padding: 0 3% 0 3%;
  }

  .links_container{
    font-family: $gilroy-medium;
    margin: 1.5rem 0 0 1rem;
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  .AboutLoCambio {
    width: 100%;
    .orangeText {
      color: #ff6d00;
    }
    .purpleText {
      color: #8051da;
    }
    .firstSection {
      width: 100%;
      margin-top:2rem;
      
    }
    .secondSection {
      padding-top: 2rem;
    }
    .title {
      text-align: left;
      font-size: 2rem;
      @media (min-width: 768px) {
        font-size: 50px;
      }
    }
    .subTitle {
      text-align: left;
      font-size: 1.9rem;
      @media (min-width: 768px) {
        font-size: 40px;
      }
    }
    .row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
    }
    .justify_row{
      justify-content: space-between;
      @media (max-width:769px) {
        justify-content: center;
       }
    }
    .cardRow {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      grid-gap:1rem;
      gap:1rem;
      margin-top: 1rem;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      padding-bottom: 1rem;
      justify-content: space-between;
      @media (max-width:769px) {
       justify-content: center;
      }
    }
    
    .valores{
      font-size: 1rem;
      line-height: 1rem;
    }
    .valores li::marker {
      color: #8051da;
      font-weight: bold;
      font-size: 1.5rem;
    }
    .orangeGradient {
      background: transparent linear-gradient(90deg, #ff6d00 0%, #f8b723 100%) 0%
        0% no-repeat padding-box;
    }
    .purpleGradient {
      background: transparent linear-gradient(90deg, #8051da 0%, #479fd6 100%) 0%
        0% no-repeat padding-box;
    }
    .blackGradient {
      background: #464646;
    }
    .card {
      background: #ffffff;
      box-shadow: 0px 10px 10px #00000029;
      border-radius: 10px;
      height: fit-content;
      max-width: 100%;
      margin-top: 1rem;
      min-width: 20rem;
    }
    .padding_card{
      padding:2rem;
    }
    .cardTitle {
      padding: 1rem;
      text-align: center;
      color: #ffffff;
      font-weight: bold;
      font-size: 24px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    .cardChild {
      padding: 2rem;
    }
    .aboutText {
      font-size: 18px;
      @media (max-width: 768px) {
        min-width: 18rem;
      }
      @media (min-width: 768px) {
        margin-right: 1.5rem;
      }
      @media (max-width: 1440px ) and (min-width: 768px){
        margin-right: 0;
        font-size: 16px;
      }
    }
    .aboutImage {
      width: 20.25rem;
      height: 19.5rem;
      margin-right: 1rem;
      @media (max-width: 768px) {
        width: 15rem;
        height: 14.375rem;
      }
    }
    .aboutTextSection {
      width: 60%;
      margin-right: 1rem;
    }
    .howFirstColumn{
      width:45%;
      min-width: 20rem;
    }
    .howSecondColumn{
      width:50%;
      min-width: 20rem;
    }
    .howText {
      min-width: 15rem;
      font-size: 18px;
      width: 85%;
      text-align: justify;
      margin-top: 1rem;
      margin-bottom: 1rem;
      margin-left: auto;
      margin-right: auto;
      @media (min-width: 768px) {
        margin-right: 1.5rem;
        margin-left: 0;
      }
    }
    .sectionTitle {
      text-align: left;
      font-size: 1.75rem;
      @media (min-width: 768px) {
        font-size: 30px;
      }
    }
    .sectionNumeration {
      text-align: left;
      font-size: 1.6rem;
      @media (min-width: 768px) {
        font-size: 25px;
      }
    }
    .column{
      display: flex;
      flex-direction: column;
      width: 90%;
      justify-content: space-evenly;
      margin-bottom: 1rem;
    }
    .searchImage{
      width:50%;
      height: 3rem;
      margin-bottom: 1.5rem;
      min-width: 15rem;
    }
    .searchInfo{
      width: 70%;
      font-size: 18px;
      margin-bottom: 1rem;
      min-width: 15rem;
      @media (min-width: 768px) {
        width: 40%;
      }
    }
    .imageRow{
      width: 100%;
      justify-content: center;
      align-items: center;
      @media (min-width: 768px) {
        justify-content: space-between;
      }
    }
    .wallInfo{
      width: 70%;
      font-size: 18px;
      margin-bottom: 1rem;
      min-width: 15rem;
      padding-top: 1rem;
      @media (min-width: 768px) {
        width: 55%;
      }
    }
    .wallImage{
      width:10rem;
      height: 3rem;
      margin-bottom: 1.5rem;
    }
    .wallInfoAlone{
      width: 100%;
      font-size: 18px;
      margin-bottom: 1rem;
      min-width: 15rem;
    }
    .publicationList li::marker {
      color: #8051da;
      font-weight: bold;
      font-size: 1.5rem;
    }

    .video-react .video-react-big-play-button {
      width: 80px;
      height: 80px;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      -webkit-transform: translateX(-50%) translateY(-50%);
      -moz-transform: translateX(-50%) translateY(-50%);
      padding: 0;
      border-radius: 80px;
      -webkit-border-radius: 80px;
      -moz-border-radius: 80px;
      border: 0;
      background-color: rgba(80, 80, 80, 0.9);
    }

    .video-react .video-react-poster {
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
    }

    .video-react .video-react-video {
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
    }

    .video-react .video-react-big-play-button:before {
      color: #ffffff;
      font-size: 2em;
      padding-top: 15px;
    }

    ul {
      list-style: disc;
      padding-left: 2em;
  }
}
}