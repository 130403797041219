@import "src/variables";

.verified_check_mark {
  margin-left: 7px;
  &__Premium {
    .star{fill: $yellow}
    .check{fill: black}
  }

  &__Standard {
    .star{fill: $blue}
    .check{fill: white}
  }
}
