@import 'src/variables', 'src/pages/chat/variables';

.list-button {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-family: $gilroy-extra-bold;
  font-size: 1.25em;
  padding: .75em;
  border-radius: $chat-base-radius;
  cursor: pointer;

  &--active, &:hover {
    color: $accent;
    background-color: transparent;
  }

  &--active svg {
    transform: rotate(180deg);
  }

  &:hover {
    box-shadow: 0 0 0 1px $accent inset;
  }

  &--active:hover {
    box-shadow: none;
  }

  & svg {
    width: 1em;
    height: 1em;
    transition: transform 150ms ease-in-out;
  }

  @media ($sm) {
    font-size: 1.75em;
  }
}