@import 'src/variables';
.change-proposal-publication {
  width: 100%;
  &__info{
    margin-bottom: 0.6em;
    display: flex;
    flex-direction: row;
    padding: 0.5em;
    
    &--main-img{
      display: flex;
      align-items: flex-start;
      width: 100px;
      min-width: 85px;
      
      &-image-container{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80px;
        height: 80px;
      }
    }
    &--main-info{
      width: 100%;
      display: grid;
      grid-template-rows: min-content;
      &-title { 
        width: 100%;
        grid-area: 1 / 1 / 2 / 9; 
        font-family: $gilroy-bold;
        color: $accent;
        font-size: 17px;
        display: grid;
        align-items: start;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1;
      }
      &-value { 
        grid-area: 1 / 9 / 2 / 10; 
        display: flex;
        justify-content: flex-end;
        align-items: center;
        p{
          color: white;
          padding: 3px 5px 1px 5px;
          border-radius: 5px;
          background-color: $font;
          font-family: $gilroy;
        }
      }
      &-location-info { 
        grid-area: 2 / 1 / 3 / 10; 
        display: grid;
        align-items: end;
        .change_title{
          font-weight: 600;
          font-size: 14px;
        }

      }
      &-location { 
        font-size: 13px;
        grid-area: 4 / 1 / 3 / 10; 
        width: 100%;
        overflow: hidden;
        &__link-to-map{
          color: $accent;
          cursor: pointer;
        }
        &-reference_point{
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  @media (max-width: 576px) {
    font-size: 12px;
    &__info{
      &--main-info{
        &-title { 
          font-size: 12px ;
        }
        &-location-info { 
          p {
            .change_title{
              font-size: 12px;
            }
          }
        }
        &-location { 
          font-size: 12px;
        }
      }
    }
  }
}
