@import 'src/variables';

.credit-payment-success-modal {
  max-width: 340px;
  color: $font;
  font-size: 16px;
  line-height: 1.125;
  margin: auto;
  padding: .5em;


  &__icon {
    width: 50px;
    height: 50px;
    margin: auto;
  }

  &__link {
    color: $accent;
  }

  &__title {
    font-family: $gilroy-extra-bold;
    margin: 20px 0 15px 0;
  }

  @media ($sm) {
    font-size: 20px;
    padding: 0;

    &__title {
      margin: 30px 0 15px 0;
    }
  }
}
