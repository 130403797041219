@import 'src/variables';
.step3 {
  .suggestions-bar{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom:1%;
    .suggestions-input{
      width: 96%;
    }
    .suggestions-button{
      position: relative;
      width: 4%;
      display: flex;
      justify-content: flex-end;

      .button {
        min-height: 40px;
        min-width: 40px;
        background-color: $accent;
        border-radius: 5px;
        background-image: none;
        .white-text{
          color: white;
        }
      }
    }
  }

  .map{
    width: 95%;
    background-color: blanchedalmond;
    margin: auto;
    height: 25rem;
  }
  .interest{
    width: 100%;
    margin-right: 1rem;
    height: auto;
    background-color: $bg;
    h4{
      font-size: 20px;
    }
    @media (max-width:769px) {
      height: auto;
      margin-right: 1rem;
      margin-top: 0.2rem;
    }
  }
  .no-interests{
    line-height: 0.9rem;
    .bp3-icon{
      margin-right: 0.5rem;
    }
  }
  .bp3-form-group{
    width: 45%;
    @media (max-width:769px) {
      width: 95%;
    }
  }
  .disabled{
    opacity: 0.75;
  }
  .row{
    justify-content: flex-start;
    margin: 1.5rem 0;
    @media (max-width:769px) {
      margin: 1rem 0;

    }
  }

  .modal-interaction{
    cursor: pointer;
    font-size: 0.8em;
  }

  .interestItem{
    margin:0 0.5rem 1rem;
    background-color: #FFFFFF;
    width: fit-content;
    font-family: $gilroy-medium;
    border-radius: 5px;
    padding: 0.5rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: $accent;
    .interest_icon{
      margin-left:1rem;
    }
    p{
      margin-right: 0.3rem;
    }
    @media (max-width:769px) {
      position: relative;
      margin: 1rem 0.5rem 0 0;
      width: 90%;
      .interest_icon{
        position: absolute;
        top: 0.52rem;
        right: 4px;
      }
    }
    
  }

  .bp3-input-group .bp3-input{
    color: $font-light;
    font-family: $gilroy-medium;;
    border:solid 1px #707070;
    padding: 1.2rem 0.5rem;
    border-radius: 5px;
  }
  input::placeholder{
    color: $font-light;
  }
}

.bp3-callout{
  text-align: left;
  padding: 5px 10px;
  @media (max-width:576px) {
    max-width: 320px;
    padding: 0.5rem;
  }
}

.bp3-popover-arrow-fill{
  display: none;
}

.bp3-popover-arrow-border{
  display: none;
}

.bp3-popover-arrow {
  display: none;
}

.suggestion-pop {
  padding: 10px;
  cursor: pointer;
}

.error {
  margin-top: 20px;
}

.bp3-transition-container .bp3-popover-appear-done .bp3-popover-enter-done {
  will-change: transform;
  top: 0px; 
  left: 0px; 
  transform: translate3d(40px, 347px, 0px);
}

.info-box-title{
  line-height: 0.5em;
  margin-bottom: 0.7rem !important;
}

.subtitle{
  margin-bottom: 0.5rem !important;
  font-family: $gilroy-medium;
}

.bold-text {
  font-family: $gilroy-bold;
}

.extra-bold-text {
  font-family: $gilroy-extra-bold;
}

.alert {
  color: $alert;
}

.yellow-text {
  color: $secondary;
}

.purple-text{
  color: $accent;
}

.orange-text{
  color: $primary;
}

.blue-text{
  color: $blue;
}

.suggestion-title {
  cursor: pointer;
}

.suggestion-item {
  margin: 5px 0 5px 0;
}

.info-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  &--title{
    
  }
  p {
    margin: 0 !important;
  }
}



