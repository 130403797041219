@import 'src/variables';

.terms-and-conditions {
  display: flex;
  padding: 1rem 0 0 0;
  font-family: $gilroy-medium;

  p {
    text-align: justify;
    text-justify: inter-word;
    margin-bottom: 1%;
  }

  .bold {
    font-family: $gilroy-bold;
  }

  .title {
    color: $accent;
    margin-bottom: 2%;
    align-self: center;
  }

  .section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 75%;
    padding-top: 1%;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 2% 1% 5%;
    &--box { 
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      background-color: white;
      border-radius: 10px;
      padding: 3%;
    }
  }

  &__links {
    margin: 1.5rem 0 0 1rem;
  }
}

@media screen and (max-width: 768px) {
  .terms-and-conditions{

    p {
      margin-top: 2%;
    }

    h2 {
      font-size: 1rem;
      text-align: center;
    }

    .section {
      justify-content: flex-start;
      width: 100%;
      padding-top: 1%;
    }

    &__container {
      width: 100%;
      padding: 0 2% 2% 2%;
    }

    &__links {
      display: none;
    }
  }
}