@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/core/lib/scss/variables.scss";
@import "~@blueprintjs/popover2/lib/css/blueprint-popover2.css";
@import "~@blueprintjs/popover2/lib/css/context-menu2.css";
@import "./AppMediaQueries.scss";
@import "./css/tailwind.css";
@import "fonts";
@import "src/variables";



/* STYLING ATRIBUTES */
*,
*:after,
*:before {
  -moz-transition: all 150ms ease-in-out;
  -webkit-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
  -webkit-tap-highlight-color: transparent;
}

html {
  overflow-x: hidden;
}

html,
body {
  color: rgba(70, 70, 70, 1) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  background: $bg;
  min-height: 100vh;
}

.textAlignLeft {
  text-align: left;
}

.textAlignCenter {
  text-align: center;
}

.textAlignRight {
  text-align: right;
}

.margin0 {
  margin: 0;
}

.margin-05em {
  margin: 1em;
}

.margin-1em {
  margin: 1em;
}

.marginTop-1em {
  margin-top: 1em;
}

.marginRight-1em {
  margin-right: 1em;
}

.marginBottom-1em {
  margin-bottom: 1em;
}

.marginLeft-1em {
  margin-left: 1em;
}

.marginTop-05em {
  margin-top: 0.5em;
}

.marginRight-05em {
  margin-right: 0.5em;
}

.marginBottom-05em {
  margin-bottom: 0.5em;
}

.marginLeft-05em {
  margin-left: 0.5em;
}

.padding0 {
  padding: 0;
}

.paddingBottom-0 {
  padding-bottom: 0;
}

.padding-05em {
  padding: 0.5em;
}

.padding-1em {
  padding: 1em;
}

.paddingTop-1em {
  padding-top: 1em;
}

.paddingLeft-05em {
  padding-left: 0.5em;
}

.paddingLeft-1em {
  padding-left: 1em;
}

.noBorderRadius {
  border-radius: 0 !important;
}

/* MATERIAL DESIGN */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $gilroy-extra-bold;
  color: rgba(70, 70, 70, 1);
  line-height: 1em;
}

a {
  text-decoration: none !important;
}

a:hover {
  text-decoration: none;
}

.bp3-popover2 {
  box-shadow: 0 0 10px #00000029;

  & .bp3-popover2-content {
    border-radius: $popover-radius;
  }
}

.bp3-popover-enter-done {
  z-index: 10099 !important;
}

.bp3-overlay {
  z-index: 5000;
}

/* TOOLTIP */
.bp3-tooltip .bp3-popover-content {
  background-image: linear-gradient(
    to bottom,
    #ffffff,
    #fbfbfb,
    #f8f8f8,
    #f4f4f4,
    #f1f1f1
  );
  color: rgba(70, 70, 70, 1);
  font-family: $gilroy-extra-bold;
}

.bp3-tooltip .bp3-popover-content p {
  color: rgba(70, 70, 70, 1);
  font-family: $gilroy-extra-bold;
}

.bp3-tooltip .bp3-popover-arrow-border {
  fill: #ffffff !important;
  z-index: 100001 !important;
}

.bp3-tooltip .bp3-popover-arrow-fill {
  fill: #ffffff !important;
  z-index: 100001 !important;
}

/* --------- */

.bp3-dialog {
  padding-bottom: 0;
  padding: 0 !important;
}

/* --------- */

.bg-yellow {
  background: #e2e6e9;
}

.tag {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 0 0 1px rgba(70, 70, 70, 1) inset;
  color: rgba(70, 70, 70, 1);
  font-family: $gilroy-extra-bold;
}

.bp3-tag {
  font-family: $gilroy-extra-bold !important;
}

.tag.bp3-tag.bp3-minimal {
  background: rgba(255, 255, 255, 1) !important;
  font-family: $gilroy-extra-bold;
}

.tagPremium {
  background: rgba(255, 187, 5, 1)
    linear-gradient(-45deg, #f0b719 0%, #e9c85b 50%, #f7f464 100%);
  color: rgba(70, 70, 70, 1);
  font-family: $gilroy-extra-bold;
}

.tagYellow {
  background: rgba(255, 187, 5, 1) !important;
  color: rgba(70, 70, 70, 1);
  font-family: $gilroy-extra-bold;
  margin-bottom: 0.5em;
  margin-right: 0.5em;
}

.tagViolet {
  background: rgba(245, 245, 245, 255) !important;
  color: rgba(128, 81, 218, 255);
  font-family: $gilroy-bold;
  margin-bottom: 0.5em;
  margin-right: 0.5em;
}

.tagNew {
  background: #c23030 !important;
  color: rgba(255, 255, 255, 1);
  font-family: $gilroy-extra-bold;
  margin-right: 0.5em;
}

.tagChecked {
  background: rgba(255, 187, 5, 1) !important;
  color: rgba(70, 70, 70, 1);
  font-family: $gilroy-extra-bold;
}

.bp3-tag.bp3-interactive:hover {
  background: #464646;
  color: #fff;
}

/* DARK SPINNER */
.bp3-spinner .bp3-spinner-track {
  color: rgba(70, 70, 70, 0.25) !important;
}

.bp3-spinner .bp3-spinner-head {
  stroke: rgba(70, 70, 70, 0.8) !important;
}

/* LIGHT SPINNER */
.whiteSpinner .bp3-spinner .bp3-spinner-track {
  color: rgba(255, 255, 255, 0.25) !important;
}

.whiteSpinner .bp3-spinner .bp3-spinner-head {
  stroke: rgba(255, 255, 255, 0.8) !important;
}

.calloutNoActivity p {
  color: rgba(70, 70, 70, 0.8) !important;
  padding: 1em 1em 1em 0;
}

.whiteSpace {
  height: 80px;
}

.justifyText {
  text-align: justify;
}

/* EMOJIS */
.emoji {
  /* font-size: 2.5em; */
  vertical-align: middle;
  line-height: 1em;
}

/* BACKGROUND ANIMATED */
.bg-animation {
  color: rgba(255, 255, 255, 1);
  background: #ff7815
    linear-gradient(-45deg, #ff7815, #ffbb05, #ff7815, #ffbb05);
  background-size: 600% 600%;
  -webkit-animation: header-Gradient 25s ease infinite;
  -moz-animation: header-Gradient 25s ease infinite;
  animation: header-Gradient 25s ease infinite;
}

.bg-animation--blue {
  color: rgba(255, 255, 255, 1);
  background: transparent
    linear-gradient(-45deg, $accent, $blue);
  background-size: 600% 600%;
  -webkit-animation: header-Gradient 25s ease infinite;
  -moz-animation: header-Gradient 25s ease infinite;
  animation: header-Gradient 25s ease infinite;
}

@-webkit-keyframes header-Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@-moz-keyframes header-Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes header-Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.shadowEffect {
  box-shadow: 0 60px 100px rgba(70, 70, 70, 0.25);
  -webkit-box-shadow: 0 60px 100px rgba(70, 70, 70, 0.25);
  -moz-box-shadow: 0 60px 100px rgba(70, 70, 70, 0.25);
}

/* BUTTOMS STYLES */
button.BtnLCPrimary {
  font-family: $gilroy-extra-bold;
  background: $accent;
  color: rgba(255, 255, 255, 1) !important;
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2),
    inset 0 -1px 0 rgba(16, 22, 26, 0.1);
  -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2),
    inset 0 -1px 0 rgba(16, 22, 26, 0.1);
  -moz-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2),
    inset 0 -1px 0 rgba(16, 22, 26, 0.1);
    &:hover{
      background:#823cad
    }
}

button.BtnLCPrimary span svg {
  color: rgba(255, 255, 255, 1) !important;
}

button.BtnLCPrimaryOutline {
  font-family: $gilroy-extra-bold;
  background: rgba(255, 255, 255, 1) !important;
  color: $accent !important;
  box-shadow: 0 0 1px 1px $accent inset !important;
  -webkit-box-shadow: 0 0 1px 1px $accent inset !important;
  -moz-box-shadow: 0 0 1px 1px $accent inset !important;
}

button:hover.BtnLCPrimaryOutline {
  background: rgba(240, 240, 240, 0.5) !important;
}

button.BtnLCPrimaryOutline span svg {
  color: $accent !important;
}

button.BtnLCPrimaryLink {
  background: transparent !important;
  color: $accent !important;
}

button.BtnLCPrimaryLink span svg {
  color: $accent !important;
}

button.BtnLCYellow {
  font-family: $gilroy-extra-bold;
  background: rgba(255, 187, 5, 1) !important;
  color: rgba(70, 70, 70, 1) !important;
}

button.BtnLCYellow span svg {
  color: rgba(70, 70, 70, 1) !important;
}

button.BtnLCAccent {
  font-family: $gilroy-extra-bold;
  background: $accent !important;
  color: $bg !important;
}

button.BtnLCCancel {
  color: #c23030 !important;
  box-shadow: 0 0 0 1px #c23030 inset !important;
  -webkit-box-shadow: 0 0 0 1px #c23030 inset !important;
  -moz-box-shadow: 0 0 0 1px #c23030 inset !important;
}

button.BtnLCCancel span {
  color: #c23030 !important;
}

button.bp3-disabled {
  background: rgba(70, 70, 70, 0.25) !important;
  box-shadow: 0 0 0 1px rgba(206, 217, 224, 0.5);
  -webkit-box-shadow: 0 0 0 1px rgba(206, 217, 224, 0.5);
  -moz-box-shadow: 0 0 0 1px rgba(206, 217, 224, 0.5);
}

/* TABS */
.bp3-tab[aria-selected='true'],
.bp3-tab:not([aria-disabled='true']):hover {
  color: $accent !important;
  box-shadow: inset 0 -3px 0 $accent !important;
  -webkit-box-shadow: inset 0 -3px 0 $accent !important;
  -moz-box-shadow: inset 0 -3px 0 $accent !important;
}

.bp3-tabs.bp3-vertical > .bp3-tab-panel {
  width: 100% !important;
}

/* PROGRESS BAR */
.bp3-slider-progress.bp3-intent-primary {
  background: $accent !important;
}

/* SearchBar autocomplete selection */
.bp3-menu-item.bp3-intent-primary.bp3-active {
  background: rgba(138, 155, 168, 0.15);
  color: #fff !important;
  font-family: $gilroy-extra-bold;
}

.bp3-callout {
  background-color: white;
}

.bp3-menu {
  padding: 0px;
}

.bp3-menu-item.bp3-intent-primary.bp3-active span span {
  color: rgba(70, 70, 70, 0.8) !important;
}

/* FORMS STYLING */
input {
  background: rgba(206, 217, 224, 0.15);
  box-shadow: 0 0 0px 1px rgba(206, 217, 224, 0.75) inset;
  color: rgba(70, 70, 70, 1);
}

input:focus {
  box-shadow: 0 0 0px 1px rgba(80, 80, 80, 0.5) inset;
}

input:focus::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: transparent;
}

input:focus::-moz-placeholder {
  /* Firefox 19+ */
  color: transparent;
}

input:focus:-ms-input-placeholder {
  /* IE 10+ */
  color: transparent;
}

input:focus:-moz-placeholder {
  /* Firefox 18- */
  color: transparent;
}

.whiteInput input {
  background: #ffffff !important;
}

.whiteInput.bp3-editable-text {
  background: #ffffff !important;
  border-radius: 4px !important;
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
}

.textAreaWhiteStyle {
  background: #ffffff !important;
  box-shadow: 0 0 0px 1px rgba(206, 217, 224, 0.75) inset !important;
  -webkit-box-shadow: 0 0 0px 1px rgba(206, 217, 224, 0.75) inset !important;
  -moz-box-shadow: 0 0 0px 1px rgba(206, 217, 224, 0.75) inset !important;
}

.CardForm {
  background: rgba(206, 217, 224, 0.125) !important;
  margin-bottom: 1em;
}

.popOverContent {
  padding: 1em !important;
}

span.bp3-popover-target {
  width: 100%;
}

.wallContainer {
  max-width: 95%;
  margin: 0px auto;

.bp3-button:not([class*="bp3-intent-"]){
  background-color: $accent;
  background-image: none;
}

}

.PubsWall {
  margin-top: 4rem;
}

.customWrapper {
  max-width: 90%;
  margin: 0 auto;
}

.minCustomWrapper {
  max-width: 50%;
  margin: 0 auto;
}

/* NO ACTIVITY DISPLAY */
.noActivityDisplay {
  /* background: rgba(255, 255, 255, 1); */
  margin-bottom: 0;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}

.noActivityDisplay h5 {
  text-align: center;
  margin-bottom: 0;
  padding: 1em 0;
  margin: 0;
}

/* GENIRIC CSS SEARCHBAR HEADER */
.highlightCustom {
  font-weight: bold;
  color: $accent;
  background: transparent !important;
}

.theme_suggestions__LVq {
  color: #464646;
}

/* Swiper */
/* .swiper-slide {
  width: 100% !important;
  height: 0;
  padding-bottom: 100% !important;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}*/

.swiper {
  width: 100%;
}

.servicesLC {
  .swiper-slide {
    display: flex;
    justify-content: center;
  }
}
  
.swiper-slide img {
  width: 100%;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}

.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  position: relative;
  bottom: 0px;
}

.custom-class-facebook {
  width: 100%;
  min-height: 40px;
  font-size: 16px;
  padding: 5px 15px;
  margin-top: 10px;
  cursor: pointer;
  color: #f8f8f8;
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
  -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
  -moz-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1); 
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
  justify-content: center;
  padding: 5px 10px;
  text-align: left;
  vertical-align: middle;
  background-color: #4267b2;
  border-color: #29487d;
  font-family: Helvetica,sans-serif;
  font-weight: 700;
  &:hover {
    background-color: #365899;
    border-color: #29487d;
  }
}

.swiper-button-next,
.swiper-button-prev {
  width: 30px;
  height: 30px;

  &::after {
    font-size: 25px !important;
    font-weight: bold;
  }
}

.swiper-pagination-bullets {
  bottom: 10px !important;
}

/* OVERLAY PRIMIUM CONTENT */
.OverlayPremium {
  width: 100%;
  height: auto;
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  overflow: hidden;
  display: block;
}

.OverlayPremium figure {
  transform: scale3d(0.85, 0.85, 0.85);
  -webkit-transform: scale3d(0.85, 0.85, 0.85);
  -moz-transform: scale3d(0.85, 0.85, 0.85);

  padding: 2em;
  display: flex;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.OverlayPremium figure:hover {
  transform: scale3d(0.9, 0.9, 0.9);
  -webkit-transform: scale3d(0.9, 0.9, 0.9);
  -moz-transform: scale3d(0.9, 0.9, 0.9);
}

.OverlayPremium h4 {
  text-align: left;
  /* font-size: 1.25em; */
}

.BluredContentOverlayPremium {
  filter: blur(20px);
  -webkit-filter: blur(20px);
  -moz-filter: blur(20px);
}

.text-center {
  text-align: center;
}

.widthMenu-wrapper {
  max-width: 380px;
  z-index: 1000;
}

.fix-width-UserInformation {
  width: calc(20% - 1em);
}

/* ADS style */
.adCallout {
  background: rgba($color: #ff822e, $alpha: 0.25);
}

.flex-center {
  display: flex;
  align-content: center;
  align-items: center;
}

.children-without-transition {
  & * {
    transition: none;
  }
  transition: none;
}

button:focus {
  outline: none;
}

.bp3-toast-container.bp3-toast-container-inline {
  position: fixed;
  z-index: 5001;
}

.bp3-toast {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardContainer {
  width: 700px;
  margin: 0 auto;
}

.bp3-card.rounded-lg {
  border-radius: 0.5rem;
}

.accent {
  color: $accent;
}

.hide-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.offline-toast button {
  display: none
}


/* ============================================================================================== */


.card-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

@media screen and (max-width: 1096px) {
  .wallContainer {
    padding-top: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .wallContainer {
    padding-top: 2rem;
    margin-top: 0%;
    padding-top: 0%;
  }

  .cardContainer {
    width: 600px;
  }
}

@media screen and (max-width: 600px) {
  .cardContainer {
    width: 425px;
  }

  .bp3-dialog {
    max-width: 90%;
  }

  .wallContainer {
    max-width: 100%;
    margin-top: 0%;
    padding-top: 0%;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    letter-spacing: 0px !important;
  }

  .CommentsContainer {
    padding: 0;
  }

}

.bp3-control input:checked ~ .bp3-control-indicator {
  background-color: #8051DA !important;
  color: #ffffff !important;
}

.bp3-callout.bp3-intent-primary {
  background-color: rgba(19, 50, 189, 0.15) !important;
}

.bp3-callout.bp3-intent-primary[class*="bp3-icon-"]::before, .bp3-callout.bp3-intent-primary > .bp3-icon:first-child, .bp3-callout.bp3-intent-primary .bp3-heading {
  color: #8051DA;
}
