@import 'src/variables';

$input-padding: 1rem;

.search-category {
  background-color: $bg;
  padding: 1.25rem;

  &__input-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
  }

  &__input {
    width: 100%;
    font-size: 1rem;
    font-family: $gilroy-medium;
    padding: $input-padding calc(#{$input-padding * 2} + 22px) $input-padding $input-padding;
    background-color: white;
    border-radius: $base-radius;
    box-shadow: 0 0 10px $shadow-color;

    &:focus {
      box-shadow: 0 0 10px $shadow-color;
      outline: unset;
    }

    &::placeholder {
      color: $font-light;
    }
  }

  &__icon-wrapper {
    right: $input-padding;
    position: absolute;
    cursor: pointer;
  }

  &__icon-wrapper {
    color: $font;
  }
}