@import 'src/variables';

.burger-menu {
  font-family: $gilroy-medium;
  font-size: 1rem;
  color: $font;
  margin-top: 2rem;

  &-section {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.5rem 1.5rem;
    
    &:hover {
      color: $accent !important;
    }
    
    & p {
      margin-left: 1rem;
    }

  }

}