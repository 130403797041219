@import 'src/variables';

.change-proposal-container {
  width: 100%;

  .change-proposal-publications {
    max-height: 250px;
    padding: 0;
  }

  .proposal-container {
    padding: 0.5em;

    &__title {
      margin-bottom: 1em;
      font-size: 17px;
    }

    &__add_publication {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 2em;

      &--button {
        width: max-content;
        border: $accent 1px solid;
        color: $accent;
        padding: 0;
        margin-left: 1em;
        padding: 5px;

        &:hover {
          background-color: $bg;
        }
      }

      &--title {
        font-family: $gilroy-bold;
        color: $accent;
      }
    }

    &__publications_container {
      // max-height: 15em;
      overflow: hidden;
    }

    &__write-proposal {
      margin-top: 0.5em;
      width: 100%;
      resize: none;
    }

    &__divider {
      margin: 0.3em 0 2em 0;
    }

    &__total_value {
      text-align: right;
      color: $dark-purple;
      margin-right: 0.7em;
      font-family: $gilroy-medium;
      &--bold {
        font-family: $gilroy-bold;
      }
    }

    textarea.bp3-input {
      background-color: $bg-light;
    }
  }
}