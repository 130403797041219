@import "src/variables";

.history{
  background-color: #E8E8E8;

  &__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: .5em 0 0 0;
    
    h1 {
      font-size: 1.25rem;
      color: $accent;
      margin: 0;
    }
  }
  .history {
    &__transaction {
      background-color: #FFFFFF;
      height: 300px;
      width: 100%;
      max-width: 340px;
      margin: 10px 5px;
      box-shadow: 0px 0px 10px #00000029;
      border-radius: 10px;
      opacity: 1;
      @media (min-width: 768px) {
        height: 104px;
        width: 100%;
        max-width: 726px;
      }
      @media (min-width: 1024px) {
        height: 129px;
        width: 100%;
        max-width: 1140px;
      }
    }
    &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .user-info {
        justify-content: center;
        align-items: center;
        width: 100%;
        padding-left: 3em;
        @media (max-width: 768px) {
          padding-left: 0;
        }
        .user_name{
          font-size: 20px;
          margin: 0.5em 0 0.5em 0;
        }
        .avatar{
          
          .blur-avatar{
            width: 58px;
            height: 58px;
            .blur-image {
              width: 58px;
              height: 58px;
              &__picture{
                width: 58px;
                height: 58px;
                &--background{
                  width: 58px;
                  height: 58px;
                }
                &--img_box img {
                  width: 58px;
                  height: 58px;
                }
              }
            }
          }
        }
      }
    }
  }
  .transaction {
    &__wrapper {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      align-content: space-evenly;
      justify-content: center;
      height: 100%;
      gap: 0.5rem;

      @media (min-width: 768px) {
        display: grid;
        align-content: center;
        gap: 0rem;
        grid-template-columns: 1fr 1fr 1fr;
      }
      @media (min-width: 1024px) {
        display: grid;
        gap: 0.5rem;
        grid-template-columns: 1fr 1fr 1fr;
      }
    }

    &__date {
      position: absolute;
      top: 15px;
      right: 15px;
      height: 15px;
      font-family: $gilroy-bold;
      font-size: .75rem;
    }

    &__user {
      min-width: 340px;
      display: grid;
      padding: 0 0.75rem;

      @media (min-width: 320px) and (max-width: 374px) {
        min-width: 300px;

        &-text-wrapper {
          h2, p {
            font-size: 1rem !important
          }
        }
      }

      &-name {
        display: flex;
        align-items: center;

        h2 {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      &-checkmark {
        margin-left: 0.2em;
        max-height: 19px;
      }
      

      &-text-wrapper {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        align-content: end;

        @media (min-width: 768px) {
          align-content: end;
        }

        h2, h3, p {
          width: 100%;
        }

        h2 {
          font-size: 1.25rem
        }

        .kind-proposal {
          font-family: $gilroy-medium;
          font-size: 1.12rem;
        }

        .RankBlock.mt-3{
          margin-top: 0;
          .rankingPorcentage {
            font-size: 1.15em;
            margin-left: 0;
          }
        }

      }

      div.AvatarContainer {
        position: relative;
        width: 100%;
        height: 100%;
        background: #ebebeb;
        border-radius: 50%;
        overflow: hidden;

        .AvatarInitials {
          position: absolute;
          width: 100%;
          height: 100%;
          font-family: $gilroy-extra-bold;
          display: flex;
          align-items: center;
          align-content: center;
          color: rgba(70, 70, 70, 1) !important;
      
          .text-xs {
            font-size: 1.75rem;
            width: 100%;
          }
        }
        
        .AvatarInitials p {
          text-align: center;
          margin-bottom: 0;
        }
        
        .AvatarImage {
          position: absolute;
          width: 100%;
          height: 0;
          padding-bottom: 100%;
        }
        
        .AvatarImage img {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        }
      }

      @media (min-width: 768px) and (max-width: 1023px) {
        height: 62px;
        min-width: 290px;
        display: grid;
        grid-template-columns: 62px 1fr 20px;
        padding: 0 0.75rem;
        grid-column-gap: 0.25rem;
        column-gap: 0.25rem;

        &-text-wrapper {
          h2 {
            font-size: 1rem;
          }

          .kind-proposal {
            font-size: 1rem;
          }
  
          .RankBlock.mt-3{
            margin-top: 0;
            .rankingPorcentage {
              font-size: 1em;
            }
          }
        }
      }


    }

    &__publications {
      height: 99px;
      min-width: 340px;
      display: grid;
      grid-template-columns : 100px 1fr 100px;
      padding: 0 0.75rem;
      justify-content: space-evenly;
      justify-items: center;  
      
      @media (min-width: 320px) and (max-width: 374px) {
        min-width: 288px;
      }

      @media (min-width: 768px) and (max-width: 1023px) {
        height: 62px;
        min-width: 210px;
        padding: 0;
        grid-template-columns: 72px 0.65fr 72px;
    
      }

      &-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        &-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          max-height: 99px;
          border: none;
          border-radius: 5px;
          overflow: hidden;
          &--separator {
            max-height: 62px;
            max-width: 62px;
          }
          @media (min-width: 768px) and (max-width: 1023px) {
            max-height: 62px;
            max-width: 62px;
          }
        }
      }
    }

    &__status {
      height: 49px;
      min-width: 230px;
      font-family: $gilroy-bold;
      font-size: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        background-color: #EFEFEF;
        width: fit-content;
        padding: 0.75em;
        border: none;
        border-radius: 5px;
      }
      span {
        &.succesful {
          color: #54D454;
        }
        &.rejected {
          color: #D64747;
        }
      }
      @media (min-width: 768px) and (max-width: 1023px) {
        min-width: 190px;
        font-size: 1rem;
      }
    }
  }


  .blur-image {
    &__picture{
      width: 100px;
      height: 100px;
  
      &--background{
        width: 100px;
        height: 100px;
      }
  
      &--img_box img {
        width: 100px;
        height: 100px;
      }
    }
  }

}