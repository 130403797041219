@import "src/variables";

.RequestsContainer {
  padding-top: 3rem;

  .bp3-tabs.bp3-vertical > .bp3-tab-list .bp3-tab {
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    margin-bottom: 0.5em;
  }

  .text-white {
    color: #fff !important;
  }

  .Box2 {
    padding-top: 2rem;
  }

  .Date {
    font-family: $gilroy-light;
    font-size: 0.7rem;
    font-weight: 600;
    padding: 10px 10px;
  }

  .Date2 {
    font-family: $gilroy-light;
    font-size: 0.7rem;
    font-weight: 600;
    color: rgba(70, 70, 70, 1) !important;
    //padding: 10px 10px;
  }

  .TopHeader {
    font-family: $gilroy-extra-bold;
    font-weight: 600;
  }

  .Header1 {
    font-family: $gilroy-extra-bold;
    font-weight: 600;
  }

  .BtnCancel {
    margin: 0px auto;
  }

  .StdButton {
    font-family: $gilroy-extra-bold;
  }

  .PubName {
    padding-bottom: 0.5rem;
  }

  .ProfileName {
    padding-top: 1.2rem;
  }

  .PropuestaTitle {
    padding-bottom: 0.7rem;
    margin-left: -5px;
  }

  .RankUser {
    padding-top: 0.5rem;
  }

  .acceptedText {
    font-family: $gilroy-light;
    font-weight: 600;
  }

  .CancelBtn {
    font-size: 10px;
    z-index: 8;
  }

  .PubBox {
    border: 1px solid rgb(169, 169, 169);
  }

  .h-cambio {
    max-height: 255px;
  }

  .ChatButton {
    align-self: center;
  }

  .Propuesta {
    font-family: $gilroy-extra-bold;
    color: rgba(70, 70, 70, 1) !important;
    line-height: 1em;
    font-size: 14px;
  }

  .OwnPubOnChange {
    margin-top: -30px;
  }

  .productSwiper .imageWrapper img {
    max-height: 140px;
  }

  /******************** MEDIA QUERY ********************/

  @media (max-width: 768px) {
    .Col1 {
      display: flex;
    }

    .Box {
      margin-left: 0;
    }

    .Col2 {
      text-align: center;
    }

    .Date2 {
      margin-right: 0;
      text-align: center;
    }

    .UserCardPropSend {
      flex-direction: row;
    }

    .newUserText {
      margin-top: 3px;
      margin-left: 5px;
    }

    .responsiveCancel {
      display: none;
    }

    .UserName {
      margin: 0px auto;
      text-align: center;
      padding-left: 0.5rem;
    }

    .headerUserCard div.AvatarContainer {
      margin: 0px auto;
      text-align: center;
    }

    .Proposal {
      margin-top: 5px;
    }

    .UserCardPropSend {
      display: inline-flex;
    }

    .RankBlock {
      margin-right: 5px;
      display: none;
    }

    .newUserText {
      display: none;
    }

    .ChatButton {
      margin-top: 12px;
    }
  }

  @media (max-width: 518px) {
    .ChatButton {
      display: none;
    }

    .PropSendPubBox {
      margin-top: 0;
    }

    .RankBlock {
      margin-top: 5px;
    }
  }

  @media (max-width: 425px) {

    .showProposalLink {
      display: block;
    }

    .Col1 {
      width: 100%;
      display: flex;
    }

    .Col2 {
      display: block;
      align-items: center;
    }

    .Box1Line1 {
      margin-right: 0;
    }

    .ProfileName {
      text-align: center;
    }
  }
}
